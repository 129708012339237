<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    ref="templates"
    :class="['templates mb-1', { templates_show: show }]"
  >
    <div
      v-for="template in templates"
      :key="template.value"
      class="templates__template"
      :class="{'templates_disabled': template.disabled}"
      @click="addTemplate(template)"
    >
      <plus-circle-icon size="0.9x" />
      <span>{{ template.name }}</span>
    </div>
    <div v-if="hasButton">
      <div
        v-show="showExpand"
        :class="['templates__expand primary-bg_hover', { templates__expand_up: show }]"
        @click="show = !show"
      >
        <chevron-down-icon size="1.2x" />
      </div>
    </div>
  </div>
</template>

<script>
  import { templatesMixin } from '@/mixins/templatesMixin';
  import { PlusCircleIcon, ChevronDownIcon } from 'vue-feather-icons';
  export default {
    name: 'TemplatesItem',
    components: {
      PlusCircleIcon,
      ChevronDownIcon,
    },
    mixins: [templatesMixin],
    props: {
      hasButton: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        show: false,
        showExpand: false,
      };
    },
    mounted () {
      if (this.hasButton) {
        this.calculateWidth();
      }
      this.templates = this.templates.filter(section => section.name !== 'Lyne');
      this.templates = this.templates.flatMap(section => section.data);
    },
    methods: {
      calculateWidth () {
        const templates = this.$refs.templates.children;
        let maxWidth = this.$refs.templates.offsetWidth - 15;
        for (let i = 0; i < templates.length - 1; i++) {
          maxWidth -= templates[i].offsetWidth;
          if (maxWidth <= 0) {
            this.showExpand = true;
            templates[i].classList.add('templates__template_hide');
          }
        }
      },
      addTemplate (template) {
        if (!template.disabled) {
          const templateStr = template.fallback ? `{${template.value}:FALLBACK}` : `{${template.value}}`;
          this.$emit('addTemplate', templateStr, template.space);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .templates {
    display: flex;
    justify-self: start;
    flex-wrap: wrap;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    // margin-left: 15px;
    column-gap: 6px;
    row-gap: 6px;
    position: relative;
    padding: 2px 10px 2px 0px;
    &_disabled{
      opacity: .5;
      cursor: default !important;
    }
    &__expand {
      cursor: pointer;
      transform: rotate(0deg);
      transition-duration: 0.3s;
      background: var(--ternary-bg-cl);
      display: grid;
      place-items: center;
      padding: 3px;
      border-radius: 50%;
      position: absolute;
      right: 0px;
      top: 0;
      font-size:  1rem;
      &:hover {
        color: #fff;
      }
      &_up {
        transform: rotate(180deg);
      }
    }
    &__template {
      display: grid;
      white-space: nowrap;
      grid-template-columns: 14px auto;
      color: var(--regular-text-cl);
      border-radius: 6px;
      align-items: center;
      background: var(--ternary-bg-cl);
      padding: 3px 6px;
      font-size:  1rem;
      cursor: pointer;
      &_hide {
        display: none;
      }
    }
    &_show {
      .templates {
        &__template {
          &_hide {
            display: grid;
          }
        }
      }
    }
  }
  @media (max-width: 800px) {
    .templates {
      &__template {
        margin-bottom: 13px;
      }

      margin-bottom: 0px;
      margin-left: 0;
    }
  }
</style>
