<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="send-email-step">
    <div
      v-if="$route.name !== 'my-templates'"
      class="send-email-step__header"
    >
      <div class="send-email-step__header-from">
        <span class="text_s color_secondary">
          {{ $t('common.from').concat(':') }}
        </span>
        <default-select
          :items="activeIntegrations"
          :value="activeIntegrations.find(i => i._id === integrationId)?.name"
          :read-only="true"
          input-size="xs"
          class="send-email-step__email-from"
          data-closable="sendEmailStepFrom"
          validation-string="required"
          input-placeholder="Unselect"
          @select="updateIntegrationId"
        />
      </div>
      <div class="send-email-step__header-to">
        <span class="text_s color_secondary">
          {{ $t('common.to').concat(':') }}
        </span>
        <default-select
          :items="emailTypes"
          input-size="xs"
          class="ml-2"
          :value="emailTypes.find(e => e._id === type)?.name"
          data-closable="sendEmailStepTo"
          validation-string="required"
          :read-only="true"
          input-placeholder="Unselect"
          @select="updateType"
        />
      </div>
    </div>
    <div
      class="send-email-step__textarea-container"
      @mouseover="showTemplateMenu"
      @mouseleave="isShowTemplateMenu = false"
    >
      <div class="send-email-step__header-subject">
        <span class="text_s color_secondary">
          {{ $tc('common.subject').concat(':') }}
        </span>
        <input
          ref="emailSubject"
          v-model="emailSubject"
          :readonly="readOnly"
          class="send-email-step__input ui-kit-input__item text_s"
          @focus="isSubject = true"
        >
        <default-select
          class="margin-left send-email-step__thread"
          :items="emailThreading"
          :value="threadName"
          :read-only="true"
          input-size="xs"
          data-closable="emailThreading"
          :placeholder="$t('modals.sendEmail.selects.thread.addThread')"
          @select="addThread"
        />
      </div>
      <div class="send-email-step__error">
        <span
          v-for="(error, key) in messageError"
          :key="`${key}Message`"
          class="text_xs"
          :class="key === 'warningText' ? 'color_warning' : 'color_danger'"
        >
          <i
            v-if="error"
            class="bx bx-info-circle"
          />
          {{ error }}
        </span>
        <span
          v-for="(error, key) in subjectError"
          :key="`${key}Subject`"
          class="text_xs"
          :class="key === 'warningText' ? 'color_warning' : 'color_danger'"
        >
          <i
            v-if="error"
            class="bx bx-info-circle"
          />
          {{ error }}
        </span>
      </div>

      <quill-textarea
        ref="quillBlock"
        class="base-item"
        :html="message"
        @focus-edit="isSubject = false"
        @paste="handlePaste"
        @change="handleChange"
        @text-click="handleQuillClick"
      />
      <template-unlink-menu
        v-show="isShowTemplateMenu"
        class="connection-request__template-menu"
        :class="{'connection-request__template-menu_active': isShowTemplateMenu}"
        :template="template"
        @unlink-template="unlinkTemplate"
        @edit-template="editTemplate"
      />
    </div>
    <div class="send-message-step__textarea-container">
      <step-menu
        ref="menu"
        :brand="brand"
        :video="video"
        :template="template"
        :message="content"
        :subject="emailSubject"
        type="send_email"
        @add-template-to-area="addTemplateEmail"
        @add-practice="addPractice"
        @remove-video="removeVideo"
        @insert-video="insertVideo"
        @unlink-template="unlinkTemplate"
        @choose-video="$emit('choose-video')"
        @choose-template="$emit('choose-template')"
      />
    </div>
  </div>
</template>
<script>
  import { addTemplateMixin } from '@/mixins/addTemplateMixin.js';
  import StepMenu from '@/components/updateWorkflow/performAction/menuFiles/StepMenu';
  import DefaultSelect from '@/components/base/uiKit/DefaultSelect';
  import QuillTextarea from '@/components/updateWorkflow/performAction/editActions/quillTextarea.vue';
  import { ContenteditableMixin } from '@/mixins/ContenteditableMixin';
  import TemplateUnlinkMenu from '@/components/updateWorkflow/performAction/menuFiles/TemplateUnlinkMenu';
  import { capitalize } from '@/mixins/capitalizeFilterMixin';
  import { mapGetters } from 'vuex';

  export default {
    name: 'SendEmailStep',
    components: {
      DefaultSelect,
      StepMenu,
      QuillTextarea,
      TemplateUnlinkMenu,
    },
    mixins: [addTemplateMixin, ContenteditableMixin],
    props: {
      brand: {
        type: String,
        default: '',
      },
      messageError: {
        type: Object,
        default: () => ({
          warningText: '',
          errorText: '',
        }),
      },
      subjectError: {
        type: Object,
        default: () => ({
          warningText: '',
          errorText: '',
        }),
      },
      message: {
        type: String,
        default: '',
      },
      subject: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'work',
      },
      integrationId: {
        type: String,
        default: '',
      },
      template: {
        type: Object,
        default: null,
      },
      video: {
        type: Object,
        default: null,
      },
      choices: {
        type: Boolean,
        default: true,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      emailThreading: {
        type: Array,
        default: () => [],
      },
      actionId: {
        type: String,
        default: '',
      },
      parentThread: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        isPaste: false,
        chosenThread: null,
        emailSubject: this.subject || '',
        email: {},
        content: this.message || '',
        showProviderTooltip: false,
        isSubject: false,
        isShowTemplateMenu: false,
        emailTypes: [
          {
            name: this.$t('modals.sendEmail.selects.type.work'),
            _id: 'work',
          },
          {
            name: this.$t('modals.sendEmail.selects.type.personal'),
            _id: 'personal',
          },
          {
            name: this.$t('modals.sendEmail.selects.type.enriched'),
            _id: 'predicted',
          },
        ],
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
        integrationsList: 'integrations/integrationsList',
      }),
      activeIntegrations () {
        return this.integrationsList
          .filter(i => i.status === 'connected')
          .map(i => {
            i.name = capitalize(i.type) + ', ' + i.email;
            return i;
          });
      },
      threadName () {
        if (this.originalTread) {
          return this.$t('modals.sendEmail.selects.thread.original');
        } else {
          return this.chosenThread?.name
            ? this.$t('modals.sendEmail.selects.thread.fromStep', {
              step: this.chosenThread.idx + 1,
            })
            : this.$t('modals.sendEmail.selects.thread.original');
        }
      },
      originalTread () {
        return this.chosenThread?.originTread.includes(this.actionId);
      },
    },
    watch: {
      content () {
        this.$emit('update:message', this.content);
        if (this.isPaste) {
          this.$refs.quillBlock.$el.scrollTop = this.$refs.quillBlock.$el.scrollHeight;
        }
        this.isPaste = false;
      },
      emailSubject () {
        this.$emit('update:subject', this.emailSubject);
        this.checkSubject();
      },
      template () {
        if (this.template) {
          this.emailSubject = this.template.subject;
          this.content = this.template.text_template;
          const root = this.$refs.quillBlock.quill.root;
          root.innerHTML = this.content;
        }
      },
      video () {
        this.content = this.message;
        this.$refs.quillBlock.quill.root.innerHTML = this.content;
        this.handleChange(this.$refs.quillBlock.quill);
        if (!this.video?.name) {
          if (this.content.match(/<p>\{video \|[^}]+\}<\/p>/g)) {
            this.$refs.quillBlock.quill.root.innerHTML = this.content.replace(/<p>\{video \|[^}]+\}<\/p>/g, '');
          }
          this.handleChange(this.$refs.quillBlock.quill);
        }
      },
    },
    mounted () {
      const selectedIntegration = this.activeIntegrations.find(i => i._id === this.integrationId);
      if (!selectedIntegration) {
        this.$emit('update:integrationId', '');
      }
      const root = this.$refs.quillBlock.quill.root;
      root.innerHTML = this.content;
      this.chosenThread = this.emailThreading.find(e => this.emailSubject === e?.name) || null;
    },
    methods: {
      handlePaste () {
        this.isPaste = true;
      },
      addThread (thread) {
        this.chosenThread = thread;
        this.emailSubject = thread.name;
        this.$emit('update:parent-thread', thread.originTread);
      },
      unlinkTemplate () {
        this.isShowTemplateMenu = false;
        this.$emit('unlink-template');
      },
      editTemplate (template) {
        this.isShowTemplateMenu = false;
        this.$emit('edit-template', template);
      },
      showTemplateMenu () {
        if (this.readOnly) {
          this.isShowTemplateMenu = true;
        }
      },
      checkSubject () {
        if (!this.originalTread && !this.emailThreading.find(e => this.emailSubject === e?.name)) {
          this.chosenThread = null;
          this.$emit('update:parent-thread', '');
        } else if (this.originalTread) {
          this.chosenThread.name = this.emailSubject;
        }
      },
      updateType (type) {
        this.$emit('update:type', type._id);
      },
      updateIntegrationId (integration) {
        this.$emit('update:integrationId', integration._id);
      },
      handleChange ({ quill }) {
        if (quill?.root.innerHTML) {
          this.content = quill.root.innerHTML;
          this.content = this.content.replace(/<p>\s*<br>\s*<\/p>/g, '<span><br></span>');

          this.handleQuillChange({ quill });
        }
      },
      addTemplateEmail (template) {
        if (!this.isSubject) {
          this.content = this.addTemplatetoQuill(template);
        } else this.emailSubject = this.addTemplateToArea(this.emailSubject, template, 'emailSubject');
      },
      insertVideo (video) {
        this.$emit('insert-video', video);
      },
      removeVideo (video) {
        this.$emit('remove-video', video);
      },
      addPractice (item) {
        this.emailSubject = item.subject;
        const root = this.$refs.quillBlock.quill.root;
        root.innerHTML = item.message;
        this.content = item.message;
      },
    },
  };
</script>
<style lang="scss">
.send-email-step {
  & .base-item {
    padding: 0px;
    border-radius: 0px;
    border: none;
    min-height: 180px;
    max-height: 180px;
    border-bottom: 1px solid rgba(10, 32, 51, 0.06);
    box-shadow: none;
    background: var(--neutral-50);
  }
  &__header {
    padding: 8px 0px 0px;
    display: flex;
    flex-flow: column;
    &-subject, &-from, &-to {
    padding: 8px ;
    display: flex;
    align-items: center;
    gap: 4px;
    border-bottom: 1px solid rgba(10, 32, 51, 0.06);
    }
  }
  &__input {
    padding: 4px;
  }
  &__thread {
    min-width: 180px;
    .uikit-tooltip__list {
      min-width: 250px;
      right: 0;
    }
  }
  &__email-from {
    min-width: 250px;
  }
  &__error {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 4px 8px;
    background: var(--neutral-50);
  }
  &__template-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transition: 0.3s;
    &_active {
      opacity: 1;
    }
  }
  &__textarea-container {
    position: relative;
  }
}
</style>
