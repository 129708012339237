/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import i18n from '@/i18n';

const industries = [
  i18n.t('industries.accounting'),
  i18n.t('industries.administrationOfficeSupport'),
  i18n.t('industries.advertisingArtsMedia'),
  i18n.t('industries.bankingFinancialService'),
  i18n.t('industries.callCustomerService'),
  i18n.t('industries.communityServicesDevelopment'),
  i18n.t('industries.construction'),
  i18n.t('industries.consultingStrategy'),
  i18n.t('industries.designArchitecture'),
  i18n.t('industries.educationTraining'),
  i18n.t('industries.engineering'),
  i18n.t('industries.farmingAnimalsConservation'),
  i18n.t('industries.financialServices'),
  i18n.t('industries.governmentDefence'),
  i18n.t('industries.healthcareMedical'),
  i18n.t('industries.hospitalityTourism'),
  i18n.t('industries.hrRecruitment'),
  i18n.t('industries.informationCommunicationTechnology'),
  i18n.t('industries.insurance'),
  i18n.t('industries.legal'),
  i18n.t('industries.manufacturing'),
  i18n.t('industries.marketingCommunications'),
  i18n.t('industries.miningResourcesEnergy'),
  i18n.t('industries.realEstateProperty'),
  i18n.t('industries.sales'),
  i18n.t('industries.scienceTechnology'),
  i18n.t('industries.sportRecreation'),
  i18n.t('industries.tradesServices'),
  i18n.t('industries.transportationLogistics'),
];

export default industries;
