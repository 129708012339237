<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <aside class="new-page-sidebar">
    <slot name="header" />
    <ul>
      <router-link
        v-for="item in list"
        :key="item.path"
        class="new-page-sidebar__link"
        :to="item.path"
      >
        <default-list-item
          :title="item.title"
          :left-icon-name="item.icon"
          :is-hovered-list="true"
          :class="itemClasses"
          :is-activated-list="isActiveListItem(item.path)"
        />
      </router-link>
    </ul>
  </aside>
</template>

<script>
  import DefaultListItem from '@/components/base/uiKit/DefaultListItem.vue';
  export default {
    name: 'PageSliderNew',
    components: {
      DefaultListItem,
    },
    props: {
      list: {
        type: Array,
        required: true,
      },
      itemClasses: {
        type: String,
        default: '',
      },
    },
    computed: {
      isActiveListItem () {
        return path => this.$route.path.includes(path);
      },
    },
  };
</script>
<style lang="scss">
.new-page-sidebar {
  width: 100%;
  border-radius: 8px;
  background: var(--background-color);
  &__link{
    display: contents;
  }
}
</style>
