<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div>
    <default-select
      :items="availableLocales"
      :value="selectedLocale.name"
      input-id="languageSelect"
      data-closable="availableLocales"
      :description="$tc('common.language')"
      read-only
      class="language-switcher mb-2"
      @select="(locale) => selectLocale(locale)"
    />

    <default-button
      class="margin-left"
      type="submit"
      :title="$t('actions.saveChanges')"
      color="primary"
      form="rectangle"
      size="m"
      @action="changeLocale(selectedLocale.value)"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import DefaultSelect from '@/components/base/uiKit/DefaultSelect.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { useI18n } from '@/i18n';

  const { locale, availableLocales, changeLocale } = useI18n();

  const selectedLocale = ref(
    availableLocales.find(
      (availableLocale) => availableLocale.value === locale.value
    )
  );

  const selectLocale = (locale) => {
    selectedLocale.value = locale;
  };
</script>

<style lang="scss">
  .language-switcher {
    max-width: 240px;
  }
</style>
