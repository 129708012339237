/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function connectCRM (platform) {
  const data = await useHttp(
    `/integrations/crm/${platform}/login`,
    'GET',
    null
  );
  return data.payload;
}

async function disconnectCRM (platform) {
  const data = await useHttp(
    `/integrations/crm/${platform}/logout`,
    'POST',
    null
  );
  return data.payload;
}

async function crmInfo (platform) {
  const data = await useHttp(
    `/integrations/crm/${platform}/crm_info`,
    'GET',
    null
  );
  return data.payload;
}

async function deleteDeals (platform, idDeals) {
  const data = await useHttp(
    `/integrations/crm/${platform}/deals/${idDeals}/delete`,
    'DELETE',
    null
  );
  return data.payload;
}

async function getProfilesIntegration (platform, searchString) {
  const data = await useHttp(
    `/integrations/crm/${platform}/profiles`,
    'GET',
    null,
    {
      q: searchString,
      limit: 10,
    }
  );
  return data.payload;
}

async function getProfilesCompanies (platform, searchString) {
  const data = await useHttp(
    `/integrations/crm/${platform}/companies`,
    'GET',
    null,
    {
      q: searchString,
      limit: 10,
    }
  );
  return data.payload;
}

async function getUsers (platform) {
  const data = await useHttp(
    `/integrations/crm/${platform}/owners`,
    'GET',
    null
  );
  return data.payload;
}

async function getStages (platform, props) {
  const data = await useHttp(
    `/integrations/crm/${platform}/stages`,
    'GET',
    null,
    props
  );
  return data.payload;
}

async function getPipelines (platform) {
  const data = await useHttp(
    `/integrations/crm/${platform}/pipelines`,
    'GET',
    null
  );
  return data.payload;
}

async function createPipeline (platform, pipeline) {
  const data = await useHttp(
    `/integrations/crm/${platform}/pipeline`,
    'POST',
    pipeline,
    null,
    true
  );
  return data.payload;
}

async function updatePipeline (platform, pipeline) {
  const data = await useHttp(
    `/integrations/crm/${platform}/pipelines/${pipeline._id}/update`,
    'PUT',
    pipeline,
    null,
    true
  );
  return data.payload;
}

async function deletePipeline (platform, pipelineId) {
  const data = await useHttp(
    `/integrations/crm/${platform}/pipelines/${pipelineId}/delete`,
    'DELETE',
    null
  );
  return data.payload;
}

async function getCurrencies (platform) {
  const data = await useHttp(
    `/integrations/crm/${platform}/currencies`,
    'GET',
    null
  );
  return data.payload;
}

async function getDeals (platform, stageId, params) {
  const data = await useHttp(
    `/integrations/crm/${platform}/stages/${stageId}`,
    'GET',
    null,
    params
  );
  return data.payload;
}

async function createDeal (platform, deal) {
  const data = await useHttp(
    `/integrations/crm/${platform}/deals`,
    'POST',
    deal,
    null,
    true
  );
  return data.payload;
}

async function updateDeal (platform, dealId, update) {
  const data = await useHttp(
    `/integrations/crm/${platform}/deals/${dealId}/update`,
    'PUT',
    update,
    null,
    true
  );
  return data.payload;
}

async function updateDealOrder (platform, dealId, newOrder) {
  const data = await useHttp(
    `/integrations/crm/${platform}/deals/${dealId}/update`,
    'PATCH',
    { new_position: newOrder },
    { new_position: newOrder }
  );
  return data.payload;
}
async function deleteStage (platform, stageId, stage) {
  const data = await useHttp(
    `/integrations/crm/${platform}/stages/${stageId}/delete`,
    'DELETE',
    {
      stage_to_move: stage?._id,
      stage_id: stageId,
    },
    null,
    true
  );
  return data.payload;
}

async function defaultAuthorize (params) {
  const data = await useHttp(
    '/integrations/crm/default/authorize',
    'GET',
    null,
    params
  );
  return data.payload;
}
async function addStage (platform, stageName, probabilityNum, pipelineId, orderId) {
  const data = await useHttp(
    `/integrations/crm/${platform}/stage`,
    'POST',
    {
      name: stageName,
      pipeline_id: pipelineId,
      order: orderId,
      probability: probabilityNum,
    },
    null,
    true
  );
  return data.payload;
}

async function updateStage (platform, stageName, probabilityNum, pipelineId, dealId) {
  const data = await useHttp(
    `/integrations/crm/${platform}/stages/${dealId}/update`,
    'PUT',
    {
      name: stageName,
      pipeline_id: pipelineId,
      probability: probabilityNum,
    },
    null,
    true
  );
  return data.payload;
}
async function getCrmList () {
  const data = await useHttp(
    '/integrations/crm/suggestions',
    'GET'
  );
  return data.payload;
}

async function suggestCrm (suggestion) {
  const data = await useHttp(
    '/space/crm_suggestion',
    'POST',
    {
      crm_suggestion: suggestion,
    },
    null,
    true
  );
  return data.payload;
}

async function getDeal (platform, dealId) {
  const data = await useHttp(
    `/integrations/crm/${platform}/deals/${dealId}`,
    'GET'
  );
  return data.payload;
}
async function refreshCrm (platform) {
  const data = await useHttp(
    `/integrations/crm/${platform}/reload`,
    'POST',
    null,
    null,
    true
  );
  return data.payload;
}

export {
  connectCRM,
  disconnectCRM,
  getProfilesIntegration,
  getProfilesCompanies,
  getUsers,
  getStages,
  getPipelines,
  getDeals,
  createDeal,
  updateDeal,
  getCurrencies,
  deleteDeals,
  updateDealOrder,
  createPipeline,
  deletePipeline,
  updatePipeline,
  deleteStage,
  defaultAuthorize,
  crmInfo,
  addStage,
  updateStage,
  getCrmList,
  suggestCrm,
  getDeal,
  refreshCrm
};
