<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    ref="vldParent"
    class="video-editor"
  >
    <div class="video-editor__inner">
      <div
        ref="background"
        class="video-editor__scroll-page-wrapper"
        @scroll="getSection"
      >
        <img
          ref="img"
          loading="lazy"
          class="video-editor__scroll-page"
          src="@/assets/images/video-page__scroll-page.png"
        >
      </div>
      <div class="video-editor__scroll-points">
        <button
          v-for="(mark, i) in scrollMarks"
          :key="mark.value"
          :class="[
            'primary-btn_round',
            'video-editor__scroll-points-item',
            activeIndex === i ? 'primary-btn_blue' : 'primary-btn_gray'
          ]"
          @click="addNativeScroll(mark, i)"
        >
          {{ mark.name }}
        </button>
      </div>
      <div
        :class="[
          'video-editor__camera-wrapper',
          'video-editor__camera-wrapper_size_' + selectedScenario.video_settings.bubble_size
        ]"
      >
        <video
          ref="video"
          class="video-editor__camera"
          :class="{'video-editor__camera_rotate-180': mirrorVideo}"
          preload="metadata"
          autoplay
        />
      </div>
    </div>

    <footer>
      <editor-start-buttons
        v-if="status === 'start'"
        :media-recorder="mediaRecorder"
        @set-video="setVideo"
        @start-video="start"
      />
      <div
        v-if="status === 'edit'"
        class="video-editor__footer"
      >
        <span>
          {{ setTimer(time) }} / {{ setTimer(Math.round(duration)) }}
        </span>
        <vue-slider
          v-if="show.controls"
          v-model="currentTime"
          class="row"
          :max="duration"
          :clickable="true"
          :interval="0.001"
          style="width: 100%"
          @change="handleChange"
          @drag-start="pause"
          @drag-end="rewind"
        />
        <vue-slider
          :key="keySlider"
          v-model="marks"
          class="row mb-3"
          :max="duration"
          :clickable="true"
          :order="false"
          :interval="0.001"
          style="width: 100%"
          @dragstart="editAction"
          @drag-end="changeAction"
        >
          <template #tooltip="{ index, value }">
            <div class="slider-tooltip">
              <div class="vue-slider-dot-tooltip-inner">
                {{ editableAction || (actions[index] && actions[index].data.target) + ': ' + setTimer(Math.round(value)) }}
              </div>
              <div
                class="slider-tooltip__delete vue-slider-dot-tooltip-inner"
                @click="deleteMark(index)"
              >
                <i class="bx bx-x" />
              </div>
            </div>
          </template>
        </vue-slider>
        <button
          class="primary-btn_gray primary-btn_size_s"
          @click="videoPause ? start() : pause()"
        >
          <i :class="['bx bx-md', videoPause ? 'bx-play' : 'bx-pause']" />
          {{ videoPause ? $t('actions.resume') : $t('actions.pause') }}
        </button>
      </div>
      <editor-record-buttons
        v-if="mediaRecorder && status !=='edit'"
        :status.sync="status"
        :recording-time="recordingTime"
        @resume-video="resume"
        @pause-video="pause"
        @stop-video="stop"
      />
    </footer>

    <div
      class="overlay-loading"
      :class="{ 'overlay-loading_active': activePercentLoader }"
    >
      <div class="overlay-loading__progress">
        {{ $t('status.loading') + ' ' + Math.round(loadedPercent) + ' %' }}
      </div>
      <div
        class="overlay-loading__progstat"
        :style="{ width: loadedPercent + '%' }"
      />
    </div>
    <div
      v-show="false"
      id="dropzone"
    />
  </div>
</template>
<script>
  import { debounce } from 'vue-debounce';
  import { mapGetters } from 'vuex';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { parseTime } from '@/mixins/parseTime';
  import { capitalizeFilterMixin } from '@/mixins/capitalizeFilterMixin';
  import { setAltImg } from '@/mixins/setAltImg';
  import { createScenario, updateScenario } from '@/api/scenario';
  import { eventBus } from '@/eventbus';
  import Dropzone from 'dropzone';

  import EditorRecordButtons from '@/components/videoEditor/editor/EditorRecordButtons.vue';
  import EditorStartButtons from '@/components/videoEditor/editor/EditorStartButtons.vue';
  import { getStream } from './use/useWebcam';

  Dropzone.autoDiscover = false;
  const scroll = {
    low: 0.25,
    normal: 1,
    high: 2,
  };
  const dropzoneOptions = {
    thumbnailWidth: 150,
    maxFilesize: 50,
    chunking: true,
    chunkSize: 512 * 1024,
    autoProcessQueue: true,
    parallelChunkUploads: false,
  };
  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return this.round(c / 2 * t * t + b);
    t--;
    return this.round(-c / 2 * (t * (t - 2) - 1) + b);
  };
  export default {
    name: 'VideoEditor',
    components: {
      EditorRecordButtons,
      EditorStartButtons,
    },
    mixins: [loaderMixin, parseTime, capitalizeFilterMixin, setAltImg],
    props: {
      selectedScenario: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        dropzone: null,
        scrollSpeed: 1,
        /* Loader start */
        activePercentLoader: false,
        loadedPercent: 0,
        percent: 0,
        /* Loader end */

        /* Video start */
        source: null,
        startTime: 0,
        duration: 0,
        time: 0,
        videoPause: false,
        show: {
          controls: true,
          editMarks: false,
        },
        fps: 25,
        recordingTime: 0,
        timer: null,
        /* Video end */

        /* Сommon variables start */
        status: 'start',
        allEventListener: {
          callBackInitialize: null,
          dataavailable: null,
          stopRecord: null,
          timeupdate: null,
          onTimer: null,
          offTimer: null,
        },
        /* Сommon variables end */

        actions: [{
          time: 0,
          data: {
            target: 'main',
          },
          type: 'scroll',
        }],
        mediaRecorder: null,
        encodedUri: '',
        editableAction: '',

        /* Marks start */
        marks: [],
        activeIndex: 0,
        scrollMarks: [
          {
            name: this.$t('videoConstructor.editor.sections.video.scrollMarks.main'),
            value: 'main',
            active: true,
            top: 0,
          },
          {
            name: this.$t('videoConstructor.editor.sections.video.scrollMarks.about'),
            value: 'about',
            top: 0.20,
          },
          {
            name: this.$t('videoConstructor.editor.sections.video.scrollMarks.activity'),
            value: 'activity',
            top: 0.30,
          },
          {
            name: this.$t('videoConstructor.editor.sections.video.scrollMarks.experience'),
            value: 'experience',
            top: 0.45,
          },
          {
            name: this.$t('videoConstructor.editor.sections.video.scrollMarks.education'),
            value: 'education',
            top: 0.65,
          },
          {
            name: this.$t('videoConstructor.editor.sections.video.scrollMarks.skills'),
            value: 'skills',
            top: 0.75,
          },
          {
            name: this.$t('videoConstructor.editor.sections.video.scrollMarks.interests'),
            value: 'interests',
            top: 0.9,
          },
        ],
        actionOptions: [
          {
            name: 'Visit company website',
            type: 'visit',
            default: {
              top: 0,
              src: '',
            },
            target: 'company',
          },
          {
            name: 'Open Mutal Conections',
            type: 'click',
            default: {
              top: 0,
              src: '',
            },
            target: 'connections',
          },
        ],
        nativeScrolling: false,
        /* Marks end */
        keySlider: 0,
      };
    },
    computed: {
      currentTime: {
        get () {
          return Number(this.time.toFixed(3));
        },
        async set (time) {
          this.time = time;
        },
      },
      isValidScenario () {
        return this.selectedScenario.name && (this.source || this.selectedScenario.original_src);
      },
      mirrorVideo () {
        return this.selectedScenario.video_settings.mirror_video;
      },
    },
    watch: {
      actions () {
        this.marks = this.actions.map((action) => {
          return action.time;
        });
      },
      recordingTime () {
        if (this.recordingTime >= 120) this.stop();
      },
      'selectedScenario.video_settings.scroll_speed' (value) {
        this.scrollSpeed = scroll[value];
      },
    },
    created () {
      this.addScrollDebounced = debounce(this.addNativeScroll, 250);

      // Проблем кэширования
      if (!eventBus._events['change-scenario']) {
        eventBus.$on('change-scenario', this.changeScenarioHandler);
      }
    },
    beforeDestroy () {
      clearInterval(this.allEventListener?.callBackInitialize);
      if (this.mediaRecorder) {
        this.mediaRecorder.removeEventListener('stop', this.allEventListener.stopRecord);
        this.mediaRecorder.removeEventListener('resume', this.allEventListener.onTimer);
        this.mediaRecorder.removeEventListener('start', this.allEventListener.onTimer);
        this.mediaRecorder.removeEventListener('pause', this.allEventListener.offTimer);
      }
      if (this.webcam) {
        this.webcam.removeEventListener('dataavailable', this.allEventListener.dataavailable);
        this.webcam.removeEventListener('timeupdate', this.allEventListener.timeupdate);
        if (this.webcam.srcObject) {
          this.webcam.srcObject.getTracks().forEach(track => {
            track.stop();
          });
          this.webcam.srcObject = null;
        }
      }
      this.mediaRecorder?.stop();
      eventBus.$off('change-scenario');
    },
    async mounted () {
      try {
        this.loaded = false;
        this.canvas = this.$refs.canvas;
        this.webcam = this.$refs.video;
        if (this.selectedScenario?._id) {
          this.initializeEditVideo(this.selectedScenario.original_src);
          if (this.selectedScenario.original_src) {
            this.status = 'edit';
          } else {
            this.status = 'start';
            await this.initializeWebcam();
          }
          this.actions = this.selectedScenario.actions;
        } else {
          await this.initializeWebcam();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.$noty.error(e.message);
      } finally {
        this.loaded = true;
      }
    },
    methods: {
      async changeScenarioHandler () {
        if (this.isValidScenario) {
          // eslint-disable-next-line no-unused-expressions
          if (this.selectedScenario._id && !this.selectedScenario.video_src) {
            const result = await this.saveVideo(this.selectedScenario._id);
            const scenario = { ...this.selectedScenario };
            scenario.video_src = result.payload.src;
            scenario.original_src = result.payload.src;
            if (this.selectedScenario.mirror_video) {
              scenario.video_src = result.payload.src + '?mirror=true';
            }
            this.$store.commit('scenario/UPDATE_SCENARIO', scenario);
          }
          try {
            this.loaded = false;
            if (this.selectedScenario._id) {
              await this.updateVideo();
            } else {
              await this.save();
            }
          } catch (e) {
            this.$noty.error(e.message);
          } finally {
            this.loaded = true;
          }
        } else {
          this.$noty.error(
            this.$t('videoConstructor.notifications.invalidScenario')
          );
        }
      },
      deleteMark (index) {
        this.marks.splice(index, 1);
        this.keySlider += 1; // Ререндер слайдера иначе точки не уйдут
        this.actions.splice(index, 1);
      },
      changeAction (indexMark) {
        this.editableAction = '';
        const time = this.marks[indexMark];
        this.actions[indexMark].time = time;
        this.actions.sort((a, b) => a.time - b.time);
      },
      editAction (index) {
        this.editableAction = this.actions[index].data.target;
      },
      getActionDelay (i, action) {
        if ([i + 1, i - 1].every((index) => {
          return !this.actions[index] || Math.abs(this.actions[index].time - action.time) > 0.1;
        })) {
          return true;
        }
        this.$noty.show(
          this.$t('videoConstructor.notifications.smallDelay')
        );
        return false;
      },
      addMark (action) {
        const index = this.actions.findIndex((el) => el.time > action.time);
        if (index < 0) {
          if (this.actions[this.actions.length - 1].data.target !== action.data.target && this.getActionDelay(this.actions.length, action)) {
            this.actions.push(action);
          }
        } else {
          if (this.actions[index].data.target !== action.data.target && this.getActionDelay(index, action)) {
            this.actions.splice(index, 0, action);
          }
        }
      },
      start () {
        if (this.status === 'edit') {
          if (this.time === this.duration) {
            // Перематывает в начало если видео закончилось
            this.time = 0;
            this.webcam.currentTime = 0;
          }
          this.webcam.play();
          this.videoPause = false;
        } else {
          this.status = 'process';
          this.mediaRecorder.start();
          this.startTime = new Date();
        }
      },
      pause () {
        if (this.status === 'edit') {
          this.webcam.pause();
          this.videoPause = true;
        } else {
          this.status = 'paused';
          this.mediaRecorder.pause();
          this.videoPause = true;
        }
      },
      resume () {
        this.status = 'process';
        this.mediaRecorder.resume();
      },
      stop () {
        this.mediaRecorder.stop();
      },
      handleChange (e) {
        this.$refs.video.currentTime = e;
      },
      findCurrentAction () {
        let l = this.actions.length;
        while (l--) {
          const a = this.actions[l];
          if (this.time > a.time + 0.01) { return a; }
        }
        return null;
      },
      setCurrentAction () {
        if (!this.nativeScrolling) {
          let currentStep = this.findCurrentAction();
          if (!currentStep) { // currentStep не будет только в том случе если это последний шаг
            currentStep = this.actions[this.actions.length - 1];
          }
          const markIndex = this.scrollMarks.findIndex(
            mark => mark.value === currentStep.data.target
          );
          if (markIndex > -1 && markIndex !== this.activeIndex) {
            this.scrollTo(this.scrollMarks[markIndex], markIndex);
          }
        }
      },
      setVideo (e) {
        const { files } = e.target;
        this.source = files[0];
        this.initializeEditVideo(URL.createObjectURL(this.source));
        this.status = 'edit';
      },
      checkError () {
        switch (true) {
        case !this.nameVideo:
          this.$noty.error(
            this.$t('videoConstructor.notifications.noVideoName')
          );
          return false;
        default:
          return true;
        }
      },
      async updateVideo () {
        const scenario = await updateScenario(
          this.selectedScenario._id,
          this.selectedScenario.actions,
          this.duration,
          this.selectedScenario.name,
          this.selectedScenario.video_settings
        );
        this.$store.commit('scenario/UPDATE_SCENARIO', scenario);
        this.$noty.success(
          this.$t('videoConstructor.notifications.scenarioUpdated')
        );
      },
      async saveScenario () {
        return await createScenario(
          this.selectedScenario.name,
          this.actions,
          this.duration,
          this.selectedScenario.video_settings
        );
      },
      async saveVideo (id) {
        let error;
        let success;
        let file;
        const progressHandler = (file, progress) => {
          this.loadedPercent = progress;
        };
        try {
          if (this.source instanceof File) {
            file = this.source;
          } else {
            file = new File([this.source], 'test.mp4', {
              type: this.source.type,
            });
          }

          const url = `${process.env.VUE_APP_BASE_URL}/public_api/scenarios/${id}/upload_video?token=${localStorage.getItem('token')}`;
          if (this.dropzone) {
            this.dropzone.options.url = url;
          } else {
            dropzoneOptions.url = url;
            this.dropzone = new Dropzone('#dropzone', dropzoneOptions);
          }
          const promise = new Promise((resolve, reject) => {
            error = (...args) => {
              reject(new Error(args[1]));
            };
            success = (file, response) => {
              resolve(response);
            };
            this.dropzone.on('error', error);
            this.dropzone.on('success', success);
          });
          this.dropzone.on('uploadprogress', progressHandler);
          this.dropzone.removeAllFiles();
          this.dropzone.addFile(file);
          return await promise;
        } finally {
          if (this.dropzone && success && error) {
            this.dropzone.off('uploadprogress', progressHandler);
            this.dropzone.off('error', error);
            this.dropzone.off('success', success);
          }
          this.activePercentLoader = false;
          this.loadedPercent = 0;
        }
      },
      async save () {
        const scenario = await this.saveScenario();
        const result = await this.saveVideo(scenario._id);
        const video = result.payload;
        this.activePercentLoader = true;

        scenario.video_src = video.src;
        this.$store.commit('scenario/ADD_SCENARIO', scenario);
        if (this.$route.name !== 'videos-constructor') { // для страницы Компании
          this.$emit('close-modal');
        } else {
          this.$router.push({ name: 'videos' });
        }
      },
      async addNativeScroll (mark, i, speed) {
        if (!this.nativeScrolling) {
          this.nativeScrolling = true;
          await this.scrollTo(mark, i, speed);
          this.nativeScrolling = false;
          if (['process', 'edit'].includes(this.status)) {
            let time;
            if (this.status === 'edit') {
              time = this.time;
            } else {
              time = (new Date() - this.startTime) / 1000;
            }
            this.addMark({
              time,
              type: 'scroll',
              data: {
                target: mark.value,
              },
            });
          }
        }
      },
      async scrollTo (mark, i, speed) {
        speed = speed || this.scrollSpeed;
        this.scrollingTo = true;
        const element = this.$refs.background;
        const start = element.scrollTop;
        const imgHeight = this.$refs.img.offsetHeight - this.$refs.background.offsetHeight;
        // const imgHeight = 1;
        const to = imgHeight * mark.top;
        const change = to - start;
        let currentTime = 0;
        const increment = 20;
        let lastScroll = start;
        const time = Math.abs(change) / speed;
        if (change === 0) { return; }
        const promise = new Promise(resolve => {
          let interval = null;
          const animateScroll = () => {
            currentTime += increment;
            element.scrollTop = change > 0
              ? Math.max(Math.easeInOutQuad(currentTime, start, change, time), lastScroll + 1)
              : Math.min(Math.easeInOutQuad(currentTime, start, change, time), lastScroll - 1);
            const done = change > 0 ? element.scrollTop >= to : element.scrollTop <= to;
            if (!done && currentTime < time && element.scrollTop !== lastScroll) {
              lastScroll = element.scrollTop;
            } else {
              clearInterval(interval);
              resolve();
            }
          };
          interval = setInterval(animateScroll, increment);
        });
        await promise;
        this.activeIndex = i;
        this.scrollingTo = false;
      },
      async initializeWebcam () {
        this.loaded = false;
        const stream = await getStream();
        if (!stream) {
          this.$noty.show(this.$t('videoConstructor.notifications.connectCameraAndMic'));
          return;
        }
        this.webcam.srcObject = stream;
        this.webcam.muted = true;
        await this.webcam.play();
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];

        this.allEventListener.dataavailable = (event) => {
          this.audioChunks.push(event.data);
        };
        this.allEventListener.stopRecord = () => {
          this.status = 'edit';
          const audioBlob = new Blob(this.audioChunks, {
            type: 'video/mp4',
          });

          this.audioChunks = [];
          this.source = audioBlob;

          this.initializeEditVideo(URL.createObjectURL(audioBlob));
          clearInterval(this.timer);
          this.recordingTime = 0;
        };
        this.mediaRecorder.addEventListener(
          'dataavailable',
          this.allEventListener.dataavailable
        );
        this.allEventListener.onTimer = () => {
          // eslint-disable-next-line no-return-assign
          this.timer = setInterval(() => this.recordingTime += 0.1, 100);
        };
        this.allEventListener.offTimer = () => clearInterval(this.timer);
        this.audioChunks = [];
        this.mediaRecorder.addEventListener('start', this.allEventListener.onTimer);
        this.mediaRecorder.addEventListener('pause', this.allEventListener.offTimer);
        this.mediaRecorder.addEventListener('resume', this.allEventListener.onTimer);
        this.mediaRecorder.addEventListener('stop', this.allEventListener.stopRecord);
        this.loaded = true;
      },
      initializeEditVideo (videoUrl) {
        if (this.webcam.srcObject) {
          this.webcam.srcObject.getTracks().forEach(track => {
            track.stop();
          });
          this.webcam.srcObject = null;
        }
        this.webcam.src = videoUrl;
        this.webcam.muted = false;
        this.webcam.load();

        this.webcam.addEventListener(
          'loadedmetadata',
          e => {
            if (this.webcam.duration === Infinity) {
              this.webcam.currentTime = 1e101;
              this.webcam.addEventListener(
                'timeupdate',
                e => {
                  this.duration = Number(this.webcam.duration.toFixed(3));
                  this.status = 'edit';
                },
                { once: true }
              );
            } else {
              this.duration = Number(this.webcam.duration.toFixed(3));
              this.webcam.currentTime = 0;
            }
          },
          { once: true }
        );
        this.allEventListener.timeupdate = e => {
          this.time = e.target.currentTime;
          this.setCurrentAction();
          if (e.currentTarget.currentTime === e.currentTarget.duration) {
            this.videoPause = true;
          }
        };
        this.webcam.addEventListener(
          'timeupdate',
          this.allEventListener.timeupdate
        );
        this.webcam.play();
      },
      rewind () {
        this.webcam.currentTime = this.time;
      },

      checkScrollDirectionIsUp (event) {
        if (event.wheelDelta) {
          return event.wheelDelta > 0;
        }
        return event.deltaY < 0;
      },
      getSection () {
        // const imgHeight = 1;
        const imgHeight = this.$refs.img.offsetHeight - this.$refs.background.offsetHeight;
        let index = this.scrollMarks.findIndex(mark => {
          const minus = Math.floor(imgHeight * mark.top) - 2 >= Math.floor(this.$refs.background.scrollTop);
          const plus = Math.floor(imgHeight * mark.top) + 2 >= Math.floor(this.$refs.background.scrollTop);
          return minus || plus;
        });
        if (index === -1) {
          index = this.scrollMarks.length - 1;
        }
        if (this.activeIndex !== index) {
          this.activeIndex = index;
          if (this.status !== 'edit' && !this.nativeScrolling) {
            this.addScrollDebounced(this.scrollMarks[index], index, 0.3);
          }
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
.video-editor {
  &__inner {
    position: relative;
    display: flex;
    gap: 24px;
    margin-bottom: 16px;
    max-height: 50vh;
    overflow: hidden;
  }

  &__scroll-page {
    display: block;
    width: 100%;

    &-wrapper {
      width: 100%;
      max-height: 50vh;
      overflow: scroll;
      filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.15));
      border-radius: 8px;
    }
  }
  &__scroll-points {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &-item {
      align-self: end;
      margin: 0 7px 4px 0;
      transition-duration: 0.3s;
    }
  }
  &__camera {
    height: 100%;
    width: 100%;
    object-fit: cover;
    &_rotate-180 {
      transform: rotateY(180deg);
    }

    &-wrapper {
      position: absolute;
      left: 16px;
      bottom: 16px;
      overflow: hidden;
      border-radius: 50%;
      &_size {
        &_small {
          width: 100px;
          height: 100px;
        }

        &_medium {
          width: 140px;
          height: 140px;
        }

        &_large {
          width: 180px;
          height: 180px;
        }
      }
    }
  }
  &__settings {
    width: 600px;
  }
}

.overlay-loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.4s;
  z-index: -1;
  opacity: 0;
  border-radius: 8px;

  &_active {
    opacity: 1;
    z-index: 999;
  }

  &__progstat {
    height: 1px;
    background: #fff;
    position: absolute;
    width: 0;
    top: 50%;
  }

  &__progress {
    font-size: 0.7em;
    letter-spacing: 3px;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    width: 100%;
    text-align: center;
    color: #fff;
  }
}

.slider-tooltip {
  position: relative;

  &__delete {
    position: absolute;
    bottom: -60px;
    color: #EB5757;
    width: 20px;
    font-size: 20px;
    border-radius: 5px;
    text-align: center;
    border-color: #ffffff;
    background-color: #ffffff;
    left: calc(50% - 15px);
    cursor: pointer;

    &::after {
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      height: 0;
      width: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 5px;
      border-bottom-color: inherit;
    }
  }
}
</style>
