<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="companies">
    <div class="companies__header">
      <span class="companies__text">
        {{ $tc('common.company', 2) }}
      </span>
      <span
        v-if="companies.length && selectedCrm !== 'pipedrive'"
        class="companies__text companies__text_link text_xs"
        @click="addCompanies"
      >
        <i class="bx bx-plus" />
        {{ $t('actions.add') }}
      </span>
    </div>
    <div
      v-if="!companies.length"
      class="companies__add"
      :class="{'companies__add_disabled': selectedCrm === 'default'}"
      @click="addCompanies"
    >
      <span
        class="companies__text text_xs"
        :class="{'color_secondary': selectedCrm === 'default'}"
      >
        <i
          v-if="selectedCrm !== 'default'"
          class="bx bx-plus"
        />
        {{
          selectedCrm !== 'default'
            ? $t('actions.add')
            : $t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.notifications.addingCompaniesSoon')
        }}
      </span>
    </div>
    <div
      v-else
      class="companies__block"
    >
      <crm-chosen-company-item
        v-for="company in companies"
        :key="company._id"
        :company="company"
        @delete-item="deleteItem"
      />
    </div>
  </div>
</template>
<script>

  import CrmChosenCompanyItem from '@/modals/CrmModals/CrmChosenCompanyItem';

  export default {
    name: 'ChosenCompanies',
    components: {
      CrmChosenCompanyItem,
    },
    props: {
      companies: {
        type: Array,
        default: () => [],
      },
      selectedCrm: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
      };
    },
    methods: {
      handleClose () {
        this.$emit('update:show', false);
      },
      addCompanies () {
        this.$emit('add-item', true);
      },
      deleteItem (item, isCompany) {
        this.$emit('delete-item', item, isCompany);
      },
    },
  };
</script>

<style lang="scss">
.companies {
  display: flex;
  flex-flow: column;
  flex: 1;
  gap: 8px;
  &__block {
    max-height: 360px;
    padding: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-flow: column;
    flex: 1;
    gap: 8px;
  }
  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__position {
    display: flex;
    flex-flow: column;
  }
  &__image {
    border-radius: 100%;
    width: 16px;
    height: 16px;
  }
  &__add {
    min-height: 92px;
    width: 244px;
    border: 1px dashed var(--secondary-text-color);
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-cl);
    &:hover {
      opacity: 0.7;
    }
    &_disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
    &_link {
      color: var(--primary-cl);
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    &_margin-left {
      margin-left: auto;
    }
  }
}
</style>
