<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="flex-row flex-gap-8">
    <default-button
      v-if="!isModal"
      :title="$t('actions.cancel')"
      color="neutral"
      form="rectangle"
      size="m"
      @action="$router.push($route.meta.traceback)"
    />
    <default-button
      :title="$route.params.videoId ? $t('actions.update') : $t('actions.save')"
      color="primary"
      form="rectangle"
      size="m"
      :is-disabled="isDisabled"
      @action="$emit('action')"
    />
  </div>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  export default {
    components: {
      DefaultButton,
    },
    props: {
      hasUpdate: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      isModal () {
        return this.$route.name !== 'videos-constructor';
      },
      isDisabled () {
        return this.$route.params.videoId && !this.hasUpdate;
      },
    },
  };
</script>
