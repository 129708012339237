/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const capitalize = (string) => string[0].toUpperCase() + string.slice(1);
export const copyTextBuffer = (string) => {
  navigator.clipboard.writeText(string);
};

export const getSnakeCase = str => str.toLowerCase().replace(/\s+/ig, '_');
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const isObject = (item) => {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
};
