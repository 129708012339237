<template>
  <div class="flex flex-gap-8 flex-align-items-center">
    <img
      class="image image_32"
      alt=""
      src="~@/assets/defaultCompany.svg"
    >
    <div class="flex-column">
      <span class="text_s text_weight_accent">
        {{ experience?.positions[0].name || $t('inboxPage.chatSection.infoSidebar.experienceInfo.noPosition') }}
      </span>
      <a
        :target="experience?.link ? '_blank' : '_self'"
        :href="experience?.link || '#'"
        :class="[
          'text_xs color_tertiary',
          {'hovered_primary': experience?.link}
        ]"
      >
        {{ experience?.name || '' }}
      </a>
      <p
        v-if="isExperience"
        class="text_xs color_tertiary"
      >
        {{ experience.positions[0].start }} - {{ experience.positions[0].end }}
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ItemExperience',
    props: {
      experience: {
        type: Object,
        default: null,
      },
      isExperience: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
