<template>
  <div
    class="flex flex-align-items-center"
    :class="[
      'modal-group-tab',
      { 'background_neutral_100': isActive },
    ]"
  >
    <div class="flex-row flex-gap-8">
      <i :class="['bx', group.category.icon]" />
      <p class="text_s text_nowrap">
        {{ group.category.name }}
      </p>
    </div>
    <div class="modal-group-tab__right flex-row flex-gap-4 flex-justify-content-between">
      <div
        v-if="hasSelectedItem"
        class="modal-group-tab__dote"
      />
      <i
        v-if="isActive"
        class="bx flex bx-chevron-right margin-left"
      />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ModalGroupTab',
    props: {
      group: {
        type: Object,
        required: true,
      },
      isActive: {
        type: Boolean,
        required: true,
      },
      hasSelectedItem: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>
<style lang="scss">
.modal-group-tab {
  //@include flex-row-between;
  padding: 12px;
  border-radius: $border-radius-big;
  cursor: pointer;
  transition: $transition-1;
  &__right {
    width: 100%;
  }
  &__dote {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--primary-cl);
  }
}
</style>
