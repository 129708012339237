<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<!-- eslint-disable vue/no-v-html !-->
<template>
  <li
    class="new-template-item"
    @click="chooseTemplate"
  >
    <div
      class="new-template-item__favorite"
      @click.stop="selectFavorites"
    >
      <i
        class="bx"
        :class="{'bxs-star color_yellow': template.is_favorite,
                 'bx-star color_tertiary': !template.is_favorite }"
      />
    </div>
    <div class="new-template-item__info">
      <span class="text_xs"> {{ template.name }} </span>
      <profile-labels
        :labels="template.labels"
      />
    </div>
    <default-tooltip
      v-if="!isStep"
      :items="isAdmin ? dataTooltipForAdmin : dataTooltip"
      class="margin-left"
      data-closable="dataTooltipTemplates"
      :show.sync="showTooltip"
      :has-triangle="false"
      min-width="170"
      max-height="200"
      @choose="tooltipHandler"
    >
      <template #default>
        <i class="bx bx-dots-vertical-rounded" />
      </template>
    </default-tooltip>
  </li>
</template>

      <script>
  import { updateFavorite } from '@/api/TemplateMethods';
  import { addTemplateToMarketplace } from '@/api/TemplateMarketplaceMethods';
  import { mapGetters } from 'vuex';
  import ProfileLabels from '@/components/base/profileItems/ProfileLabels.vue';

  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';

  export default {
    name: 'TemplateItem',
    components: {
      DefaultTooltip,
      ProfileLabels,
    },
    props: {
      template: {
        type: Object,
        default: () => {},
      },
      fullContent: {
        type: Boolean,
        default: true,
      },
      isStep: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        showMore: false,
        showDeleteModal: false,
        showTooltip: false,
        labels: [],
        dataTooltip: [
          { name: 'Duplicate', iconName: 'bx-duplicate' },
          { name: 'Delete', iconName: 'bx-trash', isDelete: true },
        ],
        dataTooltipForAdmin: [
          { name: 'Duplicate', iconName: 'bx-duplicate' },
          { name: 'Add to marketplace', iconName: 'bx-plus-circle' },
          { name: 'Delete', iconName: 'bx-trash', isDelete: true },
        ],
      };
    },
    computed: {
      ...mapGetters({
        isAdmin: 'account/isAdmin',
      }),
    },
    mounted () {
    },
    methods: {
      closeTooltip () {
        this.showMore = false;
      },
      chooseTemplate () {
        this.$emit('choose-template', this.template, 'edit');
      },
      async tooltipHandler (item) {
        if (item.name === 'Add to marketplace') {
          this.showMore = false;
          try {
            await addTemplateToMarketplace(this.template._id);
            this.$noty.success('Add to marketplace');
          } catch (e) {
            this.$noty.error(e.message);
          }
        }
        if (item.isDelete) {
          this.$emit('open-modal-delete', this.template);
          return;
        }
        if (item.name === 'Duplicate') {
          this.$router.push({
            name: 'edit-template',
            params: {
              template: this.template,
              isDuplicate: true,
            },
          });
        }
      },

      async selectFavorites () {
        const isFavorite = this.template.is_favorite;
        try {
          await updateFavorite(this.template._id, !isFavorite);
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      getIcon () {
        if (this.template) {
          switch (this.template.type) {
          case 'li_connect':
            return 'UserPlusIcon';
          case 'li_send_message':
            return 'MessageSquareIcon';
          case 'li_send_inmail':
            return 'MessageCircleIcon';
          case 'send_email':
            return 'MailIcon';
          default:
            return 'MessageCircleIcon';
          }
        }
      },
    },
  };
      </script>
      <style lang="scss">
      .new-template-item {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 8px 12px;
        border-radius: 4px;
        background: var(--neutral-50);
        min-height: 60px;
        cursor: pointer;
          &__favorite {
            cursor: pointer;
          }
        &__info {
          display: flex;
          gap: 4px;
          flex-flow: column;
        }
        }
      </style>
