/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { mapGetters } from 'vuex';
export const setAltImg = {
  computed: {
    ...mapGetters({
      theme: 'theme',
    }),
  },
  methods: {
    setAltImg (event) {
      const img = this.theme === 'dark' ? require('@/assets/images/defaultImageDark.png') : 'https://api.divo.ai/public_api/profiles/default_image';
      event.target.src = img;
    },
    setSrcImg (url) {
      if (url && !url.includes('data:image') && !url.includes('/public_api/profiles/default_image')) {
        return url;
      }
      if (this.theme === 'dark') {
        return require('@/assets/images/defaultImageDark.png');
      } else {
        return 'https://api.divo.ai/public_api/profiles/default_image';
      }
    },
  },
};
