<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="perform-action-step">
    <div
      v-for="param in params"
      :key="param.name"
      class="perform-action-step__item"
      @click="changeActions(param)"
    >
      <div class="perform-action-step__menu">
        <span class="perform-action-step__text text_s">{{ param.name }}</span>
        <default-switcher
          :is-checked="actions.includes(param._id)"
          size="s"
        />
      </div>
      <div class="perform-action-step__desc">
        <span class="perform-action-step__text text_xs color_secondary">{{ param.desc }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher';
  import { useI18n } from '@/i18n';

  const props = defineProps({
    brand: {
      type: String,
      default: '',
    },
    actions: {
      type: Array,
      default: () => [],
    },
  });

  const emit = defineEmits(['change-actions']);

  const { t } = useI18n();

  const params = ref([
    {
      name: t('campaignsPage.editor.steps.performAction.switchers.viewProfile'),
      _id: 'li_view_profile',
    },
    {
      name: t('campaignsPage.editor.steps.performAction.switchers.followProfile'),
      _id: 'li_follow_profile',

    },
    {
      name: t('campaignsPage.editor.steps.performAction.switchers.likeLastPosts'),
      _id: 'li_fetch_posts',

    },
    {
      name: t('campaignsPage.editor.steps.performAction.switchers.endorseTopSkills'),
      _id: 'li_endorse_skills',
    },
  ]);

  const changeActions = (action) => {
    emit('change-actions', action._id);
  };
</script>

<style lang="scss">
.perform-action-step {
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 8px;
  &__item {
    padding: 16px;
    border: $border;
    border-radius: 8px;
    display: flex;
    flex-flow: column;
    gap: 4px;
    cursor: pointer;
  }
  &__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
