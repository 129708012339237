/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function checkToken () {
  const data = await useHttp(
    '/auth/validate_token',
    'GET'
  );

  return data.ok;
}

async function createToken (params) {
  const data = await useHttp(
    '/auth/create_token',
    'POST',
    {},
    {
      params,
      brand: process.env.VUE_APP_BRAND,
    }
  );
  localStorage.setItem('token', data.payload.token);
}

export { checkToken, createToken };
