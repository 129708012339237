/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const BEST_PRACTICE = [
  {
    _id: 'send_email',
    data: [
      {
        subject: 'Stay ahead of the competition. Special offer for {first_name} {last_name}',
        message: 'Hi {first_name},\n' +
          'We know it’s not easy [LEAD’S GOAL].\n' +
          'Staying ahead of your competition, especially in the [LEAD’S INDUSTRY] industry, is essential. Time has to be spent every single day to make sure you make the right decision and stay on top.\n' +
          'Efficient. Reliable. Trustworthy. These are just a few things you become when you don’t have to spend hours [LEAD’S PAIN POINT]. [YOUR COMPANY NAME] is here for you. [NAME AND VERY SHORT PITCH] is the solution you’ve been looking for.\n' +
          'Let us help you get back into rhythm. Your time and effort deserve to be matched with the prowess of [YOUR COMPANY NAME].\n' +
          'Reply to schedule a demo with our specialist now.\n' +
          'Best regards,\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
      },
      {
        message: 'Hello {first_name}, \n' +
          '\n' +
          'A [LEAD’S COMPANY DESCRIPTION] needs the right tools to flourish. So how do you [YOUR PRODUCT’S SELLING POINT], or, better yet, [YOUR PRODUCT’S SELLING POINT 2]?\n' +
          '\n' +
          '[YOUR PRODUCT’S MAIN FUNCTION] with [YOUR COMPANY NAME]. \n' +
          'Grow your profits and efficiency without sacrificing quality. With us, you will [YOUR PRODUCT’S MAIN SELLING POINT]. \n' +
          'Try [PRODUCT NAME] now here [LINK TO PRODUCT PAGE OR PRICING] or reply to this email for more details.\n' +
          '\n' +
          'Have a great day,\n' +
          '\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Frustrated with [LEAD’S PAIN POINT]?',
      },
      {
        message: 'Re: An offer for {first_name}' +
          '\n' +
          '[MAIN PROBLEM YOU SOLVE] can be tiresome.\n' +
          '\n' +
          'Let [YOUR COMPANY NAME] carry that burden for you.\n' +
          '[SELLING POINT 1], [SELLING POINT 2], [SELLING POINT 3], and so much more.\n' +
          '\n' +
          'Take the chance [LINK]. You won’t regret it.\n' +
          '\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Re: An offer for {first_name}',
      },
      {
        message: 'Hi {first_name}!\n' +
          '\n' +
          'I know you’re very busy. [LEAD’S PROBLEM 1], [LEAD’S PROBLEM 2], and [LEAD’S PROBLEM 3] slowly bringing you down?\n' +
          '\n' +
          'What if I told you you can [YOUR PRODUCT’S FUNCTION 1] and [YOUR PRODUCT’S FUNCTION 2] while [MAIN SELLING POINT]?\n' +
          '\n' +
          'Look no further than [YOUR COMPANY NAME]’s [YOUR PRODUCT NAME]. With [PRODUCT’S SELLING POINT], it can [LEAD’S DESIRED RESULT].\n' +
          '\n' +
          'Make sure to check out [LINK] the specifics of the product here if you want to leave all your [LEAD’S PAIN POINT] problems in the past.\n' +
          '\n' +
          'Best regards,\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Specifics',
      },
      {
        message: 'Do you ever wish there was an easy way to [LEAD’S PAIN POINT]?\n' +
          ' [YOUR COMPANY NAME] is utilizing the latest technology to make sure you don’t [PAIN POINT 1], [PAIN POINT 2], or [PAIN POINT 3] and always stay on top.\n' +
          'If you are not interested, no worries! Thank you for giving me a minute of your time.\n' +
          'If you want to explore what [YOUR COMPANY NAME] has to offer, you can find more details here [LINK TO YOUR PRODUCT PAGE].\n' +
          'Kindest regards,\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'An offer from [YOUR COMPANY NAME]. Let’s schedule a call.',
      },
      {
        message: 'Hi {first_name}!\n' +
          'My name is [YOUR NAME], I’m the [YOUR POSITION] with [YOUR COMPANY]. We have recently unveiled our new [SHORT PITCH]. \n' +
          'My company has been really interested in speaking with someone from [YOUR LEAD’S COMPANY NAME] responsible for [WHAT YOU WANT FROM THEIR COMPANY]. From what I understand, you are the person we need. Would you be open to a call to discuss how our company can help [YOUR LEAD’S COMPANY NAME] with [LEAD’S MAIN PAIN POINT]? \n' +
          '\n' +
          'If that’s not you, could you please put me in touch with the person I could discuss this with?\n' +
          'Thank you.\n' +
          '\n' +
          'Best regards,\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Quick request',
      },
      {
        message: 'Hey {first_name}, \n' +
          '\n' +
          'My name is [YOUR NAME]. \n' +
          'I found your company on LinkedIn and can’t help but praise the ideas your company is currently promoting in the field. Quick question: what are your future plans in regards to [THE PROBLEM YOUR COMPANY IS SOLVING]? I think I may have some ideas for you. Let me know what you think. \n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'A quick question',
      },
      {
        message: 'Hello {first_name}, \n' +
          'Taking risks in [INDUSTRY] can make or break a business. Time to make the switch to [YOUR COMPANY NAME], [NAME AND VERY SHORT PITCH] to help with all of your [BUSINESS NEEDS]. With [YOUR COMPANY NAME] you can:\n' +
          '• [PROBLEM 1 THAT YOUR PRODUCT SOLVES] \n' +
          '• [PROBLEM 2 THAT YOUR PRODUCT SOLVES] \n' +
          '• [PROBLEM 3 THAT YOUR PRODUCT SOLVES]  \n' +
          '• [PROBLEM 4 THAT YOUR PRODUCT SOLVES].\n' +
          '[YOUR COMPANY NAME] is the only service on the market that takes care of all your [LEAD’S PAIN POINT] concerns. Let me hear you out and find the right solution to what is bothering you. Are you open to a call this Thursday? \n' +
          'Best regards, \n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Your business deserves the best when it comes to [LEAD’S PAIN POINT]',
      },
      {
        message: 'Hey {first_name}.\n' +
          'Our company has always put a big focus on [YOUR COMPANY SERVICES]. Usually, a company like ours would be burning through the budget [YOUR LEAD’S PAINPOINT]. We know this killed many a startup. \n' +
          'Thankfully, we ourselves have created a solution that doesn\'t just get the job done, but also helps us save time, money, and human resources. We found a way to bring together everything you might need for [LEAD’S PAINPOINT] under one roof - [YOUR COMPANY SERVICES]. We even offer a [YOUR OFFER]. \n' +
          'This way we found a way to save thousands of dollars and make our [LEAD’S PAINPOINT] more efficient. And we know a solution like this can be useful for you too. Would you be interested in a demo or a call next week? \n' +
          'Best regards, \n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME].',
        subject: 'Can help with [LEAD’S PROBLEM]',
      },
      {
        message: 'Hello {first_name}! \n' +
          'Finally, an easy-to-use, all-in-one [PRODUCT’S FUNCTION] solution. \n' +
          '[YOUR PRODUCT’S FUNCTION 1], [YOUR PRODUCT’S FUNCTION 2], [YOUR PRODUCT’S FUNCTION 3], and [YOUR PRODUCT’S FUNCTION 4], all within a single platform. No more [LEAD’S PAINPOINT] - save your time and budget without sacrificing the quality. Save up to [AMOUNT SAVED] on [PRODUCT’S FUNCTION].\n' +
          '[YOUR COMPANY NAME] is exactly what a [LEAD’S STATUS] like yours needs at this stage. What better time to check it out? \n' +
          'Cheers, \n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME].',
        subject: 'An improved [PRODUCT’S FUNCTION] solution',
      },
      {
        message: 'Hi {first_name},\n' +
          '\n' +
          '[INDUSTRY] has become much more crowded in recent years, yet your company seems to have grown and become one of the leaders in the market despite competition. \n' +
          '\n' +
          '[YOUR COMPANY NAME] can help you save money and time by automating the tasks like [YOUR PRODUCT’S FUNCTION 1], [YOUR PRODUCT’S FUNCTION 2], [YOUR PRODUCT’S FUNCTION 3], and [YOUR PRODUCT’S FUNCTION 4]. This means more resources, more closed deals, and more profit. \n' +
          '\n' +
          'If you\'re interested, you can try the all-in-one solution for free now, or get back to me about a demo. \n' +
          '\n' +
          'Best regards,\n' +
          '\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: '[LEAD’S PAINPOINT] budget solution',
      },
      {
        message: 'You’re not alone. [CUSTOMER\'S NAME] at [CUSTOMER\'S COMPANY] had the same problem trying to solve [LEAD’S PAIN POINT]. \n' +
          '\n' +
          'That’s why they chose [YOUR SOLUTION] and [YOUR COMPANY NAME]. Since choosing [YOUR COMPANY NAME], [CUSTOMER\'S NAME] has seen incredible results: \n' +
          '\n' +
          '[AMAZING STAT 1] \n' +
          '[AMAZING STAT 2] \n' +
          '[AMAZING STAT 3].\n' +
          'You can check out their full story here [LINK: HERE]. \n' +
          '\n' +
          'How can we help your [BOTTOM LINE, ROI, ETC.]? Let’s talk about it. I’d love 10 minutes of your time to show you how. What day works for you?\n' +
          '\n' +
          'Talk soon!\n' +
          '\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Tell me if this sounds familiar: [LEAD’S PAIN POINT]?',
      },
      {
        message: 'Hi {first_name}, \n' +
          '\n' +
          'At [YOUR COMPANY NAME], we’re checking in on both our customers and community this week to offer help in any way we can.\n' +
          '\n' +
          'I know at [LEAD’S COMPANY NAME] you handle [JOB TASK]. I reached out to our own [INTERNAL SIMILAR JOB TITLE] here at [YOUR COMPANY NAME] to see what resources they’re digging into right now. Here’s what they recommended: [ARTICLE TITLE, LINKED].\n' +
          '\n' +
          'If you, like the rest of us, are looking for a bit of direction right now, I’m happy to [ACTION ITEM; Example: connect you with someone, similar resources, etc.]. In the meantime, here’s a feel-good moment for you: а short video from [A VIDEO e.g. John Krasinski offering up Some Good News, an article, a study, etc. link].\n' +
          '\n' +
          'Enjoy!\n' +
          '\n' +
          '[YOUR FIRST NAME]',
        subject: 'Сasual reading for your downtime',
      },
      {
        message: 'Hi {first_name} \n' +
          '\n' +
          'I recorded a video of your buyer’s journey:\n' +
          '\n' +
          '[THE RECORDING, LINKED]\n' +
          '\n' +
          'My only step after filling out the form is to wait ⏳ hours, days… But I’m on the website right now - why not enable me to immediately book a meeting with a rep?\n' +
          '\n' +
          'With [YOUR COMPANY NAME], companies like [YOUR CUSTOMER NAMES] use [TOOL] to [YOUR FEATURES, WITH RESULTS]. Can I share a mockup of [YOUR COMPANY NAME] on your website?\n' +
          '\n' +
          '[YOUR FIRST NAME]',
        subject: '[LEAD’S COMPANY NAME]’s buyer’s journey',
      },
      {
        message: 'Hi {first_name},\n' +
          '\n' +
          '[YOUR COMPANY NAME] offers [YOUR OFFER].\n' +
          '\n' +
          'It allows you to [PRODUCT FEATURE 1] and set tasks to [PRODUCT FEATURE 2], all under one roof.\n' +
          '\n' +
          'That means you save time and [LEAD’S BENEFIT].\n' +
          '\n' +
          'Are you free for a call tomorrow so you can tell me more about how we can improve your results?\n' +
          '\n' +
          'Sincerely,\n' +
          '\n' +
          '[YOUR FIRST NAME] [YOUR LAST NAME]',
        subject: 'Have you figured out how to tackle [LEAD’S PAINPOINT] yet?',
      },
    ],
  },
  {
    _id: 'li_send_message',
    data: [
      {
        message: 'Hey {first_name}, thanks for accepting my invite. I\'ve been following {company_name} for some time. I love the brand and your mission!\n' +
          'I work with ((YOUR COMPANY)) and we have a product that can help {company_name} ((INSERT PRODUCT BENEFIT)).\n' +
          'If you have XX minutes to spare over the next week or so, I\'d love to show you how it works?\n',
      },
      {
        message: 'Hi {first_name}. I know you\'re probably very busy! \n' +
          'Would it be easier if I sent you a PDF with a brief overview of ((PRODUCT NAME)) and how it helps ((INSERT PRODUCT BENEFIT))?\n',
      },
      {
        message: 'Hi {first_name}. I thought I would try to reach out one last time. \n' +
          'If you have XX minutes for a quick demo, I\'d love to show you how ((PRODUCT NAME)) can help {company_name}.\n' +
          'I promise it won\'t take more than XX minutes, and I\'ll even send you a cup of coffee!\n',
      },
      {
        message: 'Hi {first_name}, always great to connect with fellow {college_name} alumni! I graduated in ((INSERT GRADUATING YEAR)).\n' +
          'I see you work for {company_name}. I work with ((YOUR COMPANY)) and our product ((PRODUCT NAME)), ((INSERT PRODUCT BENEFIT)).\n' +
          'I was hoping, as a fellow {college_name} graduate, you would have XX minutes to spare for a quick demo?\n',
      },
      {
        message: 'Hey {first_name}, I hope you are well.\n' +
          'I just wanted to follow up. Are you available sometime in the next week for a XX minute call?\n',
      },
      {
        message: 'Hey {first_name}, I realize you\'re probably incredibly busy and probably don\'t have time for a call.\n' +
          'If it\'s easier, I can pop you a PDF with all the information on ((PRODUCT NAME)) and how it\'s helped companies like {company_name}?',
      },
      {
        message: 'Great to connect with you {first_name}! Thank you for accepting my invite.\n' +
          'I see you\'re also {location}-based. I work for ((YOUR COMPANY)) based in {location} and our product ((PRODUCT NAME)) is perfect for companies like {company_name}.\n' +
          'Do you have XX minutes during the next week for a quick call? I promise not to take more than XX minutes of your time!\n',
      },
      {
        message: 'Hi {first_name}, I hope you\'re having a great week.\n' +
          'I know you\'re probably busy, so I thought I\'d give you a brief overview of what ((PRODUCT NAME)) does and how {company_name} can benefit.\n' +
          '((INSERT ELEVATOR PITCH - 4-5 sentences MAXIMUM))\n' +
          'Are you available for a XX minute call during the next week or so?\n',
      },
      {
        message: 'Hi {first_name} I thought I would try one last time to connect.\n' +
          'If you don\'t have time for a XX minute call, I can send you a PDF with an overview of ((PRODUCT NAME))?\n',
      },
      {
        message: 'Thank you for accepting my invite {first_name}. It\'s great to connect with someone from {company_name}!\n' +
          'I work for ((YOUR COMPANY)) and we\'re changing the way companies ((INSERT KEY BENEFIT - i.e., send email/manage tasks/build products)).\n' +
          'Do you have XX minutes sometime next week for a call? I promise not to take up more than XX minutes.\n',
      },
      {
        message: 'Hi {first_name}, just a quick follow-up to see if you\'re open to a XX minute call? I\'m happy to accommodate a time that’s suitable for you.\n',
      },
      {
        message: 'Hi {first_name}, I realize you might be too busy for a call, so I thought I\'d give you a quick overview of our product ((PRODUCT NAME)).\n' +
          '((INSERT ELEVATOR PITCH - 4-5 sentences MAXIMUM))\n' +
          'Can you see how ((PRODUCT NAME)) can help {company_name}?\n',
      },
      {
        message: 'Hi {first_name}, thank you for accepting my invite! It\'s great to connect with someone from {company_name}.\n' +
          'I work for ((YOUR COMPANY)) and our product ((PRODUCT NAME)) is having a positive impact on revenue for companies in ((INDUSTRY/MARKET)).\n' +
          'Do you have XX minutes for a call this week or next? I\'m happy to accommodate your schedule.\n',
      },
      {
        message: 'Hi {first_name}, just thought I\'d follow up. You\'re probably busy, so I thought I\'d send you a brief overview here.\n' +
          '((INSERT ELEVATOR PITCH - 4-5 sentences MAXIMUM))\n' +
          'Could we set up a XX minute call to demonstrate how ((PRODUCT NAME)) can help boost ((ie: revenue/signups/leads)) for {company_name}.\n',
      },
      {
        message: 'Hi {first_name}. I trust you are well.\n' +
          'I don\'t want to trouble you, so I thought I\'d pop you one last message to see if we can connect on a XX minute call? I\'m happy to book a time that suits you.\n' +
          'Look forward to connecting with you.\n',
      },
      {
        message: 'Thank you for accepting my invite, {first_name}.\n' +
          'I\'ve been trying to connect with someone from {company_name}. I work for ((YOUR COMPANY)). Our product ((PRODUCT)) helps companies like {company_name} increase ((ie: sign ups/sales/leads/productivity)).\n' +
          'Could I ask for XX minutes of your time to demonstrate how ((PRODUCT)) can help {company_name}? I\'m happy to accommodate your schedule.\n',
      },
      {
        message: 'Hi {first_name}, I know you\'re busy, so I thought I\'d give you a quick overview of ((PRODUCT)).\n' +
          '((INSERT ELEVATOR PITCH - 4-5 sentences MAXIMUM))\n' +
          'Are you available for a quick XX minute call? Happy to find a time that suits you.\n',
      },
      {
        message: 'Hi {first_name}, I promise this will be my last message.\n' +
          'If you don\'t have time for a call, could I send you a PDF with an overview of ((PRODUCT))? You can look at it in your own time.\n' +
          'I look forward to hearing from you.\n',
      },
      {
        message: 'Thank you for accepting my invite {first_name}. It\'s great to connect with someone from {company_name}.\n' +
          'I work for ((YOUR COMPANY)). We\'re a startup changing how companies ((INSERT KEY BENEFIT - i.e., send email/manage tasks/build products)).\n' +
          'If you have XX minutes, I\'d love to show you {company_name} can benefit from our product ((PRODUCT NAME)). \n' +
          'Could we schedule a XX minute call sometime over the next week or so?\n',
      },
      {
        message: 'Hi {first_name}, I just wanted to follow up to see if you\'re available for a quick call? \n' +
          'I\'m happy to accommodate your schedule. I promise not to take more than XX minutes of your time!\n',
      },
    ],
  },
  {
    _id: 'li_send_inmail',
    data: [
      {
        subject: 'Stay ahead of the competition. Special offer for {first_name} {last_name}',
        message: 'Hi {first_name},\n' +
          'We know it’s not easy [LEAD’S GOAL].\n' +
          'Staying ahead of your competition, especially in the [LEAD’S INDUSTRY] industry, is essential. Time has to be spent every single day to make sure you make the right decision and stay on top.\n' +
          'Efficient. Reliable. Trustworthy. These are just a few things you become when you don’t have to spend hours [LEAD’S PAIN POINT]. [YOUR COMPANY NAME] is here for you. [NAME AND VERY SHORT PITCH] is the solution you’ve been looking for.\n' +
          'Let us help you get back into rhythm. Your time and effort deserve to be matched with the prowess of [YOUR COMPANY NAME].\n' +
          'Reply to schedule a demo with our specialist now.\n' +
          'Best regards,\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
      },
      {
        message: 'Hello {first_name}, \n' +
          '\n' +
          'A [LEAD’S COMPANY DESCRIPTION] needs the right tools to flourish. So how do you [YOUR PRODUCT’S SELLING POINT], or, better yet, [YOUR PRODUCT’S SELLING POINT 2]?\n' +
          '\n' +
          '[YOUR PRODUCT’S MAIN FUNCTION] with [YOUR COMPANY NAME]. \n' +
          'Grow your profits and efficiency without sacrificing quality. With us, you will [YOUR PRODUCT’S MAIN SELLING POINT]. \n' +
          'Try [PRODUCT NAME] now here [LINK TO PRODUCT PAGE OR PRICING] or reply to this email for more details.\n' +
          '\n' +
          'Have a great day,\n' +
          '\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Frustrated with [LEAD’S PAIN POINT]?',
      },
      {
        message: 'Re: An offer for {first_name}' +
          '\n' +
          '[MAIN PROBLEM YOU SOLVE] can be tiresome.\n' +
          '\n' +
          'Let [YOUR COMPANY NAME] carry that burden for you.\n' +
          '[SELLING POINT 1], [SELLING POINT 2], [SELLING POINT 3], and so much more.\n' +
          '\n' +
          'Take the chance [LINK]. You won’t regret it.\n' +
          '\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Re: An offer for {first_name}',
      },
      {
        message: 'Hi {first_name}!\n' +
          '\n' +
          'I know you’re very busy. [LEAD’S PROBLEM 1], [LEAD’S PROBLEM 2], and [LEAD’S PROBLEM 3] slowly bringing you down?\n' +
          '\n' +
          'What if I told you you can [YOUR PRODUCT’S FUNCTION 1] and [YOUR PRODUCT’S FUNCTION 2] while [MAIN SELLING POINT]?\n' +
          '\n' +
          'Look no further than [YOUR COMPANY NAME]’s [YOUR PRODUCT NAME]. With [PRODUCT’S SELLING POINT], it can [LEAD’S DESIRED RESULT].\n' +
          '\n' +
          'Make sure to check out [LINK] the specifics of the product here if you want to leave all your [LEAD’S PAIN POINT] problems in the past.\n' +
          '\n' +
          'Best regards,\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Specifics',
      },
      {
        message: 'Do you ever wish there was an easy way to [LEAD’S PAIN POINT]?\n' +
          ' [YOUR COMPANY NAME] is utilizing the latest technology to make sure you don’t [PAIN POINT 1], [PAIN POINT 2], or [PAIN POINT 3] and always stay on top.\n' +
          'If you are not interested, no worries! Thank you for giving me a minute of your time.\n' +
          'If you want to explore what [YOUR COMPANY NAME] has to offer, you can find more details here [LINK TO YOUR PRODUCT PAGE].\n' +
          'Kindest regards,\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'An offer from [YOUR COMPANY NAME]. Let’s schedule a call.',
      },
      {
        message: 'Hi {first_name}!\n' +
          'My name is [YOUR NAME], I’m the [YOUR POSITION] with [YOUR COMPANY]. We have recently unveiled our new [SHORT PITCH]. \n' +
          'My company has been really interested in speaking with someone from [YOUR LEAD’S COMPANY NAME] responsible for [WHAT YOU WANT FROM THEIR COMPANY]. From what I understand, you are the person we need. Would you be open to a call to discuss how our company can help [YOUR LEAD’S COMPANY NAME] with [LEAD’S MAIN PAIN POINT]? \n' +
          '\n' +
          'If that’s not you, could you please put me in touch with the person I could discuss this with?\n' +
          'Thank you.\n' +
          '\n' +
          'Best regards,\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Quick request',
      },
      {
        message: 'Hey {first_name}, \n' +
          '\n' +
          'My name is [YOUR NAME]. \n' +
          'I found your company on LinkedIn and can’t help but praise the ideas your company is currently promoting in the field. Quick question: what are your future plans in regards to [THE PROBLEM YOUR COMPANY IS SOLVING]? I think I may have some ideas for you. Let me know what you think. \n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'A quick question',
      },
      {
        message: 'Hello {first_name}, \n' +
          'Taking risks in [INDUSTRY] can make or break a business. Time to make the switch to [YOUR COMPANY NAME], [NAME AND VERY SHORT PITCH] to help with all of your [BUSINESS NEEDS]. With [YOUR COMPANY NAME] you can:\n' +
          '• [PROBLEM 1 THAT YOUR PRODUCT SOLVES] \n' +
          '• [PROBLEM 2 THAT YOUR PRODUCT SOLVES] \n' +
          '• [PROBLEM 3 THAT YOUR PRODUCT SOLVES]  \n' +
          '• [PROBLEM 4 THAT YOUR PRODUCT SOLVES].\n' +
          '[YOUR COMPANY NAME] is the only service on the market that takes care of all your [LEAD’S PAIN POINT] concerns. Let me hear you out and find the right solution to what is bothering you. Are you open to a call this Thursday? \n' +
          'Best regards, \n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Your business deserves the best when it comes to [LEAD’S PAIN POINT]',
      },
      {
        message: 'Hey {first_name}.\n' +
          'Our company has always put a big focus on [YOUR COMPANY SERVICES]. Usually, a company like ours would be burning through the budget [YOUR LEAD’S PAINPOINT]. We know this killed many a startup. \n' +
          'Thankfully, we ourselves have created a solution that doesn\'t just get the job done, but also helps us save time, money, and human resources. We found a way to bring together everything you might need for [LEAD’S PAINPOINT] under one roof - [YOUR COMPANY SERVICES]. We even offer a [YOUR OFFER]. \n' +
          'This way we found a way to save thousands of dollars and make our [LEAD’S PAINPOINT] more efficient. And we know a solution like this can be useful for you too. Would you be interested in a demo or a call next week? \n' +
          'Best regards, \n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME].',
        subject: 'Can help with [LEAD’S PROBLEM]',
      },
      {
        message: 'Hello {first_name}! \n' +
          'Finally, an easy-to-use, all-in-one [PRODUCT’S FUNCTION] solution. \n' +
          '[YOUR PRODUCT’S FUNCTION 1], [YOUR PRODUCT’S FUNCTION 2], [YOUR PRODUCT’S FUNCTION 3], and [YOUR PRODUCT’S FUNCTION 4], all within a single platform. No more [LEAD’S PAINPOINT] - save your time and budget without sacrificing the quality. Save up to [AMOUNT SAVED] on [PRODUCT’S FUNCTION].\n' +
          '[YOUR COMPANY NAME] is exactly what a [LEAD’S STATUS] like yours needs at this stage. What better time to check it out? \n' +
          'Cheers, \n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME].',
        subject: 'An improved [PRODUCT’S FUNCTION] solution',
      },
      {
        message: 'Hi {first_name},\n' +
          '\n' +
          '[industry] has become much more crowded in recent years, yet your company seems to have grown and become one of the leaders in the market despite competition. \n' +
          '\n' +
          '[YOUR COMPANY NAME] can help you save money and time by automating the tasks like [YOUR PRODUCT’S FUNCTION 1], [YOUR PRODUCT’S FUNCTION 2], [YOUR PRODUCT’S FUNCTION 3], and [YOUR PRODUCT’S FUNCTION 4]. This means more resources, more closed deals, and more profit. \n' +
          '\n' +
          'If you\'re interested, you can try the all-in-one solution for free now, or get back to me about a demo. \n' +
          '\n' +
          'Best regards,\n' +
          '\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: '[LEAD’S PAINPOINT] budget solution',
      },
      {
        message: 'You’re not alone. [CUSTOMER\'S NAME] at [CUSTOMER\'S COMPANY] had the same problem trying to solve [LEAD’S PAIN POINT]. \n' +
          '\n' +
          'That’s why they chose [YOUR SOLUTION] and [YOUR COMPANY NAME]. Since choosing [YOUR COMPANY NAME], [CUSTOMER\'S NAME] has seen incredible results: \n' +
          '\n' +
          '[AMAZING STAT 1] \n' +
          '[AMAZING STAT 2] \n' +
          '[AMAZING STAT 3].\n' +
          'You can check out their full story here [LINK: HERE]. \n' +
          '\n' +
          'How can we help your [BOTTOM LINE, ROI, ETC.]? Let’s talk about it. I’d love 10 minutes of your time to show you how. What day works for you?\n' +
          '\n' +
          'Talk soon!\n' +
          '\n' +
          '[YOUR NAME], [YOUR POSITION] at [COMPANY NAME]',
        subject: 'Tell me if this sounds familiar: [LEAD’S PAIN POINT]?',
      },
      {
        message: 'Hi {first_name}, \n' +
          '\n' +
          'At [YOUR COMPANY NAME], we’re checking in on both our customers and community this week to offer help in any way we can.\n' +
          '\n' +
          'I know at [LEAD’S COMPANY NAME] you handle [JOB TASK]. I reached out to our own [INTERNAL SIMILAR JOB TITLE] here at [YOUR COMPANY NAME] to see what resources they’re digging into right now. Here’s what they recommended: [ARTICLE TITLE, LINKED].\n' +
          '\n' +
          'If you, like the rest of us, are looking for a bit of direction right now, I’m happy to [ACTION ITEM; Example: connect you with someone, similar resources, etc.]. In the meantime, here’s a feel-good moment for you: а short video from [A VIDEO e.g. John Krasinski offering up Some Good News, an article, a study, etc. link].\n' +
          '\n' +
          'Enjoy!\n' +
          '\n' +
          '[YOUR FIRST NAME]',
        subject: 'Сasual reading for your downtime',
      },
      {
        message: 'Hi {first_name} \n' +
          '\n' +
          'I recorded a video of your buyer’s journey:\n' +
          '\n' +
          '[THE RECORDING, LINKED]\n' +
          '\n' +
          'My only step after filling out the form is to wait ⏳ hours, days… But I’m on the website right now - why not enable me to immediately book a meeting with a rep?\n' +
          '\n' +
          'With [YOUR COMPANY NAME], companies like [YOUR CUSTOMER NAMES] use [TOOL] to [YOUR FEATURES, WITH RESULTS]. Can I share a mockup of [YOUR COMPANY NAME] on your website?\n' +
          '\n' +
          '[YOUR FIRST NAME]',
        subject: '[LEAD’S COMPANY NAME]’s buyer’s journey',
      },
      {
        message: 'Hi {first_name},\n' +
          '\n' +
          '[YOUR COMPANY NAME] offers [YOUR OFFER].\n' +
          '\n' +
          'It allows you to [PRODUCT FEATURE 1] and set tasks to [PRODUCT FEATURE 2], all under one roof.\n' +
          '\n' +
          'That means you save time and [LEAD’S BENEFIT].\n' +
          '\n' +
          'Are you free for a call tomorrow so you can tell me more about how we can improve your results?\n' +
          '\n' +
          'Sincerely,\n' +
          '\n' +
          '[YOUR FIRST NAME] [YOUR LAST NAME]',
        subject: 'Have you figured out how to tackle [LEAD’S PAINPOINT] yet?',
      },
    ],
  },
  {
    _id: 'li_connect',
    data: [
      {
        message: 'Hey {first_name}! I\'m trying to expand my network with ((TARGET INDUSTY)) professionals. I came across your profile and thought there might be some synergy.',
      },
      {
        message: 'Hello {first_name}! I wanted to connect as I feel we might have some common business interests.',
      },
      {
        message: 'Hi {first_name}! I\'m trying to expand my network with professionals in ((TARGET LOCATION)). I look forward to connecting with you.',
      },
      {
        message: 'Hi {first_name}, I came across your profile while searching for professionals in your space.',
      },
      {
        message: 'Hey {first_name}! I have a fantastic product I know will help you generate more revenue. If you have XX minutes, I\'d love to show you how it works.',
      },
      {
        message: 'Hey {first_name}, you have an impressive resume! I\'m trying to expand my network of ((INDUSTRY)) professionals in {location}. I look forward to connecting with you.',
      },
      {
        message: 'Hi {first_name}! Yes, this is a sales pitch! But I have a fantastic product I know your company will benefit from. All I need is “XX” minutes of your time.',
      },
      {
        message: 'Hey {first_name}, I\'m a ((YOUR JOB TITLE))  at ((YOUR COMPANY)). We have an innovative way for ((INDUSTRY)) companies to ((VALUE PROP)).',
      },
      {
        message: 'Hey {first_name}, I\'m also ((LOCATION))-based and want to grow my network with people in ((INDUSTRY)). I look forward to connecting with you.',
      },
      {
        message: 'Hey {first_name}, I\'m ((YOUR NAME)) from ((YOUR COMPANY)). I see our business interests align. I hope you\'re open to connecting with me.',
      },
      {
        message: 'Hi {first_name}, I\'m always looking to grow my network in {location}. I hope you\'re open to connecting!',
      },
      {
        message: 'Hey {first_name}, we don\'t know each other, but I also went to ((COLLEGE NAME)). I hope you\'re open to connecting.',
      },
      {
        message: 'Hi {first_name}, I\'ve been trying to connect with people in {company_name}\'s sales team. I hope you\'re open to connecting with me.',
      },
      {
        message: 'Hi {first_name}, I love networking with people in {location}. I hope you\'re open to connecting.',
      },
      {
        message: 'Hello {first_name}! I came across your profile while looking for ((INDUSTRY)) professionals. I look forward to connecting.',
      },
      {
        message: 'Hello {first_name}. I was hoping to connect and set up a meeting with you. It\'ll only take “XX” minutes of your time.',
      },
      {
        message: 'I {first_name}! Since we both went to {college_name}, there might be some synergy for us to connect.',
      },
      {
        message: 'Hi {first_name}, I see you\'ve been in the ((INDUSTRY)) for {total_years_in_career}! I have a fantastic product I know someone with your experience will find interesting. I hope you\'re open to connecting.',
      },
      {
        message: 'Hi {first_name}, I\'ve been networking with professionals in ((INDUSTRY)), and your name keeps popping up in my suggestions. I hope you\'re open to connecting!',
      },
    ],
  },
];
