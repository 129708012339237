<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="crm-switcher__select-crm"
  >
    <div
      v-closable="{
        exclude: ['selectCrm'],
        handler: () => isSelectCrm = false,
        params: 'isSelectCrm',
      }"
      class="crm-switcher__selected-crm"
      @click="isSelectCrm = !isSelectCrm"
    >
      <img loading="lazy"
        :src="require(`@/assets/integrationCRM/${$route.params.integration}.svg`)"
        alt="logo pipedrive"
      >
      <i
        class="crm-switcher__selected-crm-arrow bx bxs-down-arrow"
        :class="{'crm-switcher__selected-crm-arrow_open': isSelectCrm}"
      />
    </div>
    <ul
      ref="selectCrm"
      class="crm-switcher__select-items"
      :class="{'crm-switcher__select-items_open': isSelectCrm,
               'crm-switcher__select-items_close': !isSelectCrm,
      }"
    >
      <li
        v-for="(crm, i) in crms"
        :key="i"
        class="crm-switcher__select-item"
        @click="selectCrm(crm)"
      >
        <img loading="lazy"
          :src="require(`@/assets/integrationCRM/${crm}.svg`)"
          :alt="`logo ${crm}`"
        >
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: 'CrmSwitcher',
    props: {
      crms: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        isSelectCrm: false,
      };
    },
    methods: {
      selectCrm (crm) {
        this.isSelectCrm = false;
        this.$emit('select-crm', crm);
      },
    },
  };
</script>
<style lang="scss">
.crm-switcher {
  &__select-crm {
    position: relative;
    display: flex;
    flex-flow: column;
    transition: 300ms;
  }

  &__selected-crm {
    z-index: 99;
    padding: 3px 4px 0px 4px;
    position: relative;
    cursor: pointer;

  &-arrow {
      position: absolute;
      top: 15px;
      right: 16px;
      font-size: 8px;
      color: #fff;
      z-index: 101;
      transition: 400ms;

    &_open {
        transform: rotate(180deg);
      }
    }
  }

  &__select-items {
    position: absolute;
    padding: 0px 4px;
    background: var(--background-color);
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06), 0px 0px 8px rgba(10, 32, 51, 0.05);
    transition: 300ms;

  &_open {
      top: 40px;
      opacity: 1;
      z-index: 9;
    }

  &_close {
      top: 0px;
      opacity: 0;
      z-index: 0;
    }
  }

  &__select-item {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
