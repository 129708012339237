var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uikit-tooltip"},[_c('div',{staticClass:"uikit-tooltip__inner"},[_c('div',{ref:"showListBtn",attrs:{"data-closable":_vm.dataClosable},on:{"click":function($event){$event.stopPropagation();return _vm.showListTooltip.apply(null, arguments)}}},[_vm._t("default")],2),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBlocks[_vm.dataClosable]),expression:"showBlocks[dataClosable]"}],class:[
        'uikit-tooltip__list',
        'uikit-tooltip__list_' + _vm.listPosition,
        {'uikit-tooltip__list_fulled': _vm.isFulled },
      ],style:({
        maxHeight: _vm.maxHeight ? _vm.maxHeight + 'px' : _vm.maxHeight,
        width: _vm.listWidth,
      })},[_vm._t("firstItemList"),(_vm.items.length)?_vm._l((_vm.items),function(item,i){return _c('li',{key:i + item.name,staticClass:"uikit-tooltip__item",class:[item.class || '' , item.disabled && 'uikit-tooltip__item_disabled'],on:{"click":function($event){$event.stopPropagation();return _vm.choose(item)}}},[(item.iconName)?_c('i',{class:[
              'uikit-tooltip__icon',
              'bx',
              item.iconName,
              { 'uikit-tooltip__icon_right': _vm.iconPosition },
            ]}):_vm._e(),(item.img)?_c('default-avatar',{attrs:{"size":"xxxs","photo-link":item.img}}):_vm._e(),_c('p',{staticClass:"text_s text_ellipsis"},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.markSelected && item.value === _vm.selectedItem.value)?_c('i',{staticClass:"bx bx-check color_primary margin-left"}):_vm._e()],1)}):[_c('span',{staticClass:"text_s color_secondary"},[_vm._v(" "+_vm._s(_vm.emptyState)+" ")])],_vm._t("lastItemList")],2),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.show && _vm.hasTriangle),expression:"show && hasTriangle"}],staticClass:"bx bxs-up-arrow uikit-tooltip__triangle"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }