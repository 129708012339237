/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const redirectToSetSpaceMixin = {
  methods: {
    async redirectToSetSpace () {
      if (!['privacy-policy', 'terms-of-use', 'set-space', 'join-space', 'accept-invite', 'create-space'].includes(this.$route.name)) {
        this.$route.name !== 'set-space' && await this.$router.push('/space/set-space');
      }
    },
  },

};
