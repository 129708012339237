<template>
  <div class="responser-emails">
    <header class="responser-emails__header">
      <p class="text_s color_white">
        {{ $t('inboxPage.chatSection.actions.newMessage') }}
      </p>
      <default-button
        icon-name="bx-x"
        color="neutral"
        form="circle"
        size="s"
        @action="$emit('close')"
      />
    </header>
    <div
      ref="vldParent"
      class="flex-column flex-gap-16 p-2 vld-parent"
      :class="{'responser-emails_bordered': $route.name === 'my-prospects'}"
    >
      <div class="flex-column flex-gap-8">
        <div class="responser-emails__settings">
          <p class="text_s color_tertiary">
            {{ $t('inboxPage.chatSection.email.from') }}
          </p>
          <select-emails
            :selected="selectedIntegration"
            :items="transformedIntegrations"
            @select="selectedIntegration = $event"
          />
        </div>
        <div class="responser-emails__settings">
          <p class="text_s color_tertiary">
            {{ $t('inboxPage.chatSection.email.to') }}
          </p>
          <select-emails
            :selected="emailToObj"
            :items="emailsToList"
            @select="emailToObj = $event"
          />
        </div>
        <div class="responser-emails__settings">
          <p class="text_s color_tertiary">
            {{ $t('inboxPage.chatSection.email.subject') }}
          </p>
          <default-input
            :value.sync="emailSubject"
            size="xs"
          />
        </div>
      </div>
      <quill-textarea
        ref="quillBlock"
        class="responser-emails__quill-textarea"
        @change="emailChange"
        @text-click="handleQuillClick"
      />
      <default-button
        class="flex-align-self-end"
        :title="$t('actions.send')"
        color="primary"
        form="rectangle"
        size="s"
        :is-disabled="!isValidForm"
        @action="sendEmailHandler"
      />
    </div>
  </div>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import SelectEmails from '@/components/inboxPage/chat/emails/responser/SelectEmails';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import QuillTextarea from '@/components/updateWorkflow/performAction/editActions/quillTextarea';

  import { ContenteditableMixin } from '@/mixins/ContenteditableMixin';
  import { mapGetters } from 'vuex';
  import { sendEmail } from '@/api/profilesMethods';
  import { loaderMixin } from '@/mixins/loaderMixin';

  export default {
    name: 'EmailsResponser',
    components: {
      DefaultButton,
      SelectEmails,
      DefaultInput,
      QuillTextarea,
    },
    mixins: [ContenteditableMixin, loaderMixin],
    props: {
      lastEmail: {
        type: Object,
        default: () => {},
      },
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectedIntegration: {},
        emailToObj: {},
        emailSubject: '',
        emailBody: '',
      };
    },
    computed: {
      ...mapGetters({
        integrationsList: 'integrations/integrationsList',
        selectedCredential: 'credentials/selectedCredential',
      }),
      transformedIntegrations () {
        return this.integrationsList
          .filter(i => i.status === 'connected')
          .map(i => ({
            icon: this.getIconName(i.type),
            _id: i._id,
            email: i.email,
          }));
      },
      emailsToList () {
        const result = [
          {
            icon: 'MailIcon',
            email: this.profile.email,
          },
          {
            icon: 'MailIcon',
            email: this.profile.work_email,
          },
          {
            icon: 'MailIcon',
            email: this.profile.enriched_data.enriched_emails?.at(-1)?.email,
          },
        ];
        return result.filter(i => i.email);
      },
      isValidForm () {
        return this.emailBody && this.emailToObj.email && this.selectedIntegration._id;
      },
    },
    watch: {
      lastEmail () {
        this.init();
      },
    },
    beforeMounted () {
      this.init();
    },
    methods: {
      init () {
        this.selectedIntegration = this.getSelectedIntegration();
        this.emailToObj = this.emailsToList.find(i => i.email === this.lastEmail?.sender) || this.emailsToList[0] || {};
        this.emailSubject = this.lastEmail?.subject;
      },
      getSelectedIntegration () {
        const usedIntegration = this.transformedIntegrations.find(i =>
          i.type === this.lastEmail?.provider && i.email === this.lastEmail?.receiver
        );
        if (usedIntegration) {
          return {
            icon: this.getIconName(this.lastEmail.provider),
            _id: usedIntegration._id,
            email: this.lastEmail.receiver,
          };
        }
        return this.transformedIntegrations[0];
      },
      getIconName (integrationType) {
        switch (integrationType) {
        case 'smtp':
          return 'MailIcon';
        case 'google':
          return 'GmailIcon';
        case 'microsoft':
          return 'OutlookIcon';
        case 'sendgrid':
          return 'SendGridIcon';
        case 'exchange':
          return 'ExchangeIcon';
        case 'amazon':
          return 'AwsIcon';
        }
      },
      emailChange ({ quill }) {
        this.emailBody = quill.root.innerHTML;
        this.handleQuillChange({ quill });
      },
      async sendEmailHandler () {
        try {
          this.loaded = false;
          this.emailBody = this.emailBody.replace(/<p>\s*<br>\s*<\/p>/g, '<span><br></span>');
          const email = await sendEmail(
            this.profile._id,
            this.selectedCredential._id,
            this.emailToObj.email,
            this.selectedIntegration._id,
            this.emailSubject,
            this.emailBody
          );
          this.emailSubject = '';
          this.emailBody = '';
          this.$refs.quillBlock.quill.root.innerHTML = '';
          this.$emit('send-email', email);
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
    },
  };
</script>
<style lang="scss">
.responser-emails {
  border-radius: 8px;
  background: var(--background-color);

  &_bordered {
    border: 1px solid var(--neutral-200);
    border-radius: 0 0 8px 8px;
    border-top: none;
  }

  &__header {
    @include flex-row-between;
    padding: 8px 16px;
    border-radius: 8px 8px 0 0;
    background: var(--primary-cl);
  }

  &__settings {
    display: grid;
    grid-template-columns: 65px auto;
    gap: 8px;
    align-items: center;
    justify-content: start;
  }

  &__quill-textarea {
    width: 100%;
    max-height: 140px;
    padding: 0;
  }
}
</style>
