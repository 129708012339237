<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <li
    :class="[
      'uikit-radio-button',
      'uikit-radio-button_size_' + size,
    ]"
  >
    <input
      :name="name"
      class="uikit-radio-button__input"
      type="radio"
      :value="options.value"
      :checked="isSelected"
    >
    <label
      class="uikit-radio-button__label"
      @click="$emit('select', options)"
    >
      {{ options.label || options.value }}
    </label>
  </li>
</template>
<script>
  export default {
    name: 'RadioButton',
    props: {
      name: {
        type: String,
        default: '',
      },
      options: {
        type: Object,
        default: () => {
          return {
            label: '',
            value: '',
          };
        },
        required: true,
      },
      isSelected: {
        type: Boolean,
        required: true,
      },
      size: {
        type: String,
        default: 's',
      },
    },
  };
</script>
<style lang="scss">
  .uikit-radio-button {
    &_size {
      &_m {
        .uikit-radio-button__label {
          @include text_m;

          &::before {
            width: 24px;
            height: 24px;
            border: 6px solid $cl-neutral-200;
          }
        }

        .uikit-radio-button__input:checked + .uikit-radio-button__label::before {
          border: 6px solid var(--primary-cl);
        }
      }

      &_s {
        .uikit-radio-button__label {
          @include text_m;

          &::before {
            width: 16px;
            height: 16px;
            border: 4px solid $cl-neutral-200;
          }
        }

        .uikit-radio-button__input:checked + .uikit-radio-button__label::before {
          border: 4px solid var(--primary-cl);
        }
      }
    }

    &__input {
      display: none;
    }

    &__label {
      @include flex-row;
      cursor: pointer;

      &::before {
        box-sizing: border-box;
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 8px;
        background: $cl-neutral-200;
        transition: $transition-1;
      }
    }

    &__input:not(:checked) + &__label:hover::before {
      background: $cl-neutral-300;
    }

    &__input:checked + &__label::before {
      background: $cl-white;
      border: 6px solid var(--primary-cl);
    }
  }
</style>
