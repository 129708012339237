/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getHealthIndex, getHealthStatistics, getHealthMainStatistics } from '@/api/AdminMethods.js';

const state = () => ({
  healthIndex: [],
  healthStatistics: [],
  healthMainStatistics: null,
});

const getters = {
  healthIndex: state => {
    return state.healthIndex;
  },
  healthStatistics: state => {
    return state.healthStatistics;
  },
  healthMainStatistics: state => {
    return state.healthMainStatistics;
  },
};

const mutations = {
  SET_HEALTH_INDEX (state, payload) {
    state.healthIndex = payload;
  },
  UPDATE_HEALTH_INDEX (state, payload) {
    const healthIndex = state.healthIndex.slice(0);
    const existingIndex = healthIndex.findIndex(health => health._id === payload._id);
    if (state.healthIndex._id === payload._id) {
      state.healthIndex = payload;
    }
    if (existingIndex > -1) {
      healthIndex.splice(existingIndex, 1, payload);
    } else {
      healthIndex.push(payload);
    }
    state.healthIndex = healthIndex;
  },
  SET_HEALTH_STATISTICS (state, payload) {
    state.healthStatistics = payload;
  },
  SET_HEALTH_MAIN_STATISTICS (state, payload) {
    state.healthMainStatistics = payload;
  },
};

const actions = {
  async SET_HEALTH_INDEX ({ commit }, payload) {
    const healthIndex = await getHealthIndex();
    // Мутирую для верстки
    healthIndex.forEach((health) => {
      health.show = false;
      health.edit = false;
    });
    commit('SET_HEALTH_INDEX', healthIndex);
  },
  async SET_HEALTH_STATISTICS ({ commit }, payload) {
    const healthStatistics = await getHealthStatistics();
    commit('SET_HEALTH_STATISTICS', healthStatistics);
  },
  async SET_HEALTH_MAIN_STATISTICS ({ commit }, payload) {
    const healthMainStatistics = await getHealthMainStatistics();
    commit('SET_HEALTH_MAIN_STATISTICS', healthMainStatistics);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
