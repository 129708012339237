
const state = () => {
  const viewSettings = JSON.parse(localStorage.getItem('viewSettings'));
  return {
    horizontal: viewSettings?.horizontal,
  };
};

const getters = {
  horizontal: state => {
    return state.horizontal;
  },

};

const mutations = {
  UPDATE_VIEW (state, update) {
    if ('horizontal' in update) {
      state.horizontal = update.horizontal;
    }
    localStorage.setItem('viewSettings', JSON.stringify(state));
  },
};

const actions = {
  UPDATE_VIEW ({ commit }, update) {
    commit('UPDATE_VIEW', update);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
