/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import i18n from '@/i18n';
const moment = require('moment');
moment.locale(i18n.locale);

export const fromNowFull = (date) => {
  return moment
    .utc(date)
    .startOf('minutes')
    .fromNow(true);
};
export const getFromNowUTC = (date, fullDateParts = false) => {
  const fullDate = moment
    .utc(date)
    .startOf('minutes')
    .fromNow(true);
  const dateParts = fullDate.split(' ');
  if (fullDateParts) {
    return fullDate;
  }
  let datePart = dateParts[1].slice(0, 3);

  switch (datePart) {
    case 'few':
      datePart = i18n.t('date.justNow').toLowerCase();
      break;
    case 'hou':
      datePart = i18n.t('date.abbreviations.hour');
      break;
    case 'day':
      datePart = i18n.t('date.abbreviations.day');
      break;
    case 'mon':
      datePart = i18n.t('date.abbreviations.month');
      break;
    case 'min':
      datePart = i18n.t('date.abbreviations.minute');
      break;
  }
  if (datePart === i18n.t('date.justNow').toLowerCase()) {
    return datePart;
  }
  return (
    (['a', 'an'].includes(dateParts[0]) ? '1' : dateParts[0]) + datePart
  );
};
export const fromNowFilterMixin = {
  filters: {
    fromNowFull,
  },
  methods: {
    getFromNowUTC,
    fromNowFull,
  },
};
