<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<!-- eslint-disable vue/no-v-html !-->
<template>
  <div class="preview-inmail">
    <div class="preview-inmail__block">
      <div class="preview-inmail__block__info">
        <h1>{{ selectedCredential.full_name }}</h1>
        <span> {{ selectedCredential.position }} </span>
      </div>
      <more-horizontal-icon
        size="1.5x"
        class="preview-inmail__block__icon"
      />
    </div>
    <div class="inmail-message">
      <span class="inmail-message__title">{{ subject }}</span>
      <div class="inmail-message__container">
        <div class="inmail-message__header">
          <div
            class="inmail-message__user-photo"
            :style="
              selectedCredential && selectedCredential.photo_link
                ? {
                  'background-image': `url(${selectedCredential.photo_link})`,
                }
                : null
            "
          >
            <icon
              v-if="!(selectedCredential && selectedCredential.photo_link)"
              size="1.5x"
            />
          </div>

          <div class="inmail-message__content">
            <span class="inmail-message__name">
              {{ selectedCredential.full_name }}
            </span>
            <span
              class="inmail-message__text"
              v-html="messageVideo"
            />
            <div class="inmail-message__button-group">
              <button>
                <edit-2-icon size="1x" />
                <span>Yes, Interested...</span>
              </button>
              <button class="ml-2">
                <edit-2-icon size="1x" />
                <span>No thanks...</span>
              </button>
            </div>
            <preview-video
              v-if="video && video._id"
              class="inmail-message__video"
              :video="video"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import '@fontsource/roboto';
  import { mapGetters } from 'vuex';
  import { MoreHorizontalIcon, Edit2Icon } from 'vue-feather-icons';
  import PreviewVideo from '@/components/TemplatePage/PreviewVideo';
  export default {
    name: 'PreviewInmail',
    components: {
      MoreHorizontalIcon,
      Edit2Icon,
      PreviewVideo,
    },
    props: {
      message: {
        type: String,
        default: '',
      },
      subject: {
        type: String,
        default: '',
      },
      video: {
        type: Object,
        default: null,
      },
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      messageVideo () {
        if (this.video?._id) {
          return this.message.replace(`{video | ${this.video.name}}`, this.video.preview_src);
        }
        return this.message;
      },
    },
  };
</script>
<style scoped lang="scss">
.preview-inmail {
  width: 100%;

  &__block {
    border: 1px solid #ebebeb;
    display: flex;
    padding: 6px 22px 4px 31px;
    position: relative;
    font-family: 'Roboto';

    &__icon {
      color: #c4c4c4;
      position: absolute;
      right: 10px;
      top: 13px;
    }
    & h1 {
      font-size: 18px;
    }
    & span {
      font-size: 14px;
      color: #b1b1b2;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
  }

  &__content {
    width: 100%;
    border-top: 1px solid #e0e2e8 !important;
    border-bottom: 1px solid #e0e2e8 !important;
    margin-top: 16px;
    padding: 16px 0;
    min-height: 258px;
    height: fit-content;
  }
}
.inmail-message {
  margin-top: 20px;
  &__title {
    font-weight: 600;
    font-size: 17px;
    color: #434343;
  }
  &__container {
    margin-top: 8px;
    border-top: 1px solid #e0e2e8;
    border-bottom: 1px solid #e0e2e8;
    padding: 13px 60px;
  }
  &__header {
    display: flex;
  }
  &__content {
    font-size: 14px;
    display: flex;
    margin-left: 12px;
    flex-direction: column;
  }
  &__name {
    font-weight: bold;
    font-size: 20px;
  }
  &__text {
    margin-top: 8px;
    white-space: pre-line;
  }
  &__video {
    margin-top: 12px;
  }
  &__user-photo {
    width: 58px;
    height: 58px;
    min-width: 58px;
    box-sizing: border-box;
    background: #f2f2f2;
    color: #c6c6c6;
    background-size: cover;
    border-radius: 50%;
    margin-bottom: 12px;
    display: grid;
    place-items: center;
    transition: 0.3s;
  }

  &__button-group {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    & button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: #00000099;
      border: none;
      box-sizing: border-box;
      cursor: pointer;
      font-family: inherit;
      background: none;
      font-size: 14px;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 167ms;
      height: 32px;
      border-radius: 1.6rem;
      color: #0a66c2;
      box-shadow: inset 0 0 0 1px #0a66c2;
      padding: 5px 15px;
      & span {
        border-left: 1px solid #0a66c2;;
        margin-left: 4px;
        padding-left: 4px;
      }
      &:hover {
        background-color: #70b5f933;
        box-shadow: inset 0 0 0 2px #0a66c2;
      }
    }
  }
}
</style>
