var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-message"},[_c('div',{staticClass:"preview-message__header"},[_c('div',{staticClass:"preview-message__info"},[_c('div',{staticClass:"preview-message__user-photo",style:(_vm.selectedCredential && _vm.selectedCredential.photo_link
            ? {
              'background-image': `url(${_vm.selectedCredential.photo_link})`,
            }
            : null)},[(!(_vm.selectedCredential && _vm.selectedCredential.photo_link))?_c('icon',{attrs:{"size":"1.5x"}}):_vm._e()],1),_c('div',[_c('div',{staticClass:"preview-message__user-info"},[_c('a',{staticClass:"preview-message__user-info_name"},[_vm._v(" "+_vm._s(_vm.selectedCredential.full_name)+" ")]),_c('span',{staticClass:"preview-message__user-info_position"},[_vm._v(" "+_vm._s(_vm.selectedCredential.position)+" ")]),_c('a',{staticClass:"preview-message__user-info_member-insights"},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/previewImages/rings.svg")}}),_c('span',[_vm._v(_vm._s(_vm.selectedCredential.full_name)+" and 9 others")])])])])]),_vm._m(0)]),(_vm.message)?_c('div',{staticClass:"preview-message__message-container"},[_vm._m(1),_c('span',{staticClass:"preview-message__message",domProps:{"innerHTML":_vm._s(_vm.message)}}),_c('div',{staticClass:"preview-message__anywhere-btn"},[_c('button',{staticClass:"btn-control btn-reply mt-1"},[_vm._v(" Reply to "+_vm._s(_vm.selectedCredential.full_name)+" ")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-message__button-group"},[_c('button',{staticClass:"btn-control btn_ignore"},[_vm._v(" Ignore ")]),_c('button',{staticClass:"ml-2 btn-control btn_accept"},[_vm._v(" Accept ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-message__message-icon"},[_c('img',{staticClass:"mercado-match",attrs:{"loading":"lazy","src":require("@/assets/previewImages/dots.svg")}})])
}]

export { render, staticRenderFns }