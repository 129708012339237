var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:[
    'uikit-list',
    'uikit-list_size_' + _vm.size,
    { 'uikit-list_activated': _vm.isActivatedList },
    { 'uikit-list_hovered': _vm.isHoveredList },
  ]},[_c('div',{staticClass:"flex-row flex-gap-8 overflow-hidden"},[(_vm.leftIconClasses || _vm.leftIconName)?_c('i',{class:`bx bx-${_vm.leftIconName} ${_vm.leftIconClasses}`}):_vm._e(),(_vm.hasCheckbox)?_c('checkbox',{attrs:{"is-active":_vm.isActiveCheckbox},on:{"select-checkbox":function($event){return _vm.$emit('select', $event)}}}):_vm._e(),(_vm.avatarPhotoLink || _vm.avatarStatusColor)?_c('default-avatar',{attrs:{"size":['m', 's'].includes(_vm.size) ? 'xxs' : 'xxxs',"photo-link":_vm.avatarPhotoLink,"status-color":_vm.avatarStatusColor}}):_vm._e(),_c('div',{staticClass:"overflow-hidden"},[_c('h1',{staticClass:"text_normal",class:[
          'text_ellipsis',
          _vm.titleColor ? 'color_' + _vm.titleColor : ''
        ]},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._t("title"),_c('p',{class:_vm.textColor ? 'color_' + _vm.textColor : ''},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_vm._t("text")],2)],1),_c('div',{staticClass:"flex-row flex-gap-8"},[(_vm.description)?_c('p',{class:_vm.descriptionColor ? 'text_' + _vm.descriptionColor : ''},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e(),(_vm.buttonTitle || _vm.buttonIconName)?_c('default-button',{attrs:{"title":_vm.buttonTitle,"color":_vm.buttonColor,"icon-name":_vm.buttonIconName,"is-disabled":_vm.isDisabledButton,"form":"rectangle","size":"s"},on:{"action":function($event){return _vm.$emit('button-action')}}}):_vm._e(),(_vm.rightIconClasses)?_c('i',{class:_vm.rightIconClasses}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }