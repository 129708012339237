import { ObjectID } from 'bson';
class ActionButton {
  /**
     * @param {string} title
     * @param {(...args: unknown[]) => void} handler
     */
  constructor (title, handler) {
    this.title = title;
    this.handler = handler;
  }
}
class NotificationOptions {
  /**
     * @param {string} title
     * @param {string} text
     * @param {string} hasClosedButton
     * @param {boolean} [withoutDuration]
     * @param {ActionButton} [actionButton]
     */
  constructor (title, text, hasClosedButton = false, withoutDuration = false, actionButton = null) {
    this.title = title;
    this.text = text;
    this.hasClosedButton = hasClosedButton;
    this.withoutDuration = withoutDuration;
    this.actionButton = actionButton;
  }
}

class Notification {
  /**
    * @param {'success' | 'error' | 'info' | 'warning'} type
    * @param {NotificationOptions} options
    */
  constructor (type, options) {
    this._id = new ObjectID().toHexString();
    this.type = type;
    if (typeof options === 'string') {
      this.text = options;
    } else {
      this.title = options.title || '';
      this.text = options.text || '';
      this.hasClosedButton = options.hasClosedButton || false;
      this.withoutDuration = options.withoutDuration || false;
      this.actionButton = options.actionButton || null;
    }
  }
}
export { Notification, NotificationOptions, ActionButton };
