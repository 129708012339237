<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<!-- eslint-disable vue/no-v-html !-->
<template>
  <div class="preview-video">
    <div class="preview-video__img">
      <img loading="lazy"
        src="../../assets/images/previewVideo.png"
        alt="previewVideo"
      >
      <video
        class="preview-video__video"
        :src="video.video_src"
      />
    </div>
    <div class="preview-video__footer">
      <span class="text_s">{{video.video_settings.title_template || video.name}}</span>
      <div class="flex flex-gap-8 flex-align-items-center">
        <span class="text_xs color_secondary">{{video.preview_src}}</span>
        <span class="preview-video__dot"/>
        <span class="text_xs color_secondary" >{{Math.floor(video.duration)}} seconds read</span>
      </div>
    </div>
  </div>
</template>
<script>
  import '@fontsource/roboto';
  import { mapGetters } from 'vuex';

  export default {
    name: 'PreviewVideo',
    components: {
    },
    props: {
      video: {
        type: Object,
        default: null,
      },
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
    },
    mounted () {
    },
  };
</script>
<style lang="scss">
.preview-video {
  background: #eef3f8;;
  border: 1px solid rgba(0,0,0,0.08);
  border-radius: 0.8rem;
  width: 396px;
  &__video {
    height: 64px;
    width: 64px;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    left: 30px;
    bottom: 30px;
  }
  &__img {
    position: relative;
    & img{
      width: 394px;
      height: 200px;
      border-radius: 0.8rem;
    }
  }
  &__dot {
    background: var(--secondary-text-color);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
  &__footer {
    padding: 12px 16px;
    display: flex;
    flex-flow: column;
    gap: 4px;
  }
}

</style>
