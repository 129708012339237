/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import i18n from '@/i18n';

const moment = require('moment');

export const getParseDate = (date) => {
  let newDate;
  const times = moment().valueOf() - moment(date).valueOf() + (new Date().getTimezoneOffset() * 60000);

  if (times < 31536000000) {
    newDate = i18n.tc('date.ago.month', 2, { n: Math.floor(times / 2419200000) });
    if (newDate === i18n.tc('date.ago.month', 1, { n: 1 })) {
      newDate = i18n.tc('date.ago.month', 1, { n: 1 });
    }
    if (newDate === i18n.tc('date.ago.month', 2, { n: 0 })) {
      newDate = i18n.tc('date.ago.week', 2, { n: Math.floor(times / 604800000) });
      if (newDate === i18n.tc('date.ago.week', 2, { n: 1 })) {
        newDate = i18n.tc('date.ago.week', 1, { n: 1 });
      }
      if (newDate === i18n.tc('date.ago.week', 2, { n: 0 })) {
        newDate = i18n.tc('date.ago.day', 2, { n: Math.floor(times / 86400000) });
        if (newDate === i18n.tc('date.ago.day', 2, { n: 1 })) {
          newDate = i18n.tc('date.ago.day', 1, { n: 1 });
        }
        if (newDate === i18n.tc('date.ago.day', 2, { n: 0 })) {
          newDate = i18n.tc('date.ago.hour', 2, { n: Math.floor(times / 3600000) });
          if (newDate === i18n.tc('date.ago.hour', 2, { n: 1 })) {
            newDate = i18n.tc('date.ago.hour', 1, { n: 1 });
          }
          if (newDate === i18n.tc('date.ago.hour', 2, { n: 0 })) {
            newDate = i18n.tc('date.ago.minute', 1, { n: Math.floor(times / 60000) });
            if (newDate === i18n.tc('date.ago.minute', 1, { n: 0 })) {
              newDate = i18n.t('date.justNow');
            }
          }
        }
      }
    }
  } else {
    newDate = moment(date).format('DD/MM/YYYY HH:mm');
  }

  return newDate;
};
