/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const ProfilesPage = () => import('@/views/prospectsPage/ProfilesPage');
const MyProfilesPage = () => import('@/views/prospectsPage/MyProfilesPage');
const SearchProfilesPage = () => import('@/views/prospectsPage/SearchProfilesPage');

const meta = {
  layout: 'DefaultLayout',
  hasSidebar: true,
};

const profiles = [
  {
    path: '/profiles',
    name: 'profiles',
    component: ProfilesPage,
    redirect: { name: 'my-prospects' },
    meta,
    children: [
      {
        path: 'my-prospects',
        name: 'my-prospects',
        component: MyProfilesPage,
        meta,
      },
      {
        path: 'search-prospects',
        name: 'search-prospects',
        component: SearchProfilesPage,
        meta,
      },
    ],

  },
];

export default profiles;
