<template>
  <div>
    <p class="text_xs color_tertiary mb-1">
      {{ $t('inboxPage.chatSection.infoSidebar.contactsInfo.sections.enriched.title') }}
    </p>
    <div
      v-if="prospect.enriched_data.enrichment_status === 'failed'"
      class="flex-row flex-gap-8"
    >
      <i class="bx bxs-diamond icon_size_16 color_tertiary" />
      <p class="text_xs">
        {{ $t('inboxPage.chatSection.infoSidebar.contactsInfo.sections.enriched.status.noEnrichedEmails') }}
      </p>
    </div>
    <default-button
      v-if="prospect.enriched_data.enrichment_status === 'not_enriched' && !contacts.length"
      class="margin-center"
      :title="$t('inboxPage.chatSection.infoSidebar.contactsInfo.sections.enriched.buttons.enrichProspect')"
      icon-name="bx-search"
      color="neutral"
      form="rectangle"
      size="m"
      @action="enrich"
    />
    <default-button
      v-if="prospect.enriched_data.enrichment_status === 'in_progress'"
      class="margin-center"
      :title="$t('inboxPage.chatSection.infoSidebar.contactsInfo.sections.enriched.status.enriching')"
      icon-name="bx-loader bx-spin"
      color="neutral"
      form="rectangle"
      size="m"
    />
    <div class="flex-column">
      <item-enriched-block
        v-for="contact in contacts"
        :key="contact.value"
        :contact="contact"
      />
    </div>
  </div>
</template>
<script>
  import ItemEnrichedBlock from '@/components/inboxPage/info/basic/contacts/ItemEnrichedBlock';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { mapGetters } from 'vuex';
  import { cloneDeep } from 'lodash';

  import { accessEmail } from '@/api/profilesMethods';

  export default {
    name: 'EnrichedBlockContacts',
    components: {
      ItemEnrichedBlock,
      DefaultButton,
    },
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        prospect: cloneDeep(this.profile),
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      contacts () {
        const result = [
          {
            name: this.$tc('common.email'),
            icon: 'bx bx-envelope',
            value: this.profile.enriched_data.enriched_emails?.at(-1)?.email,
          },
          {
            name: this.$tc('common.phone'),
            icon: 'bx bx-phone',
            value: this.profile.enriched_data.enriched_phones?.at(-1)?.phone,
          },
        ];
        return result.filter(c => c.value);
      },
    },
    methods: {
      async enrich () {
        try {
          this.prospect.enriched_data.enrichment_status = 'in_progress';
          await accessEmail([this.profile._id], 1, this.selectedCredential._id);
          this.prospect = this.profile;
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
  };
</script>
