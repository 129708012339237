<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="statistic-menu">
    <div
      v-for="item in stats"
      :key="item._id"
      class="statistic-menu__item"
    >
      <!--      <div class="statistic-menu__color"/>-->
      <div class="statistic-menu__info">
        <div class="statistic-menu__color" />
        <span class="text_xs">{{ item.name }}</span>
        <span class="text_s">{{ item.amount }}</span>
      </div>
      <i
        class="margin-left bx"
        :class="item.icon"
      />
    </div>
  </div>
</template>

<script>

  export default {
    name: 'StatisticMenu',
    components: {},
    mixins: [],
    props: {
    },
    data () {
      return {
        stats: [
          {
            name: 'Sent',
            icon: 'bx-send',
            amount: 90,
            _id: 'sent',
          },
          {
            name: 'Replied',
            icon: 'bx-reply',
            amount: 120,
            _id: 'replied',
          },
          {
            name: 'Clicked',
            icon: 'bx-pointer',
            amount: 80,
            _id: 'clicked',
          },
          {
            name: 'Opened',
            icon: 'bx-envelope-open',
            amount: 150,
            _id: 'opened',
          },
        ],
      };
    },
    computed: {
    },
    mounted () {
    },
    methods: {
    },
  };
</script>

<style lang="scss">
.statistic-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__item {
    background: var(--neutral-50);
    display: flex;
    align-items: center;
    padding: 16px;
    width: 200px;
    border-radius: 8px;
    position: relative;
  }
  &__info {
    display: flex;
    flex-flow: column;
  }
  &__color {
    height: 4px;
    width: 16px;
    background: #56C12C;
    border-radius: 8px;
    margin-bottom: 4px;
  }
  //&__color {
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  width: 8px;
  //  height: 100%;
  //  border-radius: 8px 0px 0px 8px;
  //  background: #00A71B;
  //}
}
</style>
