/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { computed } from 'vue';
import i18n from '@/i18n';

export const defaultFilters = computed(() => ([
  {
    category: {
      name: i18n.tc('defaultFilters.filters.title', 2),
      _id: 'filters',
      icon: 'bx-filter-alt',
    },
    items: [
      {
        name: i18n.t('defaultFilters.filters.unreadMessages'),
        _id: 'unread',
      },
      {
        name: i18n.t('defaultFilters.filters.startedWithin7Days'),
        _id: 'started_7days',
      },
      {
        name: i18n.t('defaultFilters.filters.noreplyWithin7Days'),
        _id: 'no_reply_7days',
      },
      {
        name: i18n.t('defaultFilters.filters.onlyPausedCampaigns'),
        _id: 'paused_campaigns',
      },
      {
        name: i18n.t('defaultFilters.filters.onlyWithNotes'),
        _id: 'with_notes',
      },
    ],
  },
  {
    category: {
      name: i18n.t('defaultFilters.statusInCampaign.title'),
      _id: 'statuses',
      icon: 'bx-station',
    },
    items: [
      {
        name: i18n.t('defaultFilters.statusInCampaign.gotReply'),
        _id: 'got_reply',
      },
      {
        name: i18n.tc('defaultFilters.statusInCampaign.lead', 2),
        _id: 'interested',
      },
      {
        name: i18n.tc('defaultFilters.statusInCampaign.customer', 2),
        _id: 'lead',
      },
    ],
  },
]));
