function setLOUIdentify (user, account) {
  if (!window.LOU) return;
  const isAdmin = account?.admins.some((acc) => user._id === acc);
  window.LOU.identify(user._id, {
    company: user.brand, // Replace this object with any user data for defining segments
    permissions: isAdmin ? 'admin' : 'worker',
    plan: 'premium',
  });
}
export { setLOUIdentify };
