<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    ref="table"
    class="crm-table"
    @mousedown.self.prevent="grabPage"
    @mouseup.self.prevent="unGrabPage"
    @mousemove.self.prevent="dragPage"
  >
    <crm-table-column
      v-for="(stage, i) in stages"
      :key="stage._id"
      :stage="stage"
      :index="i"
      :selected-pipeline="selectedPipeline"
      :deals.sync="stage.crm_deals"
      @open-crm-deal-modal="openCrmDealModal"
      @change-order="changeOrder"
      @select-menu-item="selectMenuItem"
      @save-stage="saveStage"
      @grab-page="grabPage"
      @un-grab-page="unGrabPage"
      @drag-page="dragPage"
      @load-deals="(data) => $emit('load-deals', data)"
      @delete-stage="deleteStage"
    />
    <div
      v-if="!isAddStage && selectedCrm === 'default'"
      class="crm-table__add-stage"
      @click="addStage('')"
    >
      <span class="crm-table__text text_xs">
        <i class="bx bx-plus" />
        {{ $t('pipelinesPage.modals.addStage.title') }}
      </span>
    </div>
    <validation-observer
      v-slot="{ passes }"
    >
      <form
        @submit.prevent="passes(saveStage)"
      >
        <div
          v-if="isAddStage"
          class="crm-table__add-stage-active"
        >
          <div class="crm-table__item-header" />
          <default-input
            size="s"
            validation-string="required"
            :value.sync="stageName"
            :placeholder="$t('pipelinesPage.modals.addStage.inputs.stageName.placeholder')"
          />
          <div class="crm-table__flex">
            <default-input
              size="s"
              :value.sync="probability"
              validation-string="required|integer|between:0,100"
              :placeholder="$t('pipelinesPage.modals.addStage.inputs.probability.placeholder')"
            />
            <default-button
              size="m"
              color="danger"
              form="circle"
              icon-name="bx-x"
              @action="isAddStage = false"
            />
            <default-button
              size="m"
              color="success"
              type="submit"
              form="circle"
              icon-name="bx-check"
            />
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>

  import CrmTableColumn from '@/components/CRMIntegration/CrmTableColumn.vue';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { ValidationObserver } from 'vee-validate';

  export default {
    name: 'CrmStagesTable',
    components: {
      DefaultButton,
      DefaultInput,
      CrmTableColumn,
      ValidationObserver,

    },
    mixins: [],
    props: {
      stages: {
        type: Array,
        default: () => [],
      },
      selectedPipeline: {
        type: Object,
        default: () => {},
      },
      loaded: {
        type: Boolean,
        default: true,
      },
      selectedCrm: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        isMousedown: false,
        isAddStage: false,
        stageName: '',
        pipelineName: '',
        probability: '',
        selectdStage: {},
        x: null,
        currentX: 0,
        menuFields: [
          {
            name: this.$t('actions.edit'),
            iconName: 'bx-edit-alt',
            _id: 'edit',
          },
          {
            name: this.$t('actions.delete'),
            iconName: 'bx-trash',
            _id: 'delete',
          },
        ],
      };
    },
    methods: {
      changeOrder (deal, i) {
        this.$emit('change-order', deal, i);
      },
      openCrmDealModal (stage, deal) {
        this.$emit('open-crm-deal-modal', stage, deal);
      },
      addStage (stage) {
        if (stage) {
          stage.edit = true;
        } else this.isAddStage = !this.isAddStage;
      },
      deleteStage (stage) {
        this.$emit('delete-stage', stage);
      },

      saveStage (stageName, probability, selectedPipeline, selectedStage) {
        probability = Number(probability);
        if (selectedStage) {
          if (probability >= 0 && Number.isInteger(probability)) {
            this.$emit('update-stage', stageName, probability, selectedPipeline, selectedStage);
          } else {
            this.$noty.error(
              this.$t('pipelinesPage.notifications.probabilityMustBePositive')
            );
          }
        } else this.$emit('add-stage', this.stageName, this.probability, this.selectedPipeline.external_id);
        this.stageName = '';
        this.probability = '';
        this.isAddStage = false;
      },
      selectMenuItem (item, stage) {
        if (item._id === 'delete') {
          this.$emit('delete-stage', stage);
        } else {
          this.addStage(stage);
        }
      },
      grabPage (e) {
        this.isMousedown = true;
        this.$refs.table.style.cursor = 'grabbing';
        if (this.currentX) {
          this.x = e.clientX + this.currentX; // Начальное положение мыши
        } else this.x = e.clientX;
      },
      dragPage (e) {
        if (this.isMousedown) {
          this.currentX = e.clientX; // Текущее положение мыши
          this.currentX = this.x - this.currentX; // Начальное положение - текущее положение
          this.$refs.table.scrollLeft = this.currentX; // Вывод положения курсора
          window.getSelection().removeAllRanges(); // Отменяем все выделения
        }
      },
      unGrabPage () {
        this.isMousedown = false;
        this.$refs.table.style.cursor = 'grab';
      },
    },
  };
</script>

<style lang="scss">
.crm-table {
  cursor: grab;
  display: flex;
  width: 100%;
  gap: 16px;
  overflow-y: hidden;
  padding: 16px;
  height: calc(100vh - 76px - 62px);
  &__add-deal, &__add-stage {
    min-height: 100px;
    width: 188px;
    border: 1px dashed var(--secondary-text-color);
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-cl);
    &:hover {
      opacity: 0.7;
    }
  }
  &__add-stage {
    height: 48px;
    min-height: 48px;
    flex-shrink: 0;
  }
  &__add-stage-active {
     padding: 8px;
    display: flex;
    min-width: 188px;
    max-width: 188px;
    position: relative;
    background: var(--table-bg);
    border-radius: 4px;
    margin-bottom: 8px;
    height: min-content;
    flex-flow: column;
    gap: 4px;
  &-header {
      background: var(--primary-cl);
      height: 4px;
      border-radius: 4px 4px 0px 0px;
      top: 0;
      width: 188px;
      left: 0;
      position: absolute;
    }
  }
  &__flex {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  &__dot {
    background: var(--secondary-text-color);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
  &__tooltip {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
    &_hidden {
      display: block;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
