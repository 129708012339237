<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <default-tooltip
    :class="{ 'pointer': readOnly }"
    :items="filteredItems"
    :data-closable="dataClosable"
    :is-fulled="true"
    :empty-state="emptyState"
    :max-height="maxHeight"
    :show.sync="isShowTooltip"
    :is-disabled="isDisabled"
    @choose="choose"
  >
    <template #default>
      <default-input
        :value="query"
        :input-id="inputId"
        :icon="icon"
        :left-img="leftImg"
        :name="name"
        :validation-string="validationString"
        :placeholder="placeholder"
        :size="inputSize"
        :description="description"
        :has-dropdown="true"
        :is-disabled="isDisabled"
        :is-fulled="isFulled"
        :read-only="readOnly"
        @input.native="search"
        @focus.native.capture="onFocus"
      />
    </template>
  </default-tooltip>
</template>
<script>
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';

  export default {
    name: 'DefaultSelect',
    components: {
      DefaultTooltip,
      DefaultInput,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      value: {
        type: [String, Number],
        required: true,
        default: '',
      },
      inputId: { // нужно для label
        type: String,
        default: null,
      },
      name: { // нужно для валидации
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      validationString: {
        type: String,
        default: '',
      },
      inputSize: {
        type: String,
        default: 's',
      },
      isFulled: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      description: {
        type: String,
        default: '',
      },
      maxHeight: {
        type: String,
        default: 'auto',
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      dataClosable: {
        type: String,
        default: '',
      },
      emptyState: {
        type: String,
        default: 'There are no items',
      },
      icon: {
        type: String,
        default: '',
      },
      leftImg: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        query: this.value,
        selectedItem: {},
        filteredItems: this.items.slice(),
        isShowTooltip: false,
        focusedOnce: false,
      };
    },
    watch: {
      items () {
        this.filteredItems = this.items.slice();
      },
      value () {
        if (!this.focusedOnce) {
          this.query = this.value;
        }
      },
    },
    mounted () {
      if (this.value) {
        this.query = this.value;
      }
    },
    methods: {
      choose (item) {
        this.selectedItem = item;
        this.query = item.name;
        this.$emit('select', item);
      },
      search (e) {
        this.query = e.target.value;
        this.makeSearch(this.query);
      },
      makeSearch (q) {
        const searchRegex = new RegExp(q.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'gi');
        if (q) {
          this.filteredItems = this.items.filter((item) =>
            item.name.search(searchRegex) > -1
          );
        } else {
          this.filteredItems = this.items.slice();
        }
      },
      onFocus () {
        this.focusedOnce = true;
      },
    },
  };
</script>
