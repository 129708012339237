/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getAllWorkers } from '@/api/workersMethods';

const state = () => ({
  workersList: null,
});

const getters = {
  workersList: state => {
    return state.workersList;
  },
};

const mutations = {
  SET_WORKERS_LIST (state, payload) {
    state.workersList = payload;
  },
  UPDATE_WORKERS_LIST (state, payload) {
    const existingIndex = state.workersList.findIndex(worker => worker._id === payload._id);
    if (existingIndex > -1) {
      state.workersList.splice(existingIndex, 1);
    }
    state.workersList.push(payload);
  },
};

const actions = {
  async SET_WORKERS_LIST ({ commit }, payload) {
    const workers = payload || await getAllWorkers();
    commit('SET_WORKERS_LIST', workers);
    return workers;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
