<template>
  <div class="tag-input">
    <span
      v-if="label"
      class="tag-input__label"
    >
      {{ label }}
    </span>

    <validation-provider
      v-slot="{ errors, changed }"
      ref="validationRef"
      rules="email"
    >
      <div
        class="tag-input__textarea-wrapper"
        :class="{
          'tag-input__textarea-wrapper_error': errors.length && changed
        }"
        @click="focusOnTextarea"
      >
        <div
          v-if="tagList?.length"
          class="tag-list flex flex-wrap flex-gap-4 mb-05"
          @click.stop
        >
          <span
            v-for="(tag, idx) in tagList"
            :key="`${tag}-${idx}`"
            class="tag-list__item flex flex-align-items-center flex-gap-4"
          >
            {{ tag }}
            <i
              class="bx bx-x-circle tag-list__item-icon flex-row-centered"
              @click="removeTag(tag)"
            />
          </span>
        </div>

        <textarea
          id="tagInput"
          ref="textareaRef"
          v-model="textareaValue"
          class="tag-input__textarea"
          name="Email"
          autocomplete="off"
          autocorrect="off"
          :spellcheck="false"
          :placeholder="placeholder"
          @keydown="handleKeyDown"
          @input="emit('input', textareaValue)"
        />
      </div>

      <template v-if="errors.length && changed">
        <p
          v-for="(error, i) in errors"
          :key="`${error}${i}`"
          class="color_danger text_xs mt-1"
        >
          {{ error }}
        </p>
      </template>
    </validation-provider>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { ValidationProvider, extend } from 'vee-validate';
  import { email } from 'vee-validate/dist/rules';

  const emit = defineEmits(['add-tag']);

  const props = defineProps({
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'example@email.com',
    },
    value: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
  });

  // #region Validation
  extend('email', email);

  const validationRef = ref(null);
  // #endregion

  // #region Tags
  const tagList = ref(props.tags || []);

  const removeTag = (idx) => {
    tagList.value.splice(idx, 1);
  };
  // #endregion

  // #region Input
  const textareaRef = ref(null);

  const textareaValue = ref(props.value);

  const focusOnTextarea = () => textareaRef.value.focus();

  const handleKeyDown = async (event) => {
    // 32 – spacebar
    if (event.keyCode === 32 || event.key === ',') {
      event.preventDefault();

      const validationResult = await validationRef.value.validate();

      if (!validationResult.valid) return;

      const tag = textareaValue.value.replace(/ |,/gm, '');

      if (!tag.length) return;

      if (!tagList.value.includes(tag)) {
        tagList.value.push(tag);
      }

      emit('add-tag', tagList.value);

      textareaValue.value = '';
      emit('input', textareaValue);
    }
  };
  // #endregion
</script>

<style lang="scss">
.tag-input {
  &__textarea-wrapper {
    background-color: var(--neutral-50);
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 8px 12px;
    min-height: 120px;
    cursor: text;

    &_error {
      border-color: $cl-danger;
    }
  }

  &__label {
    @include text-xs;
    display: block;
    margin-bottom: 4px;
    color: var(--color-text-tetriary);
    cursor: text;
  }

  &__textarea {
    @include text-s;
    appearance: none;
    background: transparent;
    border: 0;
    width: 100%;
    resize: none;
  }

  .tag-list {
    &__item {
      @include text-s;
      background-color: var(--primary-cl);
      color: white;
      padding: 0px 4px 0px 6px;
      border-radius: 4px;
      font-weight: 500;

      &-icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>
