/**
 * @enum {'dark' | 'light'}
 */
export const TooltipAppearanceEnum = Object.freeze({
  DARK: 'dark',
  LIGHT: 'light',
});

/**
 * @enum {'top' | 'bottom' | 'left' | 'right'}
 */
export const TooltipPositionEnum = Object.freeze({
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
});

/**
 * @enum {'left' | 'right'}
 */
export const TooltipAlignEnum = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
});