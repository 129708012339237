<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="flex-column flex-gap-4">
    <div class="flex-row flex-gap-16">
      <div>
        <p class="text_s text_weight_accent mb-1">
          {{ bubbleSizeList.title }}
        </p>
        <ul>
          <radio-button
            v-for="item in bubbleSizeList.items"
            :key="item.value"
            class="mb-1"
            name="bubbleSizes"
            :options="item"
            :is-selected="bubbleSize === item.value"
            @select="$emit('update', item.value, 'bubble_size')"
          />
        </ul>
      </div>
      <div>
        <p class="text_s text_weight_accent mb-1">
          {{ scrollSpeedList.title }}
        </p>
        <ul>
          <radio-button
            v-for="item in scrollSpeedList.items"
            :key="item.value"
            class="mb-1"
            name="scrollSpeed"
            :options="item"
            :is-selected="scrollSpeed === item.value"
            @select="$emit('update', item.value, 'scroll_speed')"
          />
        </ul>
      </div>
    </div>
    <div
      class="flex-row flex-gap-8"
      @click="$emit('update', !mirrorVideo, 'mirror_video')"
    >
      <default-switcher
        size="s"
        :is-checked="mirrorVideo"
      />
      <span>{{ $t('videoConstructor.editor.sections.video.mirrorVideo.title') }}</span>
    </div>
  </div>
</template>
<script>
  import RadioButton from '@/components/base/uiKit/RadioButton';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';
  export default {
    name: 'ConstructorButtons',
    components: {
      DefaultSwitcher,
      RadioButton,
    },
    props: {
      bubbleSize: {
        type: String,
        required: true,
      },
      scrollSpeed: {
        type: String,
        required: true,
      },
      mirrorVideo: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        bubbleSizeList: {
          title:
            this.$t('videoConstructor.editor.sections.video.avatarSize.title')
              .concat(':'),
          items: [
            {
              label: this.$t('common.small'),
              value: 'small',
            },
            {
              label: this.$t('common.medium'),
              value: 'medium',
            },
            {
              label: this.$t('common.large'),
              value: 'large',
            },
          ],
        },
        scrollSpeedList: {
          title:
            this.$t('videoConstructor.editor.sections.video.scrollSpeed.title')
              .concat(':'),
          items: [
            {
              label: this.$t('common.low'),
              value: 'low',
            },
            {
              label: this.$t('common.normal'),
              value: 'normal',
            },
            {
              label: this.$t('common.high'),
              value: 'high',
            },
          ],
        },
      };
    },
  };
</script>
