<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <section class="preferences-page">
    <preferences-sidebars
      :user-list="userSidebarList"
      :workspace-list="workspaceSidebarList"
      class="preferences-page__sidebars"
    />
    <section class="preferences-page__section">
      <div class="preferences-page__section-inner">
        <header class="mb-2">
          <h2 class="text_l text_weight_accent">
            {{ sectionTitle }}
          </h2>
        </header>
        <router-view />
      </div>
    </section>
  </section>
</template>

<script>
  import PreferencesSidebars from '@/components/preferencesPage/sidebars/PreferencesSidebars';
  import { USER_ROUTE_PATH, WORKSPACE_ROUTE_PATH } from '@/router/modules/preferences';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      PreferencesSidebars,
    },
    data () {
      return {
        isAdmin: false,
        userSidebarList: {
          title: this.$t('userSettings.title'),
          items: [
            {
              title: this.$t('common.basicInformation'),
              icon: 'user',
              name: 'user-basic',
              path: USER_ROUTE_PATH + '/basic',
            },
            {
              title: this.$tc('common.password'),
              icon: 'lock-alt',
              name: 'user-password',
              path: USER_ROUTE_PATH + '/password',
            },
            // {
            //   title: this.$tc('common.language'),
            //   icon: 'globe',
            //   name: 'user-language',
            //   path: USER_ROUTE_PATH + '/language',
            // },
          ],
        },
        workspaceSidebarList: {
          title: this.$t('workspaceSettings.title'),
          items: [
            {
              title: this.$t('common.basicInformation'),
              icon: 'user',
              name: 'workspace-basic',
              path: WORKSPACE_ROUTE_PATH + '/basic/' + this.$store.getters['account/account']._id,
            },
            {
              title: this.$t('workspaceSettings.settingsList.members.title'),
              icon: 'group',
              name: 'workspace-users',
              path: WORKSPACE_ROUTE_PATH + '/users/' + this.$store.getters['account/account']._id,
            },
            {
              title: this.$tc('common.label', 2),
              icon: 'purchase-tag',
              name: 'workspace-labels',
              path: WORKSPACE_ROUTE_PATH + '/labels/' + this.$store.getters['account/account']._id,
            },
            {
              title: this.$tc('common.appearance'),
              icon: 'bx bx-layout',
              name: 'workspace-appearance',
              path: WORKSPACE_ROUTE_PATH + '/appearance/' + this.$store.getters['account/account']._id,
            },
          ],
        },
      };
    },
    computed: {
      sectionTitle () {
        return this.userSidebarList.items
          .concat(this.workspaceSidebarList.items)
          .find(i => i.name === this.$route.name)?.title;
      },
      ...mapGetters({
        account: 'account/account',
        user: 'user/user',
      }),
    },
    mounted () {
      this.isAdmin = this.account?.admins.includes(this.user._id);
      // if (!this.isAdmin) {
      //   this.workspaceSidebarList.items.pop();
      // }
    },
  };
</script>
<style lang="scss">
.preferences-page {
  display: flex;
  gap: 16px;
  @include parent-size;
  padding: 16px 0 0 16px;
  label {
      cursor: pointer;
      max-width: 35%;
    }
  &__sidebars {
    width: 340px;
  }

  &__section {
    overflow-y: scroll;
    flex-grow: 1;
    padding: 0 16px 16px 0;
    min-width: min-content;
    &-inner {
      position: relative;
      max-width: calc(1024px - 340px);
      padding: 24px;
      border-radius: 8px;
      background: var(--background-color);
      box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06), 0px 0px 8px rgba(10, 32, 51, 0.05);
    }
  }

  &__form {
    &-control {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    &-required{
      color: $error-cl;
    }

    &-input {
      width: 60%;
    }

    &-group {
      width: 60%;

      &_inline {
        display: flex;
        gap: 4px;
      }
    }
  }
}
</style>
