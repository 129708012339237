/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const AddCredentialsPage = () => import('@/views/AddCredentialsPage');

const credentials = [
  {
    path: '/credentials/add',
    name: 'credentials-add',
    component: AddCredentialsPage,
    meta: {
      layout: 'DefaultLayout',
      hasSidebar: true,
    },
  },
];

export default credentials;
