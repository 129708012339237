<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="colors-block">
    <div
      v-for="(color, index) in colors"
      :key="color"
      :style="{ background: color }"
      class="colors-block__circle"
      @click="handleClickColor(color, index)"
    >
      <check-icon
        size="0.9x"
        :class="['colors-block__icon', {'colors-block__icon_active': index === chosenIndex}]"
      />
    </div>
  </div>
</template>
<script>
  import { CheckIcon } from 'vue-feather-icons';
  export default {
    name: 'ColorsBlock',
    components: {
      CheckIcon,
    },
    props: {
      colors: {
        type: Array,
        default: () => [
          'regular-text-cl',
          '#4F4F4F',
          '#828282',
          '#BDBDBD',
          '#F2F2F2',
          '#EB5757',
          '#F2994A',
          '#F2C94C',
          '#219653',
          '#27AE60',
          '#6FCF97',
          '#2F80ED',
          '#2D9CDB',
          '#56CCF2',
          '#9B51E0',
          '#BB6BD9',
        ],
      },
    },
    data () {
      return {
        chosenIndex: null,
      };
    },
    methods: {
      handleClickColor (color, index) {
        this.chosenIndex = index;
        this.$emit('choose-color', color);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .colors-block {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    column-gap: 12px;
    row-gap: 12px;
    &__circle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      &:hover > .colors-block__icon {
        opacity: 1;
      }
    }
    &__icon {
      opacity: 0;
      transition-duration: 0.3s;
      position: absolute;
      &_active {
        opacity: 1;
      }
    }
  }
</style>
