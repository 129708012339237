/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
class ApiError extends Error {
  /**
     *
     * @param {String} message
     * @param {Number} code
     */
  constructor (message, code) {
    super(message);
    this.code = code;
  }
}
export { ApiError };
