<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    ref="labelsTooltip"
    class="tooltip"
  >
    <delete-confirmation-modal
      text="Confirm deleting label from space"
      button-text="Delete label"
      :show.sync="showDeleteModal"
      name="deleteLabel"
      @confirm="deleteLabel"
    />
    <update-label-modal
      :show.sync="showCreateLabelModal"
      @update-label="createLabel"
    />
    <div
      v-if="showButton"
      ref="addLabels"
      class="profile-action-button primary-cl"
      @click="showLablesTooltipLocal = true"
    >
      <plus-icon size="1.3x" />
      <span class="text primary-cl">
        {{ $t('actions.addLabel') }}
      </span>
    </div>
    <checkbox-tooltip
      v-show="showLablesTooltip || (showButton && showLablesTooltipLocal)"
      ref="tooltip"
      :all-items="[
        {
          category: {
            name: $t('defaultFilters.labels.title'),
            _id: 'labels'
          },
          /*items: allLabels.map((label) => label.checkmarkStyle = {borderColor: label.color}),*/
          items: allLabels,
        },
      ]"
      :chosen-items="[
        {
          category: {
            name: $t('defaultFilters.labels.title'),
            _id: 'labels'
          },
          items: chosenLabels
        },
      ]"
      :styles="customStyle"
      :pass-through="true"
      :show-actions="true"
      class="people-profile__tooltip"
      @delete-profile-label="deleteProfileLabel"
      @add-profile-label="addProfileLabel"
    >
      <template #itemAction="slotProps">
        <div
          class="delete-label-button flex-row"
          @click="openDeleteModal(slotProps.item)"
        >
          <i class="bx bx-x text_m delete-button__icon" />
        </div>
      </template>

      <template #tooltipActions>
        <div
          class="tooltip-block__action-button"
          @click="showCreateLabelModal = true"
        >
          <i class="bx bx-plus tooltip-block__action-icon" />
          <p class="text_xs">
            {{ $t('actions.createNewLabel') }}
          </p>
        </div>
      </template>
    </checkbox-tooltip>
  </div>
</template>
<script>
  import { createLabel, deleteLabel } from '@/api/labelsMethods';
  import CheckboxTooltip from '@/components/base/CheckboxTooltip/CheckboxTooltip.vue';
  import { XIcon, PlusIcon } from 'vue-feather-icons';
  import UpdateLabelModal from '@/modals/UpdateLabelModal.vue';
  import DeleteConfirmationModal from '@/modals/DeleteConfirmationModal.vue';

  export default {
    name: 'LabelsTooltip',
    components: {
      UpdateLabelModal,
      CheckboxTooltip,
      PlusIcon,
      XIcon,
      DeleteConfirmationModal,
    },
    props: {
      chosenLabels: {
        type: Array,
        default: () => [],
      },
      allLabels: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: 'profile',
      },
      customStyle: {
        type: Object,
        default () {
          return { maxWidth: '353px' };
        },
      },
      showLablesTooltip: {
        type: Boolean,
        default: false,
      },
      showButton: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        showLablesTooltipLocal: false,
        showCreateLabelModal: false,
        labelToDelete: null,
        showDeleteModal: false,
      };
    },
    mounted () {
      document.addEventListener('click', this.closeListener);
    },
    beforeDestroy () {
      document.removeEventListener('click', this.closeListener);
    },
    methods: {
      closeListener (e) {
        const el = this.$refs.labelsTooltip;
        const exclude = ['addLabels'];
        const clickedOnExcludedEl = exclude.some(refName => {
          const excludedEl = this.$refs[refName] || document.getElementById(refName);
          return excludedEl?.contains(e.target);
        });
        if (!el.contains(e.target) && !clickedOnExcludedEl) {
          this.closeLabelsTooltip();
        }
      },
      openDeleteModal (label) {
        this.labelToDelete = label;
        this.showDeleteModal = true;
      },
      async createLabel (label) {
        try {
          const newLabel = await createLabel(label.name, label.color, this.type);
          if (this.type === 'profile') {
            await this.$store.dispatch('labels/SET_LABELS_LIST');
          } else if (this.type === 'template') {
            await this.$store.dispatch('labels/SET_TEMPLATE_LABELS_LIST');
          } else {
            await this.$store.dispatch('labels/SET_SPACE_LABELS_LIST');
          }

          this.$emit('add-label', newLabel);
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async deleteLabel () {
        if (this.labelToDelete) {
          try {
            await deleteLabel(this.labelToDelete._id);
            if (this.type === 'profile') {
              await this.$store.dispatch('labels/SET_LABELS_LIST');
            } else if (this.type === 'template') {
              await this.$store.dispatch('labels/SET_TEMPLATE_LABELS_LIST');
            } else {
              await this.$store.dispatch('labels/SET_SPACE_LABELS_LIST');
            }
            // const newLabels = this.labels.slice(this.labels.findIndex((label) => label._id === deleteLabel._id), 1);
            this.$emit('delete-label', this.labelToDelete._id);
            this.$noty.success(
              this.$t('notifications.labelSuccessfullyDeleted')
            );
            this.labelToDelete = null;
          } catch (e) {
            this.$noty.error(e.message);
          }
        }
      },
      closeLabelsTooltip () {
        if (this.showButton) {
          this.showLablesTooltipLocal = false;
          return;
        }
        this.$emit('update:showLablesTooltip', false);
      },
      deleteProfileLabel (labelId, labels) {
        this.$emit('delete-profile-label', labelId, labels);
      },
      addProfileLabel (labelId, labels) {
        this.$emit('add-profile-label', labelId, labels);
      },
    },
  };
</script>

<style lang="scss" scoped>

  .delete-label-button {
    margin-top: 0;
    opacity: 1;
    width: 16px;
    height: 16px;
    color: #e96969;
    border-radius: 4px;
    background: rgba(255, 99, 89, 0.1);
    &:hover {
      background: rgba(255, 84, 73, 0.3);
    }
  }
</style>
