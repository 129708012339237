/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getUsers, getStages, getPipelines, getCurrencies } from '@/api/crmIntegration';
import { capitalize } from '@/mixins/capitalizeFilterMixin';

const state = () => ({
  // stages
  // stagesDefault: [],
  stagesPipedrive: [],
  // stagesHubspot: [],
  // allStages
  // allStagesDefault: [],
  allStagesPipedrive: [],
  // allStagesHubspot: [],
  // owners
  // ownersDefault: [],
  ownersPipedrive: [],
  // ownersHubspot: [],
  // pipelines
  // pipelinesDefault: [],
  pipelinesPipedrive: [],
  // pipelinesHubspot: [],

  // Other
  currencies: [],
});

const getters = {
  // stagesDefault: state => {
  //   return state.stagesDefault;
  // },
  stagesPipedrive: state => {
    return state.stagesPipedrive;
  },
  // stagesHubspot: state => {
  //   return state.stagesHubspot;
  // },
  // allStagesDefault: state => {
  //   return state.allStagesDefault;
  // },
  allStagesPipedrive: state => {
    return state.allStagesPipedrive;
  },
  // allStagesHubspot: state => {
  //   return state.allStagesHubspot;
  // },
  pipelinesPipedrive: state => {
    return state.pipelinesPipedrive;
  },
  // pipelinesHubspot: state => {
  //   return state.pipelinesHubspot;
  // },
  // pipelinesDefault: state => {
  //   return state.pipelinesDefault;
  // },
  // ownersDefault: state => {
  //   return state.ownersDefault;
  // },
  ownersPipedrive: state => {
    return state.ownersPipedrive;
  },
  // ownersHubspot: state => {
  //   return state.ownersHubspot;
  // },

  currencies: state => {
    return state.currencies;
  },
};

const mutations = {
  SET_STAGES (state, { stages, platform }) {
    state['stages' + capitalize(platform)] = stages;
  },
  SET_ALL_STAGES (state, { stages, platform }) {
    state['allStages' + capitalize(platform)] = stages;
  },
  SET_OWNERS (state, { owners, platform }) {
    state['owners' + capitalize(platform)] = owners;
  },
  SET_PIPELINES (state, { pipelines, platform }) {
    state['pipelines' + capitalize(platform)] = pipelines;
  },
  SET_CURRENCIES (state, payload) {
    state.currencies = payload;
  },
  UPDATE_STAGE (state, { stage, platform }) {
    // Срабатывает только при сокетах

    const stages = state['stages' + capitalize(platform)].slice(0);
    const existingIndex = stages.findIndex(el => el._id === stage._id);

    if (existingIndex > -1) {
      stages.splice(existingIndex, 1, stage);
    } else {
      stages.push(stage);
    }

    state['stages' + capitalize(platform)] = stages.sort((a, b) => {
      return b.data.order - a.data.order;
    });
  },
  DELETE_STAGE (state, { platform, deleteId }) {
    state['pipelines' + platform] = state['pipelines' + capitalize(platform)].filter((stage) => {
      return !deleteId.includes(stage._id);
    });
    state['allStages' + platform] = state['allStages' + capitalize(platform)].filter((stage) => {
      return !deleteId.includes(stage._id);
    });
  },
  UPDATE_PIPELINE (state, { pipeline, platform }) {
    // Привязываю к нужному стейту
    const pipelines = state['pipelines' + capitalize(platform)];
    const existingIndex = pipelines.findIndex(el => el._id === pipeline._id);
    if (existingIndex > -1) {
      pipelines.splice(existingIndex, 1, pipeline);
    } else {
      pipelines.push(pipeline);
    }
    state['pipelines' + capitalize(platform)] = pipelines;
  },
  DELETE_PIPELINE (state, { platform, deleteId }) {
    state['pipelines' + capitalize(platform)] = state['pipelines' + capitalize(platform)].filter((pipeline) => {
      return !deleteId.includes(pipeline._id);
    });
  },
  UPDATE_DEALS (state, { deal, platform }) {
    const stages = state['stages' + capitalize(platform)];
    const stageIndex = stages.findIndex(stage => stage._id === deal.crm_stage._id);
    const dealIndex = stages[stageIndex]?.crm_deals?.findIndex(el => el._id === deal._id);
    if (dealIndex > -1) {
      // Что бы не фильтровать все stage по юзерам добавил костыль
      if (deal.crm_owner && stages[stageIndex]?.crm_deals[dealIndex]?.crm_owner._id !== deal.crm_owner._id) {
        stages[stageIndex].crm_deals.splice(dealIndex, 1);
      } else {
        stages[stageIndex].crm_deals.splice(dealIndex, 1, deal);
      }
    } else {
      // Сработает при изменение stages у сделки.
      // eslint-disable-next-line array-callback-return
      stages.find((stage, indexStage) => {
        const indexDeal = stage?.crm_deals.findIndex(el => el._id === deal._id);
        if (indexDeal > -1) {
          if (deal.crm_owner && stages[stageIndex]?.crm_deals[dealIndex]?.crm_owner._id !== deal.crm_owner._id) {
            stages[indexStage]?.crm_deals.splice(indexDeal, 1, deal);
          } else {
            stages[indexStage]?.crm_deals.splice(indexDeal, 1, deal);
          }
        }
        return null;
      });
    }
    state['stages' + (platform[0].toUpperCase() + platform.slice(1))] = stages;
  },
  CREATE_DEALS (state, { deal, platform }) {
    const stages = state['stages' + capitalize(platform)];
    const stageIndex = stages.findIndex(stage => stage._id === deal.crm_stage._id);
    if (stageIndex > -1) {
      // Избежание копий при socket
      const dealIndex = stages[stageIndex]?.crm_deals.findIndex(el => deal._id === el._id);
      if (dealIndex === -1) {
        stages[stageIndex].crm_deals.push(deal);
      }
      state['stages' + capitalize(platform)] = stages;
    }
  },
};

const actions = {
  async SET_STAGES ({ commit }, { props, platform }) {
    // В этих stages есть поле deals
    const stages = await getStages(platform, props);
    commit('SET_STAGES', { stages, platform });
  },
  async SET_ALL_STAGES ({ commit }, platform) {
    // В этих stages нет поля deals
    const stages = await getStages(platform);
    commit('SET_ALL_STAGES', { stages, platform });
  },
  async SET_CURRENCIES ({ commit }, platform) {
    const currencies = await getCurrencies(platform);
    commit('SET_CURRENCIES', currencies);
  },
  async SET_OWNERS ({ commit }, platform) {
    const owners = await getUsers(platform);
    commit('SET_OWNERS', { owners, platform });
  },
  async SET_PIPELINES ({ commit }, platform) {
    const pipelines = await getPipelines(platform);
    commit('SET_PIPELINES', { pipelines, platform });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
