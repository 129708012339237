/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function getResponses (credentialsId = '') {
  const data = await useHttp(
    '/saved_responses/fetch',
    'GET',
    null,
    {
      credentials: credentialsId,
    }
  );
  return data.payload;
}
async function deleteResponse (credentialsId = '', id) {
  const data = await useHttp(
    `/saved_responses/${id}/delete`,
    'POST',
    null,
    {
      credentials: credentialsId,
    }
  );
  return data.payload;
}
async function createResponse (credentialsId = '', name, textTemplate) {
  const data = await useHttp(
    '/saved_responses/create',
    'POST',
    {
      name,
      text_template: textTemplate,
    },
    {
      credentials: credentialsId,
    }
  );
  return data.payload;
}
async function updateResponse (credentialsId = '', id, name, textTemplate) {
  const data = await useHttp(
    `/saved_responses/${id}/update`,
    'POST',
    {
      id,
      name,
      text_template: textTemplate,
    },
    {
      credentials: credentialsId,
    }
  );
  return data.payload;
}
export { getResponses, deleteResponse, createResponse, updateResponse };
