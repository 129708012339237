<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="flex-row flex-gap-8">
    <span
      :class="[
        'tooltip-block__checkmark',
        { 'tooltip-block__checkmark_active primary-bg': isActive },
      ]"
      :style="checkmarkStyle"
    />
    <span class="tooltip-block__text text_s">{{ item.name || 'no name!' }}</span>
  </div>
</template>
<script>
  export default {
    name: 'CheckboxTooltipItem',
    props: {
      chosenItems: {
        type: Array,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },
      category: {
        type: Object,
        required: true,
      },
      checkmarkStyle: {
        type: Object,
        default: null,
      },
    },
    computed: {
      isActive: function () {
        const categroyIndex = this.chosenItems.findIndex(
          (itemsSet) => itemsSet.category._id === this.category._id
        );
        const active =
          categroyIndex > -1
            ? this.chosenItems[categroyIndex].items.length
              ? this.chosenItems[categroyIndex].items.some(
                (item) => item._id === this.item._id
              )
              : false
            : false;
        return active;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .tooltip-block {
    &__checkmark {
      width: 16px;
      height: 16px;
      left: 16px;
      border: 1px solid var(--border-cl);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      &.tooltip-block__checkmark_active::after {
        display: block;
      }

      &::after {
        content: '';
        display: none;
        background-image: url('~@/assets/icons/checked.svg');
        position: absolute;
        width: 8px;
        height: 6px;
      }
    }

    &__text {
      text-align: start;
    }
  }
</style>
