/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';
import { useFile } from '@/middleware/fileMiddleware';

/** @type {api.profile.getProfiles} */
async function getProfiles (filters, credentialId, q, page, limit = 0) {
  q = filters.query ?? q;
  const data = await useHttp(
    '/profiles/fetch',
    'GET',
    null,
    {
      filters: JSON.stringify(filters.filters) || '[]',
      is_open: JSON.stringify(filters.is_open),
      is_viewed: JSON.stringify(filters.is_viewed),
      labels: JSON.stringify(filters.labels) || '[]',
      workflows: JSON.stringify(filters.workflows) || '[]',
      approval_statuses: JSON.stringify(filters.approval_statuses) || '[]',
      statuses: JSON.stringify(filters.statuses) || '[]',
      replied_after: JSON.stringify(filters.replied_after) || '[]',
      connected_at: JSON.stringify(filters.connected_at) || '{}',
      credentials_id: credentialId,
      q,
      page,
      limit,
    }
  );

  return data.payload;
}

async function getInboxProfiles (
  credentialId,
  q,
  filters,
  tag,
  withOpenedEmail,
  withBouncedEmail,
  offset = 0,
  limit = 25
) {
  const data = await useHttp(
    '/profiles/fetch_inbox',
    'GET',
    null,
    {
      credentials_id: credentialId,
      q,
      workflows: filters.workflows || '[]',
      filters: filters.filters || '[]',
      statuses: filters.statuses || '[]',
      labels: filters.labels || '[]',
      tag,
      sort: 'unread_first',
      with_opened_email: withOpenedEmail,
      with_bounced_email: withBouncedEmail,
      offset,
      limit,
    }
  );

  return data.payload;
}

async function getInfo (profileId, credentialId, allMessages = true) {
  const data = await useHttp(
    `/profiles/${profileId}/info`,
    'GET',
    null,
    {
      credentials_id: credentialId,
      all_messages: allMessages,
    }
  );
  return data.payload;
}

async function sendMessage (profileId, credentialId, text, date = null) {
  const data = await useHttp(
    `/profiles/${profileId}/send_message`,
    'POST',
    {
      text,
      date,
    },
    {
      credentials_id: credentialId,
    },
    true
  );

  return data.payload;
}

async function getCSV (email, sort, ids, credentialId, limit = 0, filters = {}, q = '', offset = 0) {
  let data = await useFile(
    '/profiles/fetch',
    'GET',
    null,
    {
      filters: JSON.stringify(filters.filters) || '[]',
      is_open: JSON.stringify(filters.is_open),
      is_viewed: JSON.stringify(filters.is_viewed),
      labels: JSON.stringify(filters.labels) || '[]',
      workflows: JSON.stringify(filters.workflows) || '[]',
      statuses: JSON.stringify(filters.statuses) || '[]',
      ids: JSON.stringify(ids) || '[]',
      credentials_id: credentialId,
      offset,
      q,
      sort,
      limit,
      is_inbox: false,
      email,
      format: 'csv',
    }
  );
  data = URL.createObjectURL(data);
  return data;
}

async function updateManualBlacklist (status, ids, credentialId, limit = 0, filters = {}, q = '') {
  const data = await useHttp(
    '/profiles/manual_blacklist',
    'POST',
    { blacklist_status: status },
    {
      ids: JSON.stringify(ids),
      credentials_id: credentialId,
      filters: JSON.stringify(filters.filters) || '[]',
      is_open: JSON.stringify(filters.is_open),
      is_viewed: JSON.stringify(filters.is_viewed),
      labels: JSON.stringify(filters.labels) || '[]',
      workflows: JSON.stringify(filters.workflows) || '[]',
      statuses: JSON.stringify(filters.statuses) || '[]',
      replied_after: JSON.stringify(filters.replied_after) || '[]',
      q,
      limit,
    }, true
  );

  return data.payload;
}

async function pushToCrm (
  platform,
  owner,
  ids,
  credentialId,
  isDeduplicate,
  emailsTypes,
  limit = 0,
  filters = {},
  q = ''
) {
  const data = await useHttp(
    `/integrations/crm/${platform}/push_to_crm`,
    'POST',
    null,
    {
      ids: JSON.stringify(ids),
      credentials_id: credentialId,
      filters: JSON.stringify(filters.filters) || '[]',
      is_open: JSON.stringify(filters.is_open),
      is_viewed: JSON.stringify(filters.is_viewed),
      labels: JSON.stringify(filters.labels) || '[]',
      workflows: JSON.stringify(filters.workflows) || '[]',
      statuses: JSON.stringify(filters.statuses) || '[]',
      replied_after: JSON.stringify(filters.replied_after) || '[]',
      is_deduplicate: JSON.stringify(isDeduplicate),
      emailsTypes: JSON.stringify(emailsTypes),
      q,
      limit,
      crm_owner_pk: owner,
    }
  );

  return data.payload;
}
async function sendEmail (profileId, credentialId, email, integrationId, subject, text) {
  const data = await useHttp(
    `/profiles/${profileId}/send_email`,
    'POST',
    {
      email,
      integration_id: integrationId,
      subject,
      text,
      credentials_id: credentialId,
    }
  );

  return data.payload;
}

async function updateProfile (profileId, credentialId, profile) {
  const data = await useHttp(
    `/profiles/${profileId}/update`,
    'POST',
    {
      credentials_id: credentialId,
      profile: JSON.stringify(profile),
    }
  );
  return data.payload;
}

async function markRead (profileId, credentialId) {
  const data = await useHttp(
    `/profiles/${profileId}/mark_read`,
    'POST',
    null,
    {
      credentials_id: credentialId,
    }
  );

  return data.payload;
}

async function markUnread (profileId, credentialId) {
  const data = await useHttp(
    `/profiles/${profileId}/mark_unread`,
    'POST',
    null,
    {
      credentials_id: credentialId,
    }
  );

  return data.payload;
}

async function syncDialog (profileId, credentialId) {
  const data = await useHttp(
    `/profiles/${profileId}/sync`,
    'POST',
    null,
    {
      credentials_id: credentialId,
    }
  );

  return data.payload;
}

async function forwardToEmail (profileId, credentialId, email) {
  const data = await useHttp(
    `/profiles/${profileId}/forward_to_email`,
    'POST',
    null,
    {
      credentials_id: credentialId,
      email,
    }
  );

  return data.payload;
}
async function accessEmail (ids = [], limit = 0, credentialId, filters = {}, q = '') {
  const data = await useHttp(
    '/profiles/enrich',
    'POST',
    null,
    {
      ids: JSON.stringify(ids),
      limit,
      credentials_id: credentialId,
      is_open: JSON.stringify(filters.is_open) || false,
      is_viewed: JSON.stringify(filters.is_viewed),
      labels: JSON.stringify(filters.labels) || '[]',
      workflows: JSON.stringify(filters.workflows) || '[]',
      statuses: JSON.stringify(filters.statuses) || '[]',
      q,
    }
  );

  return data.payload;
}

async function getMessages (profileId, credentialId, old, offset = 0, limit = 20) {
  const data = await useHttp(
    `/profiles/${profileId}/get_messages`,
    'GET',
    null,
    {
      old_dialogs: old,
      offset,
      limit,
      credentials_id: credentialId,
    }
  );

  return data.payload;
}

async function getEmails (profileId, credentialId, offset = 0, limit = 20) {
  const data = await useHttp(
    `/profiles/${profileId}/get_emails`,
    'GET',
    null,
    {
      offset,
      limit,
      credentials_id: credentialId,
    }
  );

  return data.payload;
}
async function addProfileLabel (profileId, labelId, credentialId) {
  const data = await useHttp(
    `/profiles/${profileId}/labels/${labelId}`,
    'POST',
    {
      credentials_id: credentialId,
    }
  );
  return data.payload;
}
async function deleteProfileLabel (profileId, labelId, credentialId) {
  const data = await useHttp(
    `/profiles/${profileId}/labels/${labelId}`,
    'DELETE',
    {
      credentials_id: credentialId,
    }
  );
  return data.payload;
}
async function getMapAndWeather (profileId, credentialId) {
  const data = await useHttp(
    `/profiles/${profileId}/map_and_weather`,
    'GET',
    null,
    {
      credentials_id: credentialId,
    }
  );

  return data.payload;
}

async function getWorkflowStep (workflowId) {
  const data = await useHttp(
    `/workflows/${workflowId}/steps`,
    'GET'
  );

  return data.payload;
}

async function updateProfilesBulk (credentialId, ids, workflowStatus) {
  const obj = {
    workflow_status: workflowStatus,
  };
  const data = await useHttp(
    '/profiles/update_bulk',
    'POST',
    {
      profiles_ids: ids,
      credentials_id: credentialId,
      fields_to_update: obj,
    },
    {},
    true
  );

  return data.payload;
}

async function changeWorkflowBulk (workflowId, startOnChange, ids, limit, credentialId, filters = {}, q = '') {
  // await sleep(2000);
  const data = await useHttp(
    '/profiles/change_workflow_async',
    'POST',
    {
      workflow_id: workflowId,
      start_on_change: startOnChange,
    },
    {
      ids: JSON.stringify(ids),
      limit,
      credentials_id: credentialId,
      is_open: JSON.stringify(filters.is_open),
      is_viewed: JSON.stringify(filters.is_viewed),
      labels: JSON.stringify(filters.labels) || '[]',
      workflows: JSON.stringify(filters.workflows) || '[]',
      statuses: JSON.stringify(filters.statuses) || '[]',
      q,
    },
    true
  );

  return data.payload;
}

export {
  getProfiles,
  getInfo,
  sendMessage,
  markRead,
  syncDialog,
  markUnread,
  getCSV,
  updateProfile,
  sendEmail,
  forwardToEmail,
  accessEmail,
  getInboxProfiles,
  getMessages,
  getEmails,
  addProfileLabel,
  deleteProfileLabel,
  getMapAndWeather,
  getWorkflowStep,
  pushToCrm,
  updateProfilesBulk,
  changeWorkflowBulk,
  updateManualBlacklist
};
