<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end">
    <modal
      :key="key"
      name="updateLabelModal"
      width="420px"
      height="339px"
      classes="component"
      :adaptive="true"
      @closed="handleClose"
    >
      <div class="modal">
        <div class="modal__header mb-3">
          <p class="modal__title">
            {{ $t('modals.updateLabel.title') }}
          </p>
          <x-icon
            size="1.1x"
            class="modal__close"
            @click="handleClose"
          />
        </div>
        <section class="modal__input mb-3">
          <input
            v-model="labelCopy.name"
            :class="['input', { input_error: error.name }]"
            type="text"
            :placeholder="$t('modals.updateLabel.inputPlaceholder')"
            @input="updateInput"
          >
          <p
            v-if="error.name"
            class="modal__error"
          >
            {{ errorText.name }}
          </p>
        </section>
        <section class="update-label__colors-block">
          <colors-block
            @choose-color="chooseColor"
          />
          <p
            v-if="error.color"
            class="modal__error"
          >
            {{ errorText.color }}
          </p>
        </section>
        <div class="modal__buttons">
          <p
            :class="['primary-btn', { 'primary-btn_error': error.name || error.color }]"
            @click="updateLabel"
          >
            {{ $t('actions.create') }}
          </p>
          <p
            :class="['primary-btn  primary-btn_gray']"
            @click="handleClose"
          >
            {{ $t('actions.cancel') }}
          </p>
        </div>
      </div>
    </modal>
  </portal>
</template>

<script>

// TODO переписать используя inputModal
  import { mapGetters } from 'vuex';
  import { XIcon } from 'vue-feather-icons';
  import ColorsBlock from './LabelModal/ColorsBlock.vue';

  export default {
    name: 'UpdateLabelModal',
    components: {
      XIcon,
      ColorsBlock,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      label: {
        type: Object,
        default: () => {
          return {
            name: '',
            _id: null,
            color: null,
          };
        },
      },
    },
    data () {
      return {
        labelCopy: JSON.parse(JSON.stringify(this.label)),
        error: {
          name: false,
          color: false,
        },
        errorText: {
          color: this.$t('modals.updateLabel.errors.chooseColor'),
          name: this.$t('modals.updateLabel.errors.fillName'),
        },
        clicked: false,
        key: 'updateLabelModal',
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
    },
    watch: {
      show (show) {
        show
          ? this.$modal.show('updateLabelModal')
          : this.$modal.hide('updateLabelModal');
        if (!show) {
          this.key = `${this.key} ${new Date().getTime()}`;
        }
      },
    },
    methods: {
      handleClose () {
        this.$emit('update:show', false);
        this.labelCopy.name = '';
        this.labelCopy.color = null;
        this.clicked = false;
        this.error = {
          color: false,
          name: false,
        };
      },
      chooseColor (color) {
        this.error.color = false;
        this.labelCopy.color = color;
      },
      checkFields () {
        switch (true) {
        case this.labelCopy.name.length === 0:
          this.error.name = true;
          this.errorText.name = this.$t('modals.updateLabel.errors.fillName');
          return false;
        case this.labelCopy.name.length > 30:
          this.error.name = true;
          this.errorText.name = this.$t('modals.updateLabel.errors.maxNameLength');
          return false;
        case !this.labelCopy.color:
          this.error.color = true;
          this.errorText.color = this.$t('modals.updateLabel.errors.chooseLabelColor');
          return false;
        default:
          return true;
        }
      },
      async updateLabel () {
        this.clicked = true;
        if (this.checkFields()) {
          this.$emit('update-label', this.labelCopy);
          this.$emit('update:label', this.labelCopy);
          this.handleClose();
        } else {
          if (this.labelCopy.name.length < 1) {
            this.error.name = true;
            if (!this.labelCopy.color) {
              this.error.color = true;
            }
          }
        }
      },
      updateInput () {
        this.error.name = this.labelCopy.name.length < 1;
      },
    },
  };
</script>

<style lang="scss" scoped>

  .update-label{
    &__colors-block{
position: relative;
    }
  }
</style>
