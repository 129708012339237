/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const TemplatesPage = () => import('@/views/templatesPage/TemplatesPage');
const TemplatesPageMarketplace = () => import('@/views/templatesPage/TemplatesPageMarketplace');
const TemplatesPageMyTemplates = () => import('@/views/templatesPage/TemplatesPageMyTemplates');
const VideosAll = () => import('@/views/templatesPage/videos/VideosAll');
const VideosConstructor = () => import('@/views/templatesPage/videos/VideosConstructor');

const meta = {
  layout: 'DefaultLayout',
  hasSidebar: true,
};

const videosMeta = {
  layout: 'DefaultLayout',
  hasSidebarButton: true,
  withoutNavigation: true,
  traceback: '/templates/videos',
};

const templates = [
  {
    path: '/templates',
    name: 'templates',
    component: TemplatesPage,
    redirect: '/templates/my-templates',
    children: [
      {
        path: '/templates/my-templates',
        name: 'my-templates',
        component: TemplatesPageMyTemplates,
        meta,
      },
      {
        path: 'marketplace',
        name: 'marketplace',
        component: TemplatesPageMarketplace,
        meta,
      },
      {
        path: '/templates/videos',
        name: 'videos',
        component: VideosAll,
        meta,
      },
      {
        path: '/templates/videos/constructor/:videoId?',
        name: 'videos-constructor',
        component: VideosConstructor,
        meta: videosMeta,
      },
    ],
  },

];

export default templates;
