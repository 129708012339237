<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="checkbox"
    @click.stop="selectCheckbox"
  >
    <div
      class="checkbox__box"
    >
      <span
        :class="[
          'checkbox__checkmark',
          { 'checkbox__checkmark_active': isActive },
        ]"
      >
        <i class="bx bx-check" />
      </span>
    </div>
    <slot />
  </div>
</template>
<script>
  export default {
    name: 'CheckboxBlock',
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

    },
    methods: {
      selectCheckbox () {
        this.$emit('select-checkbox', this.isActive);
        this.$emit('update:isActive', !this.isActive);
      },
    },
  };
</script>
<style lang="scss" scoped>
.checkbox {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;
  cursor: pointer;
  &__box {
    flex-flow: row;
    column-gap: 12px;
    color: #919399;
  }
  &__checkmark {
    display: block;
    width: 16px;
    height: 16px;
    color: var(--btn-bg);
    border: 1px solid var(--border-cl);
    border-radius: 4px;

    i {
      font-size: 12px;
      transition: 0.5s;
      color: #fff;
      opacity: 0;
    }

    &_active {
      background: var(--primary-cl);
      border: 1px solid var(--primary-cl);
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        opacity: 1;
      }
    }
  }
}
</style>
