<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="video-menu">
    <div
      v-if="!video && !disabled"
      class="video-menu__add"
      @click="$emit('choose-video')"
    >
      <span class="video-menu__text text_xs">
        <i class="bx bx-plus" />
        {{ $t('campaignsPage.editor.steps.modals.stepEdit.menu.video.buttons.addVideo') }}
      </span>
    </div>
    <div
      v-else-if="!video && disabled"
      class="video-menu__add"
      @click="$emit('unlink-template')"
    >
      <span class="video-menu__text text_xs">
        <i class="bx bx-unlink" />
        {{ $t('campaignsPage.editor.steps.modals.stepEdit.menu.video.errorText') }}
      </span>
    </div>
    <video-item
      v-else
      :key="video._id"
      class="video-menu__video"
      :video="video"
      :mode="'discard'"
      @discard="removeVideo"
      @insert="insertVideo"
    />
  </div>
</template>
<script>
  import VideoItem from '@/components/videoEditor/VideoItem';
  export default {
    name: 'VideoMenu',
    components: {
      VideoItem,
    },
    props: {
      video: {
        type: Object,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
      };
    },
    methods: {
      insertVideo (video) {
        this.$emit('insert-video', video);
      },
      removeVideo (video) {
        this.$emit('remove-video', video);
      },
    },
  };
</script>
<style lang="scss">
.video-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  &__add {
    width: 250px;
    min-height: 68px;
    border: $border-dashed;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-cl);
    transition: 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
  &__video {
    min-width: 250px;
   min-height: 68px;
  }
}
</style>
