/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { updateUser } from '@/api/userMethods';
import { mapGetters } from 'vuex';

export const usedEmailsMixin = {
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
  },
  data () {
    return {
      recentlyUsedEmails: this.$store.getters['user/user'].recently_used_emails.slice(),
    };
  },
  watch: {
    async recentlyUsedEmails (recentlyUsedEmails) {
      if (
        recentlyUsedEmails.toString() !==
        this.user.recently_used_emails.toString()
      ) {
        const user = this.user;
        user.recently_used_emails = recentlyUsedEmails;

        const response = await updateUser(user);
        await this.$store.dispatch('user/SET_USER', response);
      }
    },
  },
};
