/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const useFile = async (url, method = 'GET', body = null, params = {}) => {
  if (body) {
    const formData = new FormData();
    Object.keys(body).forEach(key => formData.append(key, body[key]));
    body = formData;
  }
  const headers = {};
  // const headers = {};
  // headers.Accept = 'application/json';
  headers['Content-Type'] = 'application/x-www-form-urlencoded';
  params = {
    ...params,
    token: localStorage.getItem('token'),
  };

  const space = new URLSearchParams(window.location.search).get('space');
  params = new URLSearchParams(params);
  if (space) {
    params.set('space', space);
  }

  params = new URLSearchParams(params).toString();

  url = `${process.env.VUE_APP_BASE_URL}/public_api${url}?${params}`;

  const response = await fetch(url, {
    method,
    body,
    headers,
  });
  if (response.status === 404) {
    return null;
  }

  const blob = await response.blob();
  return blob;
};

export { useFile };
