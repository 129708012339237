function setChatra (account, user, theme) {
  // initialization Chatra
  (function (d, w, c) {
    w.ChatraID = getChatraId(user.brand);
    const s = d.createElement('script');
    w[c] = w[c] || function () {
      (w[c].q = w[c].q || []).push(arguments);
    };
    s.async = true;
    s.src = 'https://call.chatra.io/chatra.js';
    if (d.head) d.head.appendChild(s);
  })(document, window, 'Chatra');
  // eslint-disable-next-line no-undef
  Chatra('setIntegrationData', {
    name: user.first_name,
    email: user.email,
    brand: user.brand,
    _id: user._id,
    space: account?._id,
  });

  if (theme === 'dark') {
    // eslint-disable-next-line no-undef
    Chatra('setColors', {
      /* main properties */
      buttonText: '#fff', /* chat button text/icon color */
      buttonBg: '#152e4d', /* chat button background color */
    });
  }
  window.ChatraSetup = {
    disabledOnMobile: true,
  };
}

function getChatraId (brand) {
  switch (brand) {
    case 'linkedintelaccess':
      return '4EhdqaLg4JCTBdTvB';
    default:
      return 'EmhXonXe37yZgYY7s';
  }
}
export { setChatra };
