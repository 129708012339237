<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="quill-editor"
    :class="{'toolbarDisabled': toolbarDisabled == true, }"
    @click="$emit('activate')"
  >
    <slot name="toolbar" />
    <div ref="editor" />
  </div>
</template>

<script>
  import vuejsQuillMixin from './mixin.js';

  export default {
    mixins: [vuejsQuillMixin],
  };
</script>
<style>
	.quill-editor.toolbarDisabled .ql-toolbar {
		display: none;
    border: none !important;
	}
	.quill-editor.toolbarDisabled .ql-container {
		border-top: 1px solid #ccc !important;
	}
</style>
