<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="enrich-profile-step">
    <span class="text_s">
      {{ $t('campaignsPage.editor.steps.enrichProfile.infoMessage', { name: brand }) }}
    </span>
  </div>
</template>
<script>

  export default {
    name: 'EnrichProfileStep',

    components: {
    },
    props: {
      brand: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
      };
    },

    watch: {
    },
  };
</script>
<style lang="scss">
.enrich-profile-step {
  display: flex;
  padding: 16px;
}
</style>
