<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="delay-counter">
    <p class="delay-counter__tip text_xs color_secondary">
      {{ $t('campaignsPage.editor.steps.step.delayCounter.title') }}
    </p>
    <div class="delay-counter__counter">
      <div
        class="delay-counter__counter-icon text_s delay-counter__counter-icon_minus"
        @click.stop="decrease('delay_days')"
      >
        <i class="bx bx-minus" />
      </div>
      <span class="delay-counter__text color_tertiary text_xs">
        {{ $t('campaignsPage.editor.steps.step.delayCounter.days', { days: delay_days}) }}
      </span>
      <div
        class="delay-counter__counter-icon text_s delay-counter__counter-icon_plus"
        @click.stop="increase('delay_days')"
      >
        <i class="bx bx-plus" />
      </div>
    </div>
    <div class="delay-counter__counter">
      <div
        class="delay-counter__counter-icon text_s delay-counter__counter-icon_minus"
        @click.stop="decrease('delay_hours')"
      >
        <i class="bx bx-minus" />
      </div>
      <span class="delay-counter__text color_tertiary text_xs">
        {{ $t('campaignsPage.editor.steps.step.delayCounter.hours', { hours: delay_hours}) }}
      </span>
      <div
        class="delay-counter__counter-icon text_s delay-counter__counter-icon_plus"
        @click.stop="increase('delay_hours')"
      >
        <i class="bx bx-plus" />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'DelayCounter',
    components: {
    },

    props: {
      delay_hours: {
        type: Number,
        default: 0,
      },
      delay_days: {
        type: Number,
        default: 0,
      },
    },
    methods: {
      decrease (name) {
        if (name === 'delay_hours') {
          if (this.delay_hours === 0) {
            if (this.delay_days > 0) {
              this.decrease('delay_days');
            }
            this.$emit(`update:${name}`, 23);
            return;
          }
        }
        if (this[name] > 0) {
          this.$emit(`update:${name}`, this[name] - 1);
        }
      },
      increase (name) {
        if (name === 'delay_hours') {
          if (this.delay_hours === 23) {
            this.increase('delay_days');
            this.$emit(`update:${name}`, 0);
            return;
          }
        }
        this.$emit(`update:${name}`, this[name] + 1);
      },
    },
  };
</script>
<style lang="scss">
  .delay-counter {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    column-gap: 12px;
    row-gap: 4px;
    white-space: nowrap;
    &:hover .delay-counter__counter-icon {
      transition-duration: .3s;
    }
    & .input {
      width: fit-content;
    }
    &__tip {
      font-size:  0.83rem;
      line-height: 18px;
      width: 100%;
    }
    &__text {
      align-self: center;
    }
    &__counter {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      background: var(--neutral-50);
      column-gap: 12px;
      border-radius: 8px;
    }

    &__counter-icon {
      background: var(--neutral-100);
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 1;
      transition-timing-function: ease-in-out;
      transition-duration: .7s;
      &:hover {
        background: var(--neutral-200);
      }
      &_minus {
        border-radius: 8px 0px 0px 8px;
      }
      &_plus {
        border-radius: 0px 8px 8px 0px;
      }
    }
  }
</style>
