<template>
  <div
    v-if="notifications.length"
    class="flex-column flex-gap-16"
  >
    <item-notifications
      v-for="notification in getMaxNotifications"
      :key="notification._id"
      :notification="notification"
      @close="deleteNotification(notification)"
      @make-action="makeAction(notification)"
      @click.native="makeAction(notification)"
    />
  </div>
</template>
<script>
  import ItemNotifications from '@/plugins/notify/ItemNotifications';
  import { eventBus } from '@/eventbus';
  import { CONSTANTS } from '@/plugins/notify/notify.plugin';

  export default {
    name: 'DefaultNotifications',
    components: {
      ItemNotifications,
    },
    data () {
      return {
        notifications: [],
      };
    },
    computed: {
      getMaxNotifications () {
        return this.notifications.slice(0, 3);
      },
    },
    created () {
      eventBus.$on(CONSTANTS.NOTIFY, this.addNewNotification);
    },
    destroyed () {
      eventBus.$off(CONSTANTS.NOTIFY);
    },
    methods: {
      addNewNotification (notification) {
        this.notifications.push(notification);
        if (!notification.withoutDuration) {
          setTimeout(() => this.deleteNotification(notification), 4000);
        }
      },
      deleteNotification (notification) {
        this.notifications = this.notifications.filter(n => n._id !== notification._id);
      },

      makeAction (notification) {
        notification.actionButton.handler();
        this.deleteNotification(notification);
      },
    },
  };
</script>
