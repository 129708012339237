<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    :class="[
      'default-badge',
      'default-badge_' + size,
      'background_' + color
    ]"
  >
    {{ count > 99 ? '99+' : count }}
  </div>
</template>
<script>
  export default {
    name: 'DefaultBadge',
    props: {
      count: {
        type: [String, Number],
        required: true,
      },
      color: {
        type: String,
        default: 'brand',
      },
      size: { // m, s
        type: String,
        default: 's',
      },
    },
  };
</script>
<style lang="scss">
.default-badge {
  display: inline-block;
  padding: 0 6px;
  border-radius: 100px;
  color: $cl-white;

  &_m {
    @include text_s;
  }

  &_s {
    @include text_xs;
  }
}
</style>
