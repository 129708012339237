/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const differentFunctionsMixin = {
  methods: {
    copyValue (value) {
      // Для копирования в буфер обмена создаю инпут => select => copy => delete el
      const $input = document.createElement('input');
      $input.value = value;
      document.body.appendChild($input);
      $input.select();
      document.execCommand('Copy');
      $input.remove();
      this.$noty.success('Copied successfully');
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    createNewTab (link, type = '_blank') {
      window.open(link, type);
    },
  },
};
