<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="calendly-menu">
    <div class="calendly-menu__container">
      <div
        v-for="item in stats"
        :key="item._id"
        class="calendly-menu__item"
      >
        <div class="calendly-menu__color" />
        <span class="text_s">{{ item.name }}</span>
        <div class="calendly-menu__info">
          <span class="text_xs color_secondary">{{ item.amount }}</span>
          <span class="calendly-menu__dot" />
          <span class="text_xs color_secondary">{{ item.type }}</span>
        </div>
        <span class="calendly-menu__text link text_xs"><i
          class=" bx bx-link-alt"
        />Insert link</span>
      </div>
    </div>
    <default-button
      class="calendly-menu__button"
      form="rectangle"
      size="m"
      :has-right-icon="true"
      title="See all event types"
      icon-name="bx-link-external"
      color="neutral"
    />
  </div>
</template>

<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  export default {
    name: 'CalendlyMenu',
    components: {
      DefaultButton,
    },
    mixins: [],
    props: {
    },
    data () {
      return {
        stats: [
          {
            name: 'Phone screen',
            amount: '90 min',
            type: 'One-on-One',
          },
          {
            name: 'Phone screen',
            amount: '90 min',
            type: 'One-on-One',
          },
          {
            name: 'Phone screen',
            amount: '90 min',
            type: 'One-on-One',
          },
        ],
      };
    },
    computed: {
    },
    mounted () {
    },
    methods: {
    },
  };
</script>

<style lang="scss">
.calendly-menu {
  display: flex;
  gap: 8px;
flex-flow: column;
  &__container {
  display: flex;
  gap: 16px;
  align-items: center;
  }
  &__item {
    flex-flow: column;
    background: var(--neutral-50);
    display: flex;
    padding: 16px;
    max-width: 250px;
    flex: 1;
    gap: 4px;
    border-radius: 8px;
    position: relative;
  }
  &__info {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &__dot {
    width: 4px;
    height: 4px;
    border-radius: 100px;
    background: $t-light-secondary;
  }
  &__color {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 4px;
    border-radius: 8px 8px 0px 0px;
    background: #641BFD;
  }
  &__button {
    display: flex;
    width: fit-content;
    align-items: center;
  }
  &__text {
    gap: 4px;
    display: flex;
    align-items: center;
  }
}
</style>
