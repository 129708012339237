/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function getAllWebhooks (credentialId) {
  const data = await useHttp(
    '/integrations/webhooks/fetch',
    'GET',
    null,
    {
      credentials_id: credentialId,
    }
  );

  return data.payload;
}

async function createWebhook (credentialId, url, triggers) {
  const data = await useHttp(
    '/integrations/webhooks',
    'POST',
    {
      target_url: url,
      triggers: JSON.stringify(triggers),
      credentials_id: credentialId,
    },
    null,
    true
  );

  return data.payload;
}

async function deleteWebhook (webhookId, credentialId) {
  const data = await useHttp(
    `/integrations/webhooks/${webhookId}`,
    'DELETE',
    {
      credentials_id: credentialId,
    },
    null,
    true
  );

  return data.payload;
}

async function testWebhook (url, trigger, credentialId) {
  const data = await useHttp(
    '/integrations/webhooks/test',
    'POST',
    {
      target_url: url,
      trigger: trigger,
      credentials_id: credentialId,
    },
    null,
    true
  );

  return data.payload;
}
async function updateWebhookFields (defaultFIelds, labels, credentialId) {
  const data = await useHttp(
    '/integrations/webhooks/settings',
    'PUT',
    {
      default_fields: defaultFIelds,
      labels: labels,
      credentials_id: credentialId,
    },
    null,
    true
  );
  return data.payload;
}
export { getAllWebhooks, createWebhook, deleteWebhook, testWebhook, updateWebhookFields };
