var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"crm-header"},[_c('div',{class:[
      'crm-header__left-side',
      { 'crm-header__disabled-block': !_vm.allPipelines.length }
    ]},[_c('default-input',{attrs:{"value":_vm.query,"placeholder":_vm.$t('pipelinesPage.header.searchPlaceholder'),"debounce":300,"size":"xs","icon":"bx-search"},on:{"update:value":function($event){_vm.query=$event},"input":_vm.search}})],1),_c('div',{staticClass:"crm-header__right-side"},[(_vm.owners.length && _vm.loaded)?_c('div',{directives:[{name:"closable",rawName:"v-closable",value:({
        exclude: ['selectOwner'],
        handler: () => _vm.isSelectOwner = false,
        params: 'isSelectOwner',
      }),expression:"{\n        exclude: ['selectOwner'],\n        handler: () => isSelectOwner = false,\n        params: 'isSelectOwner',\n      }"}],class:[
        'crm-header__select-owner',
        { 'crm-header__disabled-block': !_vm.allPipelines.length }
      ],on:{"click":function($event){_vm.isSelectOwner = !_vm.isSelectOwner}}},[_c('div',{ref:"selectOwner",staticClass:"crm-header__selected-owner"},[(_vm.selectedOwner.img)?_c('img',{staticClass:"crm-header__owner-avatar",attrs:{"loading":"lazy","src":_vm.selectedOwner.img,"alt":"owner avatar"}}):_c('i',{staticClass:"bx bx-user-circle color_secondary crm-header__owner-avatar crm-header__owner-avatar_icon"}),_c('input',{staticClass:"crm-header__owner-input color_black",attrs:{"readonly":"true","placeholder":_vm.$t('pipelinesPage.header.selects.owner.placeholder'),"type":"text"},domProps:{"value":_vm.selectedOwner.name || 'All Owners'}}),_c('i',{staticClass:"bx crm-header__selected-owner-arrow bx-chevron-down"})]),_c('ul',{ref:"selectOwner",staticClass:"crm-header__select-owner-items",class:{'crm-header__select-owner-items_open': _vm.isSelectOwner,
                 'crm-header__select-owner-items_close': !_vm.isSelectOwner,
        }},_vm._l((_vm.owners),function(owner){return _c('li',{key:owner._id,staticClass:"crm-header__select-owner-item",on:{"click":function($event){$event.stopPropagation();return _vm.selectOwner(owner)}}},[(owner.img)?_c('img',{staticClass:"crm-header__owner-avatar",attrs:{"loading":"lazy","src":owner.img,"alt":"owner avatar"}}):_c('i',{staticClass:"bx bx-user-circle color_secondary crm-header__owner-avatar crm-header__owner-avatar_icon"}),_c('span',{staticClass:"crm-header__text crm-header__text_hidden"},[_vm._v(_vm._s(owner.name))])])}),0)]):_vm._e(),_c('default-select',{key:_vm.selectedPipeline.name,class:{ 'crm-header__disabled-block': !_vm.allPipelines.length },attrs:{"data-closable":"pipeline","items":_vm.pipelines,"value":_vm.selectedPipeline._id ? _vm.selectedPipeline.name : '',"input-size":"xs","input-placeholder":"Select Pipeline"},on:{"select":_vm.selectPipeline}}),_c('default-select',{class:{ 'crm-header__disabled-block': !_vm.allPipelines.length },attrs:{"items":_vm.statusFilters,"data-closable":"status","read-only":true,"value":_vm.selectedStatus._id ? _vm.selectedStatus.name : '',"input-size":"xs","input-placeholder":_vm.$t('pipelinesPage.header.selects.deals.placeholder')},on:{"select":_vm.selectStatus}}),_c('crm-switcher',{attrs:{"crms":_vm.crms},on:{"select-crm":function($event){return _vm.$emit('select-crm', $event)}}}),(_vm.$route.params.integration !== 'default')?_c('default-button',{attrs:{"color":"neutral","form":"square","icon-name":"bx-refresh","size":"m"},on:{"action":function($event){return _vm.$emit('refresh')}}}):_vm._e(),(_vm.$route.params.integration === 'default')?_c('default-tooltip',{class:[
        'crm-table-column__tooltip',
        { 'crm-header__disabled-block': !_vm.allPipelines.length }
      ],attrs:{"data-closable":"pipelineMenu","has-triangle":false,"size":'s',"show":_vm.isShowTooltip,"items":_vm.menuFields,"list-position":"right"},on:{"update:show":function($event){_vm.isShowTooltip=$event},"choose":_vm.selectMenuItem},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('default-button',{staticClass:"crm-header__btn-delete",attrs:{"color":"neutral","form":"square","icon-name":"bx-dots-horizontal-rounded","size":"m"}})]},proxy:true}],null,false,4195241307)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }