export const brandsNoEmailForBasic = [
  'kennected',
  'bdrai',
  'leadballer',
  'businessaccelerator',
  'networked',
  'growthstackconnect',
  'insurancebranding',
  'linkedhiredauto20',
  'selfdisrupt',
  'prospectmagic',
  'ninetypartneroutreach',
  'linkedgenerator',
  'linkbridge',
  'systemofgrowth',
  'leadscout',
  'carolineleadgen',
  'salesenablement',
  'leadstormio',
  'linkedintelaccess',
  'salesstarconnect',
];
