/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const OnboardingPage = () => import('@/views/OnboardingPage');

const integrations = [
  {
    path: '/onboarding',
    name: 'onboarding',
    component: OnboardingPage,
    meta: {
      layout: 'DefaultLayout',
      hasSidebar: true,
    },
  },
];

export default integrations;
