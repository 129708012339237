/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const closableMixin = {
  data () {
    return {
      showBlocks: {},
      excludedElements: [],
      closableElements: [],
    };
  },
  mounted () {
    this.closableElements = [...this.$el.querySelectorAll('[data-type="closable"], [data-closable]')] || [];
    this.excludedElements = [...this.$el.querySelectorAll('[data-excluded]')] || [];
    this.closableElements.forEach((element) => {
      this.showBlocks[element.dataset.closable] = false;
    });
    document.addEventListener('click', this.closeListener, { capture: true });
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeListener, { capture: true });
  },
  methods: {
    closeListener (e) {
      let closable = Array.prototype.find.call(this.closableElements, (element) => element.contains(e.target));
      const excludedElement = Array.prototype.find.call(this.excludedElements, (element) => element.contains(e.target));
      if ((!closable) && excludedElement) {
        const excludedName = excludedElement.getAttribute('data-excluded');
        closable = Array.prototype.find.call(this.closableElements, (element) => element.getAttribute('data-closable') === excludedName);
      }
      const closableName = closable?.getAttribute('data-closable') || '';
      const newShowBlocks = {};
      const oldShowBlocks = this.showBlocks;
      Object.keys(oldShowBlocks).forEach((key) => {
        if (key !== closableName) {
          newShowBlocks[key] = key = false;
        } else {
          newShowBlocks[key] = oldShowBlocks[key];
        }
      });
      // newShowBlocks = Object.assign(newShowBlocks, this.showBlocks);
      this.showBlocks = newShowBlocks;
    },

  },
};
