/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { computed } from 'vue';
import i18n from '@/i18n';

export const TEMPLATES = computed(() => ([
  {
    name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.personal.name'),
    _id: 'personal',
    data: [
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.personal.fields.firstName'),
        value: 'first_name',
        space: 10,
        amount: 0,
      },
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.personal.fields.totalYearsInCareer'),
        value: 'total_years_in_career',
        space: 4,
        amount: 0,
      },
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.personal.fields.lastName'),
        value: 'last_name',
        space: 15,
        amount: 0,
      },
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.personal.fields.totalCareerPositionCount'),
        value: 'total_career_positions_count',
        space: 4,
        amount: 0,
      },
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.personal.fields.occupation'),
        value: 'occupation',
        space: 20,
        amount: 0,
      },
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.personal.fields.collegeName'),
        value: 'college_name',
        space: 15,
        amount: 0,
      },
    ],
  },
  {
    name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.companyAndLocation.name'),
    _id: 'company',
    data: [
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.companyAndLocation.fields.location'),
        value: 'location',
        space: 15,
        amount: 0,
      },
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.companyAndLocation.fields.companyName'),
        value: 'company_name',
        space: 15,
        amount: 0,
      },
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.companyAndLocation.fields.yearsInCompany'),
        value: 'years_in_company',
        space: 4,
        amount: 0,
      },
    ],
  },
  {
    name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.lyne.name'),
    _id: 'lyne',
    data: [
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.lyne.fields.firstName.name'),
        value: 'lyne_statement',
        text: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.lyne.fields.firstName.text'),
        space: 40,
        amount: 0,
      },
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.lyne.fields.lastName.name'),
        value: 'lyne_question?',
        text: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.lyne.fields.lastName.text'),
        space: 15,
        amount: 0,
      },
      {
        name: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.lyne.fields.totalCareerPositionCount.name'),
        value: 'lyne_followup',
        text: i18n.t('campaignsPage.editor.steps.modals.stepEdit.menu.placeholders.sections.lyne.fields.totalCareerPositionCount.text'),
        space: 50,
        amount: 0,
      },
    ],
  },
]));
