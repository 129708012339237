<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="practice-menu"
  >
    <div
      v-for="(item, i) in filteredByType"
      :key="`${i}${type}`"
      class="practice-menu__item"
      @click="addPractice(item)"
    >
      <span v-if="item.subject" class="practice-menu__text practice-menu__text_subject text_s">
        {{ item.subject }}
      </span>
      <span class="practice-menu__text practice-menu__text_message text_xs color_secondary">
        {{ item.message }}
      </span>
      <div @click.stop="refresh(i)" class="practice-menu__refresh">
        <i class='bx bx-refresh color_white'></i>
      </div>
    </div>
  </div>
</template>
<script>
  import { templatesMixin } from '@/mixins/templatesMixin';
  import { BEST_PRACTICE } from '@/utils/workflows/bestPractice';
  export default {
    name: 'PracticeMenu',
    components: {

    },
    mixins: [templatesMixin],
    props: {
      type: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        filteredByType: [],
      };
    },
    computed: {
    },
    mounted () {
      this.filteredByType = BEST_PRACTICE.slice().find(e => e._id === this.type).data.slice(0, 3);
    },
    methods: {
      refresh (index) {
        const data = BEST_PRACTICE.slice().find(e => e._id === this.type).data;
        const result = data.filter(x => !this.filteredByType.includes(x));
        const lol = result[Math.floor(Math.random() * result.length)];
        this.filteredByType.splice(index, 1, lol);
      },
      addPractice (item) {
        if (!this.disabled) {
          this.$emit('add-practice', item);
        }
      },
    },
  };
</script>
<style lang="scss">
.practice-menu {
  display: flex;
  gap: 8px;
    &__item {
      position: relative;
      display: flex;
      flex-flow: column;
      padding: 12px 16px;
      background: var(--neutral-50);
      border-radius: 8px;
      gap: 4px;
      cursor: pointer;
      min-width: 270px;
      max-height: 100px;
      &:hover {
        opacity: 0.7;
      }
    }
  &__refresh {
    position: absolute;
    box-shadow: 0px 2px 8px rgba(10, 32, 51, 0.12);
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-cl);
    left: calc(50% - 32px/2 + 0.33px);
    bottom: -16px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  &__text {
    &_subject {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90%;
      white-space: nowrap;
    }
    &_message {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-width: 100%;
    }
  }
  }
</style>
