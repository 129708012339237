/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapGetters } from 'vuex';

class LoaderOptions {
  /** @param {{width: int, height: number, container: *, opacity: }} extraOptions */
  constructor (extraOptions = null) {
    const defaultOptions = {
      height: 90,
      width: 90,
      opacity: 0.5,
      container: null,
    };
    if (extraOptions) {
      Object.assign(defaultOptions, extraOptions);
    }

    this.color = 'var(--primary-cl)';
    this.height = defaultOptions.height;
    this.width = defaultOptions.width;
    this.backgroundColor = 'var(--loader-bg)';
    this.opacity = defaultOptions.opacity;
    this.loader = 'dots';
    this.lockScroll = true;
    this.transition = 'fade';
    this.container = defaultOptions.container;
  }
}

const loaderMixin = {
  watch: {
    loaded (value) {
      if (this.$refs.vldParent) {
        if (!value) {
          const options = new LoaderOptions(this.loaderOptions);
          options.container = this.$refs.vldParent;
          this.loader = this.$loading.show(options);
        } else {
          this.loader?.hide();
        }
      }
    },
    loaders: {
      handler () {
        Object.entries(this.loaders).forEach(([ref, show]) => {
          if (this.$refs[ref] || ref === 'window') {
            if (show) {
              if (!this.loaderObjects[ref]) {
                const options = new LoaderOptions();
                if (ref === 'window') {
                  options.container = null;
                  options.isFullPage = true;
                } else {
                  options.container = this.$refs[ref];
                }
                this.loaderObjects[ref] = this.$loading.show(options);
              }
            } else {
              this.loaderObjects[ref]?.hide();
              this.loaderObjects[ref] = null;
            }
          }
        });
      },
      deep: true,

    },
  },
  computed: {
    ...mapGetters({
      account: 'account/account',
    }),
  },
  components: {
    Loading,
  },
  data () {
    return {

      loaded: true,
      loaders: {},
      loaderObjects: {},
      loaderOptions: {},
      loader: null,
    };
  },
};
export { LoaderOptions, loaderMixin };
