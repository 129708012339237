<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end-2">
    <modal
      :key="key"
      name="LoadTemplateModal"
      width="600px"
      height="auto"
      :adaptive="true"
      @closed="handleClose"
    >
      <div class="modal load-template-modal">
        <div
          class="load-template-modal__close-button"
          @click="handleClose"
        >
          <i class="bx bx-x" />
        </div>
        <div class="load-template-modal__header">
          <h2 class="load-template-modal__title">
            {{ $t('modals.loadTemplate.title') }}
          </h2>
          <default-input
            :value.sync="filters.query"
            :is-fulled="true"
            :debounce="300"
            size="xs"
            icon="bx-search"
            :placeholder="$t('placeholders.search')"
          />
        </div>
        <!-- <div class="load-template-modal__filters">
          <div
            v-for="(item, i) in filtersTemplate"
            :key="item.name + i"
            :class="!item.isActive || 'load-template-modal__filter_active'"
            class="load-template-modal__filter"
            @click="selectFilter(i)"
          >
            <span
              class="text_s"
              :class="{'color_white': item.isActive}"
            >{{ item.name }}</span>
          </div>
        </div> -->
        <div
          v-if="templates.length"
          id="tooltipContainer"
          ref="vldParent"
          class="load-template-modal__template-container vld-parent"
        >
          <template-item
            v-for="(templateItem, i) in templates"
            :key="i + 'idTemplateList'"
            :template="templateItem"
            :full-content="false"
            class="load-template-modal__template-item"
            @choose-template="chooseTemplate"
          />
        </div>
        <div
          v-else
          class="text_m mt-2 load-template-modal__template-container"
        >
          {{ $t('modals.loadTemplate.noTemplates') }}
        </div>
      </div>
    </modal>
  </portal>
</template>

<script>
  import TemplateItem from '@/components/TemplatePage/TemplateItem.vue';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { getTemplates } from '@/api/TemplateMethods';

  export default {
    name: 'LoadTemplateModal',
    components: {
      DefaultInput,
      TemplateItem,
    },
    mixins: [loaderMixin],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        filtersTemplate: [
          {
            name: this.$t('modals.loadTemplate.filters.recent'),
            isActive: true,
          },
          {
            name: this.$t('modals.loadTemplate.filters.favorite'),
            isActive: false,
          },
          {
            name: this.$t('modals.loadTemplate.filters.all'),
            isActive: false,
          },
        ],
        templates: [],
        pageSize: 10,
        filters: {
          query: '',
        },
        key: 'LoadTemplateModal',
      };
    },
    watch: {
      async show (show) {
        show
          ? this.$modal.show('LoadTemplateModal')
          : this.$modal.hide('LoadTemplateModal');
        if (!show) {
          this.key = `${this.key} ${new Date().getTime()}`;
        } else {
          await this.getTemplates();
        }
      },

      async 'filters.query' () {
        await this.getTemplates();
      },
    },

    methods: {
      async getTemplates () {
        this.filters.types = [this.type];
        this.loaded = false;
        try {
          const response = await getTemplates(this.filters);
          this.templates = response.data;
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },

      chooseTemplate (template) {
        this.$emit('send', template);
      },
      handleClose () {
        this.$emit('update:show', false);
        this.text = '';
      },
      send () {
        this.$emit('send', this.template);
      },
    // selectFilter (selectedFilter) {
    //   this.filtersTemplate.forEach((filter, i) => {
    //     if (selectedFilter === i) {
    //       filter.isActive = true;
    //     } else {
    //       filter.isActive = false;
    //     }
    //   });
    // },
    },
  };
</script>

<style lang="scss">
.load-template-modal {
  transition: 0.8s;
  height: 100%;
  position: relative;
  background-color: var(--background-color);
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  gap: 16px;
  &__close-button {
    position: absolute;
    cursor: pointer;
    display: block;
    padding: 0;
    margin: 0;
    top: 12px;
    right: 12px;
    border: none;
    outline: none;
    background: none;
    font-size: 24px;
    color: #919399;
  }
  &__header {
    display: flex;
    flex-flow: column;
    gap: 24px;
  }
  &__title {
    font-size: 20px;
    font-weight: 400;
  }
  &__filters {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
  }
  &__filter {
    padding: 6px 12px;
    background: #f3f3f3;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &_active {
      background: var(--primary-cl);
      cursor: inherit;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  &__template-container {
    display: flex;
    flex-flow: column;
    gap: 8px;
    max-height: 300px;
    overflow: scroll;
    text-align: center;
    color: #919399;
  }
}
</style>
