<template>
  <article class="background_primary br-8">
    <!-- Внутренний контейнер обязателен, как и класс на родителе, иначе карточка будет просвечиваться из-за rgba -->
    <div
      :class="[
        'flex-row-between flex-gap-16 p-2 br-8',
        'background_' + notification.type,
        { 'cursor-pointer': notification.actionButton },
      ]"
    >
      <i :class="iconClasses" />
      <div class="flex-column flex-gap-4 flex-grow-1">
        <h1
          v-if="notification.title"
          class="text_xs text_accent"
        >
          {{ notification.title }}
        </h1>
        <p
          v-if="notification.text"
          class="text_xs color_tertiary"
        >
          {{ notification.text }}
        </p>
        <div
          v-if="notification.actionButton"
          class="flex-row flex-gap-8"
        >
          <default-button
            form="rectangle"
            :title="notification.actionButton.title"
            :color="notification.type"
            size="s"
            @action="$emit('make-action')"
          />
          <default-button
            v-if="notification.hasClosedButton"
            form="rectangle"
            :title="$t('actions.cancel')"
            title-color="color_tertiary"
            hovered-title-color="hovered_secondary"
            hovered-background-color="background_hovered_neutral_100"
            size="s"
            @action="$emit('close')"
          />
        </div>
      </div>
      <i
        class="bx bx-x icon_size_16 flex-align-self-start color_tertiary cursor-pointer"
        @click.stop="$emit('close')"
      />
    </div>
  </article>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';

  export default {
    name: 'ItemNotifications',
    components: {
      DefaultButton,
    },
    props: {
      notification: {
        type: Object,
        required: true,
      },
    },
    computed: {
      iconClasses () {
        return [
          'bx icon_size_16 flex-align-self-start',
          { 'color_info bx-info-circle': this.notification.type === 'info' },
          { 'color_warning bxs-error': this.notification.type === 'warning' },
          { 'color_danger bxs-error': this.notification.type === 'error' },
          { 'color_success bx-check-circle': this.notification.type === 'success' },
        ];
      },
    },
  };
</script>
