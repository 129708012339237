var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLabelsShort)?_c('div',{staticClass:"labels"},_vm._l((_vm.labels),function(label,i){return _c('p',{key:i,staticClass:"label",style:({
      background: label.color + '1A',
      color: label.color,
    }),on:{"click":function($event){return _vm.$emit('click-label', label)}}},[_vm._v(" "+_vm._s(label.name)+" ")])}),0):_c('div',{staticClass:"labels"},[_vm._l((_vm.labels.slice(0, _vm.maxShownLabels)),function(label,i){return _c('p',{key:i,staticClass:"label",style:({
      background: label.color + '1A',
      color: label.color,
    }),on:{"click":function($event){return _vm.$emit('click-label', label)}}},[_vm._v(" "+_vm._s(label.name)+" ")])}),(_vm.labels.length - _vm.maxShownLabels > 0)?_c('span',{staticClass:"label__text"},[_vm._v(" +"+_vm._s(_vm.labels.length - _vm.maxShownLabels))]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }