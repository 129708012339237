<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="preview-menu"
  >
    <preview-email-message
      v-if="type === 'send_email'"
      :message="parse(message)"
      :subject="parse(subject)"
    />
    <preview-message
      v-if="type === 'li_send_message'"
      :message="parse(message)"
      :subject="parse(subject)"
      :video="video"
    />
    <preview-inmail
      v-if="type === 'li_send_inmail'"
      :message="parse(message)"
      :subject="parse(subject)"
      :video="video"
    />
    <preview-connect
      v-if="type === 'li_connect'"
      :message="parse(message)"
      :subject="parse(subject)"
    />
  </div>
</template>
<script>
  import PreviewEmailMessage from '@/components/TemplatePage/PreviewEmailMessage.vue';
  import PreviewInmail from '@/components/TemplatePage/PreviewInmail.vue';
  import PreviewMessage from '@/components/TemplatePage/PreviewMessage.vue';
  import PreviewConnect from '@/components/TemplatePage/PreviewConnect.vue';
  import { templatesMixin } from '@/mixins/templatesMixin';

  export default {
    name: 'PreviewMenu',
    components: {
      PreviewEmailMessage,
      PreviewMessage,
      PreviewInmail,
      PreviewConnect,
    },
    mixins: [templatesMixin],
    props: {
      message: {
        type: String,
        default: '',
      },
      subject: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: '',
      },
      video: {
        type: Object,
        default: null,
      },
    },
    methods: {
      parse (string) {
        const templateItems = {
          first_name: 'John',
          last_name: 'Smith',
          location: 'London',
          company_name: 'LinkedIn',
          years_in_company: '2',
          total_career_positions_count: '4',
          total_years_in_career: '5',
          college_name: 'Oxford',
          occupation: 'supervisor',
          custom_field_1: '',
        };
        const words = string.split(/(?=[{}])/);
        words.map((word, index) => {
          if (
            index !== words.length - 1 &&
            word.charAt(0) === '{' &&
            words[index + 1].charAt(0) === '}'
          ) {
            word = word + '}';
            const fits = !(word.includes('FALLBACK')) && this.templatesRegexps.some((regexp) => regexp.test(word));

            if (fits) {
              const replaceTo = word.split(':')[1] ? word.split(':')[1].slice(0, -1) : templateItems[word.replace('}', '').replace('{', '')];
              string = string.replace(word, replaceTo);
            }
          }
        });
        return string;
      },
    },
  };
</script>
<style lang="scss">
.preview-menu {
 padding: 16px;
  width: 70%;
  box-shadow: 0px 2px 8px rgba(10, 32, 51, 0.12);
  border-radius: 16px;
  .preview-email__content{
    min-height: inherit;
    max-height: 100px;
  }
  .preview-message__message-container {
    max-height: 130px;
    overflow: hidden;
  }
}
</style>
