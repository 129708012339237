<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <article
    :class="[
      'default-card',
      {
        'default-card_hovered default-card_hovered_neutral': isHovered,
        'default-card_activated': isActive,
      }
    ]"
  >
    <div class="overflow-hidden">
      <p class="text_weight_accent text_ellipsis mb-1 text_s">
        {{ workflow.name }}
      </p>
      <my-workflow-info
        :workflow="workflow"
        :credential-full-name="selectedCredential.full_name"
      />
    </div>
    <default-label
      class="flex-align-self-center"
      size="xs"
      :custom-type="statusLabel.type"
      :label-name="statusLabel.name"
    />
  </article>
</template>
<script setup>
  import MyWorkflowInfo from '@/components/workflowsPage/MyWorkflowInfo.vue';
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel';
  import { onBeforeMount, ref } from 'vue';
  import { useStore } from '@/store';
  import { useI18n } from '@/i18n';
  const store = useStore();
  // name: 'WorkflowCard',
  const props = defineProps({
    workflow: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  });
  const statusLabel = ref({
    type: '',
    name: '',
  });
  const { t } = useI18n();

  const selectedCredential = store.getters['credentials/selectedCredential'];
  const setStatusLabelHandler = () => {
    switch (props.workflow.status) {
    case 'active':
      statusLabel.value.type = 'success';
      break;
    case 'inactive':
      statusLabel.value.type = 'info';
      break;
    }
    statusLabel.value.name = t(`status.${props.workflow.status}`);
  };
  onBeforeMount(setStatusLabelHandler);
</script>
<style lang="scss">
@import '@/components/cards/cards';
</style>
