/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const normalizeNameByActionMixin = {
  filters: {
    normalizeNameByAction: function (action) {
      switch (action) {
        case 'li_view_profile': {
          return 'Visit Profile';
        }
        case 'li_endorse_skills': {
          return 'Endorse Top 5 Skills';
        }
        case 'li_follow_profile': {
          return 'Follow profile';
        }
        case 'li_fetch_posts': {
          return 'Fetching posts';
        }
        default: {
          return 'New action';
        }
      }
    },
  },
};
