/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import _Quill from 'quill';
import quillEditor from './editor.vue';

import mixin from './mixin';

const Quill = window.Quill || _Quill;
const install = (Vue, globalOptions) => {
  if (globalOptions) {
    quillEditor.props.globalOptions.default = () => globalOptions;
  }
  Vue.component(quillEditor.name, quillEditor);
};

const VueQuillEditor = { Quill, quillEditor, install };
export default VueQuillEditor;

export { Quill, quillEditor, install, mixin };
