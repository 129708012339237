<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <default-modal
    :key-modal="key"
    v-bind="$props"
    :show.sync="showInputModal"
    @send="send"
  >
    <section class="modal__input mb-3">
      <input
        v-model="inputText"
        v-debounce:200="updateText"
        :class="['input', { input_error: error }]"
        type="text"
        :placeholder="placeholder"
      >
      <p
        v-if="error"
        class="modal__error"
      >
        {{ errorText }}
      </p>
      <div
        v-if="localTags.length"
        class="tags"
      >
        <div
          v-for="(tag, i) in localTags"
          :key="tag + i"
          class="tags__tag primary-bg"
          @click="setTag(tag)"
        >
          <span>{{ tag }}</span>
          <div
            class="tags__tag-remove"
            @click="deleteTag(tag)"
          />
        </div>
      </div>
    </section>
  </default-modal>
</template>

<script>
  import DefaultModal from './defaultModal.vue';

  export default {
    name: 'InputModal',
    components: {
      DefaultModal,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      modalText: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: 'inputModal',
      },
      primaryButtonText: {
        type: String,
        default: function () {
          return this.$t('actions.confirm');
        },
      },
      cancelButtonText: {
        type: String,
        default: function () {
          return this.$t('actions.cancel');
        },
      },
      errorText: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      height: {
        type: String,
        default: '420px',
      },
      width: {
        type: String,
        default: '450px',
      },
      tags: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        inputText: this.text,
        showInputModal: this.show,
        localTags: this.tags.slice(),
        key: 'inputModal',
      };
    },
    watch: {
      showInputModal (show) {
        !show && this.handleClose();
      },
      show (show) {
        this.showInputModal = show;
        show ? this.$modal.show(this.name) : this.$modal.hide(this.name);
        if (!show) {
          this.inputText = '';
          this.key = `${this.key} ${new Date().getTime()}`;
        }
      },
      text (text) {
        this.inputText = text;
      },
      tags () {
        this.localTags = this.tags.slice();
      },
    },
    methods: {
      updateText () {
        this.$emit('update:text', this.inputText);
      },
      handleClose () {
        this.$emit('update:show', false);
        this.$emit('update:text', '');
        this.inputText = '';
      },
      send () {
        this.$emit('send', this.inputText);
      },
      deleteTag (tag) {
        this.$emit('update:tags', this.localTags.filter(localTag => localTag !== tag));
      },
      setTag (tag) {
        this.inputText = tag;
      },
    },
  };
</script>

<style lang="scss" scoped>

  .update-label {
    &__colors-block {
      position: relative;
    }
  }

  .tags {
    display: flex;
    flex-flow: row wrap;
    column-gap: 10px;
    margin-top: 10px;
    row-gap:10px;

    &__tag {
      font-size: 15px;
      padding: 5px;
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      transition-duration: 0.3s;
      &:hover {
        color: #fff;
        opacity: 0.7;
      }
    }
    &__tag-remove {
      position: relative;
      height: 20px;
      width: 20px;
      font-size:  0.83rem;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      &:after,
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: ' ';
        height: 0.8em;
        width: 0.1em;
        background-color: #333;
        box-sizing: border-box;
        background: #fff;
      }
      &:before {
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
      }
      &:after {
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      }
    }
  }
</style>
