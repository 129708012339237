/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const PricingPage = () => import('@/views/PricingPage');

const meta = {
  layout: 'DefaultLayout',
  hasSidebar: true,
};

const pricing = [
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingPage,
    meta,
    children: [
      {
        path: '/pricing/extension/:priceId',
        name: 'extension',
        component: PricingPage,
        meta,
      },
      {
        path: '/pricing/add-seat',
        name: 'add-seat',
        component: PricingPage,
        meta,
      },
    ],
  },

];

export default pricing;
