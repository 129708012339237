/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

// modules
import auth from '@/router/modules/auth';
import admin from '@/router/modules/admin';
import billing from '@/router/modules/billing';
import campaigns from '@/router/modules/campaigns';
import credentials from '@/router/modules/credentials';
import integrations from '@/router/modules/integrations';
import profiles from '@/router/modules/profiles';
import reports from '@/router/modules/reports';
import settings from '@/router/modules/settings';
import onboarding from '@/router/modules/onboarding';
import space from '@/router/modules/space';
import preferences from '@/router/modules/preferences';
import templates from '@/router/modules/templates';
import pricing from '@/router/modules/pricing';
import workspace from '@/router/modules/workspace';
import { redirectRules } from '@/data/redirectRules';
import { loadLocaleMessages } from '@/i18n';

// routes
import InboxPage from '@/views/InboxPage';
const TermsOfUse = () => import('@/views/TermsOfUse');
const PrivacyPolicy = () => import('@/views/PrivacyPolicy');
const ErrorPage = () => import('@/views/ErrorPage');
const VideoPage = () => import('@/views/VideoPage');
const PaymentInProgress = () => import('@/views/PaymentInProgress');
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => {
    if (err.message.includes('Redirected when going from')) return console.error(err.message);
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};
Vue.use(VueRouter);
const routes = [
  ...auth,
  ...admin,
  ...billing,
  ...campaigns,
  ...credentials,
  ...integrations,
  ...profiles,
  ...reports,
  ...settings,
  ...onboarding,
  ...space,
  ...preferences,
  ...templates,
  ...pricing,
  ...workspace,
  {
    path: '*', // redirect for all undefined routes
    redirect: '/campaigns',
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: TermsOfUse,
    meta: {
      layout: 'ClearLayout',
    },
  },
  {
    path: '/inbox/:profileId?',
    name: 'inbox',
    component: InboxPage,
    meta: {
      layout: 'DefaultLayout',
      hasSidebar: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    meta: {
      layout: 'ClearLayout',
    },
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
    meta: {
      layout: 'ClearLayout',
    },
  },
  {
    path: '/v/:videoId',
    name: 'video',
    component: VideoPage,
    meta: {
      layout: 'DefaultLayout',
      withoutNavigation: true,
    },
  },
  {
    path: '/payment-in-progress',
    name: 'paymentInProgress',
    component: PaymentInProgress,
    meta: {
      layout: 'DefaultLayout',
      withoutNavigation: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

/**
 * @param {import('@/data/redirectRules').RedirectRule} redirectRule
 * @param {import('vue-router').Route} to
**/
function checkPath (redirectRule, to, next) {
  if (!redirectRule.check(to.path, store.getters['account/isAdmin'])) {
    if (to.name !== redirectRule.defaultRedirect.name) {
      next(redirectRule.defaultRedirect);
    } else {
      next();
    }
    return;
  }
  next();
}
router.beforeEach(async (to, from, next) => {
  await loadLocaleMessages();
  const params = new URLSearchParams(window.location.search);
  // get selectedCred from route or from URL
  if (!['edit-campaign', 'add-campaign', 'smart-sequences', 'edit-template', 'step-edit'].includes(to.name)) {
    sessionStorage.removeItem('workflow');
  }
  if (to.meta) {
    to.meta.back = from.fullPath;
  } else {
    to.meta = { back: from.fullPath };
  }
  const selectedCred = params.get('selectedCred') || to.query.selectedCred;
  const space = params.get('space');
  if (space && space !== 'undefined') {
    store.dispatch('account/SET_SPACE', space);
  }
  to.query.selectedCred = selectedCred;

  // set store mode
  const account = store.getters['account/account'];
  const credList = store.getters['credentials/credentialsList'];
  const selectedCredential = store.getters['credentials/selectedCredential'];
  if (account) {
    if (!account.is_paid) {
      return checkPath(redirectRules.unpaid, to, next);
    }
    if (!account.is_billing_enabled && to.name === 'billing') {
      next({ name: 'campaigns' });
    }
    if (credList) {
      if (credList.length) {
        if (selectedCred) {
          const credFromUrl = credList.find((cred) => cred._id === selectedCred);
          credFromUrl && await store.dispatch('credentials/SET_SELECTED_CREDENTIAL', credFromUrl);
        }
        if (!selectedCredential) {
          await store.dispatch('credentials/SET_SELECTED_CREDENTIAL', credList[0]);
        }
        next();
      } else {
        if (!account.is_billing_enabled) {
          return checkPath(redirectRules.noCredentials, to, next);
        }
        return account.is_paid
          ? checkPath(redirectRules.noCredentials, to, next)
          : checkPath(redirectRules.unpaid, to, next);
      }
    } else {
      next();
    }
  } else {
    next();
  }
});
router.afterEach(async (to, from) => {
  const space = to.query.space || from.query.space;
  const params = new URLSearchParams(window.location.search);
  const selectedCred = to.query.selectedCred || from.query.selectedCred;
  const user = store.getters['user/user'];
  // keep space in URL
  if (space && space !== 'undefined') {
    params.set('space', space);
    to.query.space = space;
    window.history.replaceState(null, null, `${to.path}?${params.toString()}`);
  }
  // keep selectedCred in URL
  if (selectedCred && !['', 'undefined'].includes(selectedCred)) {
    params.set('selectedCred', selectedCred);
    to.query.selectedCred = selectedCred;
    window.history.replaceState(null, null, `${to.path}?${params.toString()}`);
  }
  if (user && user.track_info?.irclickid) {
    for (const [track, value] of Object.entries(user.track_info)) {
      params.set(`${track}`, `${value}`);
      to.query[track] = `${value}`;
    }
    window.history.replaceState(null, null, `${to.path}?${params.toString()}`);
  }
});
export default router;
