/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { computed } from 'vue';
import i18n from '@/i18n';

export const CRM_DEAL_INPUTS = computed(() => ([
  {
    _id: 'platform',
    size: 'full',
    chosen: {},
    error: false,
    validation: '',
    name: i18n.tc('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.platform.name'),
    placeholder: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.platform.placeholder'),
    data: [],
  },
  {
    _id: 'title',
    input: true,
    size: 'full',
    model: '',
    name: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.title.name'),
    validation: 'required',
    placeholder: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.title.placeholder'),
  },
  {
    _id: 'pipeline_id',
    size: 'full',
    chosen: {},
    error: false,
    validation: '',
    name: i18n.tc('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.pipeline.name'),
    placeholder: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.pipeline.placeholder'),
    data: [],
  },

  {
    _id: 'stage_id',
    size: 'full',
    chosen: {},
    error: false,
    name: i18n.tc('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.stage.name'),
    validation: '',
    placeholder: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.stage.placeholder'),
    data: [],
  },
  {
    _id: 'value',
    input: true,
    size: 'half',
    model: '',
    validation: 'integer',
    name: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.value.name'),
    placeholder: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.value.placeholder'),
  },
  {
    _id: 'currency',
    size: 'half',
    chosen: {},
    error: false,
    name: ' ',
    placeholder: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.currency.placeholder'),
    data: [],
  },
  {
    _id: 'owner_id',
    size: 'full',
    chosen: {},
    error: false,
    name: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.owner.name'),
    placeholder: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.owner.placeholder'),
    data: [
      {
        name: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.owner.data.all'),
        _id: 'all',
      },
      {
        name: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.owner.data.none'),
        _id: 'none',
      },
    ],
  },
  {
    _id: 'probability',
    input: true,
    size: 'half',
    model: '',
    name: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.probability.name'),
    validation: 'min_value:0|max_value:100|integer',
    placeholder: i18n.t('inboxPage.chatSection.infoSidebar.dealsInfo.modals.crmDeal.inputs.probability.placeholder'),
  },
]));
