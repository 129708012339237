<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <!--  eslint-disable no-tabs -->
  <!-- Generator: Adobe Illustrator 19.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"

    viewBox="-8.24997 -12 71.49974 72"
  ><path
    fill="#28a8ea"
    d="M51.5095 0h-12.207a3.4884 3.4884 0 00-2.4677 1.0225L8.0222 29.835a3.4884 3.4884 0 00-1.0224 2.4677v12.207A3.49 3.49 0 0010.49 48h12.207a3.4884 3.4884 0 002.4678-1.0225l28.813-28.8125a3.49 3.49 0 001.022-2.4677V3.4903A3.49 3.49 0 0051.5095 0z"
  /><path
    fill="#0078d4"
    d="M51.5098 48H39.3025a3.49 3.49 0 01-2.4678-1.0222l-5.835-5.835V30.24a6.24 6.24 0 016.24-6.24h10.903l5.8349 5.835a3.49 3.49 0 011.0222 2.4678V44.51a3.49 3.49 0 01-3.49 3.49z"
  /><path
    fill="#50d9ff"
    d="M10.4898 0H22.697a3.49 3.49 0 012.4678 1.0222l5.835 5.835V17.76a6.24 6.24 0 01-6.24 6.24H13.8569l-5.835-5.835a3.49 3.49 0 01-1.0221-2.4677V3.49a3.49 3.49 0 013.49-3.49z"
  /><path
    opacity=".2"
    d="M28.9998 12.33v26.34a1.7344 1.7344 0 01-.04.3998A2.3138 2.3138 0 0126.6697 41h-19.67V10h19.67a2.326 2.326 0 012.33 2.33z"
  /><path
    opacity=".1"
    d="M29.9998 12.33v24.34A3.3617 3.3617 0 0126.6697 40h-19.67V9h19.67a3.3418 3.3418 0 013.33 3.33z"
  /><path
    opacity=".2"
    d="M28.9998 12.33v24.34A2.326 2.326 0 0126.6697 39h-19.67V10h19.67a2.326 2.326 0 012.33 2.33z"
  /><path
    opacity=".1"
    d="M27.9998 12.33v24.34A2.326 2.326 0 0125.6697 39h-18.67V10h18.67a2.326 2.326 0 012.33 2.33z"
  /><rect
    fill="#0078d4"
    rx="2.3333"
    height="28"
    width="28"
    y="10"
  /><path
    fill="#fff"
    d="M18.5851 18.8812H12.038v3.8286h6.1454v2.4537H12.038v3.9766h6.8961v2.4434H9.066v-15.167h9.5191z"
  /></svg>
</template>
<script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'AwsIcon',
    computed: {
      ...mapGetters({
        theme: 'theme',
      }),
      fill () {
        return this.theme === 'dark' ? '#fff' : '#252F3E';
      },
    },

  };
</script>
    <style type="text/css" scoped>
      .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FF9900;}
    </style>
