import { Notification } from './models';

export default {
  install: (app, { eventBus }) => {
    const notify = new NotificationService(eventBus);
    app.prototype.$notify = notify;
  },
};

export const CONSTANTS = {
  NOTIFY: 'notify',
};
class NotificationService {
  constructor (eventBus) {
    this.eventBus = eventBus;
  }

  /** @param {NotificationOptions} options */
  success (options) {
    this.notify(CONSTANTS.NOTIFY, new Notification('success', options));
  }

  /** @param {NotificationOptions} options */
  error (options) {
    this.notify(CONSTANTS.NOTIFY, new Notification('error', options));
  }

  /** @param {NotificationOptions} options */
  info (options) {
    this.notify(CONSTANTS.NOTIFY, new Notification('info', options));
  }

  /** @param {NotificationOptions} options */
  warning (options) {
    this.notify(CONSTANTS.NOTIFY, new Notification('warning', options));
  }

  /**
    * @param {string} eventName
    * @param {Notification} notification
  */
  notify (eventName, notification) {
    this.eventBus.$emit(eventName, notification);
  }
}
