var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{class:[
    'default-card',
    {
      'default-card_hovered default-card_hovered_bordered': _vm.isHovered,
      'default-card_activated': _vm.isActive,
    }
  ]},[_c('div',{staticClass:"flex-column-centered flex-gap-8"},[_c('default-avatar',{attrs:{"size":"xs","photo-link":_vm.profile.photo_link,"status-color":_vm.profileStatusColor}}),_c('i',{class:[
        'bx icon_size_16',
        _vm.isStarredProfile ? 'bxs-star color_yellow' : 'bx-star color_tertiary',
      ],on:{"click":function($event){$event.stopPropagation();return _vm.markProfile('Starred')}}})],1),_c('div',{staticClass:"overflow-hidden flex-grow-1"},[_c('div',{staticClass:"flex-row flex-gap-4"},[_c('p',{staticClass:"text_xs text_weight_accent text_ellipsis"},[_vm._v(" "+_vm._s(_vm.profile.full_name)+" ")]),(_vm.profile.wait_reasons.is_stuck)?_c('default-label',{attrs:{"size":"xs","label-name":"Pending","custom-type":"warning"}}):_vm._e()],1),_c('p',{staticClass:"card-dialogs__message mb-1"},[_c('span',{staticClass:"color_primary"},[_vm._v(" "+_vm._s(_vm.lastMessageTemplate.author + ': ')+" ")]),_vm._v(" "+_vm._s(_vm.lastMessageTemplate.message)+" ")]),(_vm.profile.labels.length)?_c('labels-list',{attrs:{"labels":_vm.profile.labels}}):_vm._e()],1),_c('div',{staticClass:"flex-column-centered flex-justify-content-between"},[_c('p',{staticClass:"text_xs color_tertiary"},[_vm._v(" "+_vm._s(_vm.profile.last_message?.created_at ? _vm.getFromNowUTC(_vm.profile.last_message?.created_at) : '')+" ")]),(_vm.profile.unread_count)?_c('div',{class:{ 'card-dialogs__message-status': _vm.profile.unread_count }}):_vm._e(),_c('default-tooltip',{staticClass:"flex-align-self-end",attrs:{"data-closable":"cardDialog","items":_vm.tooltipData,"list-position":"right","has-triangle":false},on:{"choose":_vm.tooltipHandler},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('default-button',{attrs:{"icon-name":"bx-dots-vertical-rounded","title-color":"color_secondary","hovered-title-color":"hovered_primary","form":"square","size":"s","has-padding":false}})]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }