<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div :class="['uikit-avatar', 'uikit-avatar_size_' + size]">
    <img
      loading="lazy"
      :src="setSrcImg(photoLink || '')"
      class="uikit-avatar__image"
      @error="setAltImg"
    >
    <div
      v-if="statusColor"
      class="uikit-avatar__status"
      :style="{ background: statusColor }"
    />
    <slot />
  </div>
</template>

<script>
  import { setAltImg } from '@/mixins/setAltImg';

  export default {
    name: 'DefaultAvatar',
    mixins: [setAltImg],
    props: {
      size: { // xxl, xl, l, m, s, xs, xxs, xxxs
        type: String,
        required: true,
      },
      photoLink: {
        type: String,
        default: '',
      },
      statusColor: {
        type: String,
        default: '',
      },
    },
  };
</script>
<style lang="scss">
  .uikit-avatar {
    position: relative;

    &_size {
      &_xxl {
        min-width: 72px;
        width: 72px;
        height: 72px;
      }

      &_xl {
        min-width: 64px;
        width: 64px;
        height: 64px;
      }

      &_l {
        min-width: 56px;
        width: 56px;
        height: 56px;
      }

      &_m {
        min-width: 48px;
        width: 48px;
        height: 48px;
      }

      &_s {
        min-width: 40px;
        width: 40px;
        height: 40px;
      }

      &_xs {
        min-width: 32px;
        width: 32px;
        height: 32px;
      }

      &_xxs {
        min-width: 24px;
        width: 24px;
        height: 24px;
      }

      &_xxxs {
        min-width: 16px;
        width: 16px;
        height: 16px;
      }

      &_xxl, &_xl {
        .uikit-avatar__status {
          width: 20px;
          height: 20px;
          border: 3px solid $cl-white;
        }
      }

      &_l, &_m {
        .uikit-avatar__status {
          width: 16px;
          height: 16px;
          border: 2px solid $cl-white;
        }
      }

      &_s, &_xs {
        .uikit-avatar__status {
          width: 12px;
          height: 12px;
          border: 2px solid $cl-white;
        }
      }

      &_xxs {
        .uikit-avatar__status {
          width: 8px;
          height: 8px;
          border: 1px solid $cl-white;
        }
      }

      &_xxxs {
        .uikit-avatar__status {
          width: 6px;
          height: 6px;
          border: 1px solid $cl-white;
        }
      }
    }

    &__image {
      overflow: hidden;
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &__status {
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 50%;
    }
  }
</style>
