var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"crm-switcher__select-crm"},[_c('div',{directives:[{name:"closable",rawName:"v-closable",value:({
      exclude: ['selectCrm'],
      handler: () => _vm.isSelectCrm = false,
      params: 'isSelectCrm',
    }),expression:"{\n      exclude: ['selectCrm'],\n      handler: () => isSelectCrm = false,\n      params: 'isSelectCrm',\n    }"}],staticClass:"crm-switcher__selected-crm",on:{"click":function($event){_vm.isSelectCrm = !_vm.isSelectCrm}}},[_c('img',{attrs:{"loading":"lazy","src":require(`@/assets/integrationCRM/${_vm.$route.params.integration}.svg`),"alt":"logo pipedrive"}}),_c('i',{staticClass:"crm-switcher__selected-crm-arrow bx bxs-down-arrow",class:{'crm-switcher__selected-crm-arrow_open': _vm.isSelectCrm}})]),_c('ul',{ref:"selectCrm",staticClass:"crm-switcher__select-items",class:{'crm-switcher__select-items_open': _vm.isSelectCrm,
             'crm-switcher__select-items_close': !_vm.isSelectCrm,
    }},_vm._l((_vm.crms),function(crm,i){return _c('li',{key:i,staticClass:"crm-switcher__select-item",on:{"click":function($event){return _vm.selectCrm(crm)}}},[_c('img',{attrs:{"loading":"lazy","src":require(`@/assets/integrationCRM/${crm}.svg`),"alt":`logo ${crm}`}})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }