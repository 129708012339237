<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    :class="[
      'uikit-label',
      'uikit-label_size_' + size,
      { 'cursor-pointer': hasButton },
    ]"
    :style="label ? labelStyle : customStyle"
  >
    <p>
      {{ label?.name || labelName }}
    </p>
    <i
      v-if="hasButton"
      class="bx bx-x icon_size_16"
    />
  </div>
</template>
<script>
  import variables from '@/styles/_variables.module.scss';

  export default {
    name: 'DefaultLabel',
    props: {
      label: {
        type: Object,
        default: null,
      },
      size: {
        type: String,
        required: true,
      },
      labelName: {
        type: String,
        default: '',
      },
      customType: {
        type: String,
        default: '',
      },
      hasButton: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      labelStyle () {
        return {
          background: this.label.color ? this.label.color + '1A' : '',
          color: this.label.color,
        };
      },
      customStyle () {
        return {
          background: variables[`cl-${this.customType}-bg`],
          color: variables[`cl-${this.customType}`],
        };
      },
    },
  };
</script>
<style lang="scss">
.uikit-label {
  @include flex-row-centered;
  border-radius: 4px;
  white-space:  nowrap;

  &_size {
    &_m {
      @include text_m;
      padding: 4px 8px;
    }

    &_s {
      @include text_s;
      padding: 2px 6px;
    }

    &_xs {
      @include text_xs;
      padding: 2px 6px;
    }
  }
}
</style>
