<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<!-- eslint-disable vue/no-v-html !-->
<template>
  <div class="preview-message">
    <div class="preview-message__container">
      <div
        class="preview-message__user-photo"
        :style="
          selectedCredential && selectedCredential.photo_link
            ? {
              'background-image': `url(${selectedCredential.photo_link})`,
            }
            : null
        "
      >
        <icon
          v-if="!(selectedCredential && selectedCredential.photo_link)"
          size="1.5x"
        />
      </div>
      <div class="preview-message__message-content">
        <div class="preview-message__header">
          <div class="preview-message__user-info">
            <span class="preview-message__user-info__name">{{ selectedCredential.full_name }}</span>
            <span class="preview-message__user-info__time">{{ formatAMPM() }}</span>
          </div>
        </div>
        <div
          class="preview-message__message"
          v-html="messageVideo"
        />
        <preview-video
          v-if="video && video._id"
          class="preview-message__video"
          :video="video"
        />
      </div>
    </div>
    <!-- <img loading="lazy" src="~@/assets/testMessagePreview.png" class="test"> -->
  </div>
</template>
<script>
  import '@fontsource/inter';
  import { mapGetters } from 'vuex';
  import { parseTime } from '@/mixins/parseTime';
  import PreviewVideo from '@/components/TemplatePage/PreviewVideo';

  export default {
    name: 'PreviewMessage',
    components: { PreviewVideo },
    mixins: [parseTime],
    props: {
      message: {
        type: String,
        default: '',
      },
      video: {
        type: Object,
        default: null,
      },
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      messageVideo () {
        if (this.video?._id) {
          const previewText = this.video?.preview_src ? this.video.preview_src : '{link to video}';
          return this.message.replace(`{video | ${this.video.name}}`, previewText);
        }
        return this.message;
      },
    },
  };
</script>
<style scoped lang="scss">
  .preview-message {
    display: flex;
    width: 100%;
    flex-flow: column;
    font-family: "Inter";
    &__container {
      display: flex;
    }
    &__header {
      display: flex;
    }
    &__user-photo {
      width: 60px;
      height: 60px;
      background: #f2f2f2;
      color: #c6c6c6;
      background-size: cover;
      border-radius: 50%;
      margin-bottom: 12px;
      display: grid;
      place-items: center;
      transition: 0.3s;
    }

    &__message-content {
      font-size: 14px;
      margin-left: 13px;
      max-width: calc(100% - 74px);
    }
    &__video {
      margin-top: 12px;
    }
    &__user-info {
      &__name {
        font-weight: bold;
        font-size: 16px;
      }

      &__time {
        font-size: 16px;
        color: #8C8C8C;
        margin-left: 4px;
        &::before {
          content: '\2022';
          padding-right: 4px;
          font-size: 16px;
        }
      }
    }

    &__message {
      margin-top: 10px;
      white-space: break-spaces;
    }
  }
  .test {
    position: absolute;
  }
</style>
