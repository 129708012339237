function setServiceBell (user, account, id = 'e2394e1f81b74eef8e6add957a147d0e') {
  // eslint-disable-next-line no-unused-expressions, no-var, no-sequences, no-undef
  const w = window;
  const d = document;
  function e (e, n) {
    w.ServiceBell.q = w.ServiceBell.q || [];
    w.ServiceBell.q.push([e, n]);
  }
  if (!w.ServiceBell) {
    const i = function (n) {
      for (let i = arguments.length, r = new Array(i > 1 ? i - 1 : 0), c = 1; c < i; c++) {
        r[c - 1] = arguments[c]; e(n, r);
      }
      [
        'init',
        'identify',
        'dial',
        'alert',
        'bookMeeting',
        'hide',
        'show',
        'expand',
        'collapse',
        'connect',
        'disconnect',
        'showPopup',
      ].forEach(
        function (r) {
          i[r] = function () {
            for (let i = arguments.length, r = new Array(i), c = 0; c < i; c++) {
              r[c] = arguments[c];
              e(n, r);
            }
          };
        });
    };
    w.ServiceBell = i;
  }
  const s = d.createElement('script');
  s.id = 'service-bell-script';
  s.src = 'https://cdn.servicebell.com/main.js';
  s.async = 1;
  const r = d.getElementsByTagName('script')[0]; r.parentNode.insertBefore(s, r);

  window.ServiceBell('init', id, { mode: 'iframe-jit' });
  window.ServiceBell('identify',
    user._id,
    {
      displayName: user.first_name + ' ' + user.last_name,
      email: user.email,
      avatar: user.avatar,
      link: account?._id ?? `${user.config.client_link}/campaigns/my-campaigns?space=${account._id}`,
    }
  );
}
export { setServiceBell };
