<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="score-menu">
    <score-stat-item
      v-for="stat in stats"
      :key="stat._id"
      :stat="stat"
      :message="message"
      :subject="subject"
    />
  </div>
</template>

<script>

  import ScoreStatItem from '@/components/updateWorkflow/performAction/menuFiles/ScoreStatItem';
  export default {
    name: 'ScoreMenu',
    components: { ScoreStatItem },
    mixins: [],
    props: {
      message: {
        type: String,
        default: '',
      },
      subject: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        stats: [
          {
            name: 'Word count',
            _id: 'wordCount',
          },
          {
            name: 'Question count',
            _id: 'questionCount',
          },
          {
            name: 'Reading level',
            _id: 'readingLevel',
          },
          {
            name: 'Positivity',
            _id: 'positivity',
          },
          {
            name: 'Spam word count',
            _id: 'spamCount',
          },
        ],
      };
    },
    computed: {
    },
    mounted () {
      if (['send_email', 'li_send_inmail'].includes(this.type)) {
        this.stats.unshift({
          name: 'Subject length',
          _id: 'subjectLength',
        });
      }
    },
    methods: {
    },
  };
</script>

<style lang="scss">
.score-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
</style>
