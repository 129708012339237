<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="score-stat-item">
    <div class="score-stat-item__diagram">
      <svg
        fill="none"
        class="score-stat-item__diagram-moving"
        width="60"
        height="60"
        viewBox="0 0 35 35"
      >
        <circle
          stroke="#F8F9FA"
          stroke-width="3"
          r="15.9"
          cx="50%"
          cy="50%"
        />
      </svg>
      <svg
        fill="none"
        class="score-stat-item__diagram-track"
        width="60"
        height="60"
        viewBox="0 0 35 35"
      >
        <circle
          stroke="var(--primary-cl)"
          stroke-width="3"
          stroke-dasharray="1000"
          stroke-linecap="round"
          :stroke-dashoffset="getRate"
          r="15.9"
          cx="50%"
          cy="50%"
        />
      </svg>
      <div class="score-stat-item__diagram-percent">
        {{ rate }}
      </div>
    </div>
    <span class="score-stat-item__text text_xs color_secondary">{{ stat.name }}<i class="bx bx-info-circle" /></span>
  </div>
</template>

<script>

  import { SPAM_WORDS } from '@/utils/workflows/spamWords';

  export default {
    name: 'ScoreStatItem',
    components: {},
    mixins: [],
    props: {
      stat: {
        type: Object,
        default: () => {},
      },
      message: {
        type: String,
        default: '',
      },
      subject: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        // rate: 50,
      };
    },
    computed: {
      splitEnter () {
        const text = this.message;
        const res = [];
        const test = text.replace(/<span>\s*<br>\s*<\/span>/g, '');
        const str = test.replace(/[\t\n\r\s*.\s*?\s*!\s*</p>]/gm, ' ').split(' ');
        str.map((s) => {
          const trimStr = s.trim();
          if (trimStr.length > 0) {
            res.push(trimStr);
          }
          return trimStr;
        });
        return res.filter(e => e !== '');
      },
      spamsRegexps () {
        const spamsRegexps = [];
        SPAM_WORDS.forEach(word => {
          const regexString = `${word}(:[^}]+)?`;
          spamsRegexps.push(new RegExp(regexString));
        });
        return spamsRegexps;
      },
      spamsLength () {
        return this.spamsRegexps.reduce((prev, r) => {
          const spamsName = this.message.match(new RegExp(r, 'gi'));
          if (spamsName) {
            prev = [...prev, ...spamsName];
          }
          return prev;
        }, []);
      },
      getRate () {
        const all = 1000;
        switch (this.stat._id) {
        case 'wordCount':
          return all - this.splitEnter.length;
        case 'questionCount':
          return all - (this.message.split('?').length - 1);
        case 'spamCount':
          return all - this.spamsLength.length;
        case 'subjectLength':
          return all - this.subject.length;
        default: {
          return all - this.rate;
        }
        }
      },
      rate () {
        switch (this.stat._id) {
        case 'wordCount':
          return this.splitEnter.length;
        case 'questionCount':
          return this.message.split('?').length - 1;
        case 'spamCount':
          return this.spamsLength.length;
        case 'subjectLength':
          return this.subject.length;
        default: {
          return 50;
        }
        }
      },
    },
  };
</script>

<style lang="scss">
.score-stat-item {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 4px;
  &__diagram {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
  }

  &__diagram-track {
    position: absolute;
    transform: rotate(-90deg);
  }
  &__diagram-moving {
    position: absolute;
    border-radius: 5px;
  }
  &__diagram-percent {
    font-size: 11px;
    line-height: 16px;
  }
  &__text {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}
</style>
