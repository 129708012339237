<template>
  <div class="segmented-control">
    <div
      ref="switch"
      class="segmented-control__switch"
      :style="`max-width: ${maxWidth || ''}`"
    >
      <div
        ref="toggle"
        class="segmented-control__toggle"
        :style="`margin-left: ${marginLeft}; width: ${width}`"
      />
      <div
        v-for="(button, i) in buttonOptions"
        :key="i"
        class="segmented-control__block"
        :class="{
          'segmented-control__block_active':
            button.value === chosenSegmentation,
        }"
        :style="`width: ${width}`"
        @click.stop="emit('change', button.value)"
      >
        <span class="text_xs"><i
          class="bx"
          :class="button.icon"
        />{{ button.text }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
// DefaultSegmentedControl
  import { computed } from 'vue';
  const emit = defineEmits(['change']);
  const props = defineProps({
    buttonOptions: {
      type: Array,
      default: () => [],
    },
    chosenSegmentation: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '',
    },
  });

  const width = computed(() => {
    if (activeBtnIdx.value === props.buttonOptions.length - 1) {
      return `calc(${100 / props.buttonOptions.length}% - (${
        2 * (activeBtnIdx.value + 1)
      }px + 4px))`;
    }
    return `calc(${100 / props.buttonOptions.length}% - ${
      2 * (activeBtnIdx.value + 1)
    }px)`;
  });

  const marginLeft = computed(() => {
    return `${(100 / props.buttonOptions.length) * activeBtnIdx.value}% `;
  });

  const activeBtnIdx = computed(() => {
    return props.buttonOptions.findIndex(
      (btn) => btn.value === props.chosenSegmentation
    );
  });
</script>
<style lang="scss">
.segmented-control {
  &__switch {
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    padding: 4px;
    gap: 2px;
    background-color: var(--bg-gray-04);
    border-radius: 8px;
  }
  &__toggle {
    position: absolute;
    transition: 0.5s;
    background: var(--background-color);
    box-shadow: 0px 2px 8px rgba(10, 32, 51, 0.12);
    border-radius: 6px;
    margin-left: 0;
    height: 24px;
  }

  &__block {
    transition: 0.5s;
    flex-grow: 1;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    z-index: 5;
    font-weight: 600;
    color: var(--color-text-tetriary);

    &_active {
      color: var(--text-color);
    }
  }
}
</style>
