/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const parseTime = {
  methods: {
    formatAMPM () {
      const date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours || 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      const strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    setTimer (time) {
      let s = parseInt((time / 1) % 60);
      let m = parseInt((time / (1 * 60)) % 60);
      let h = parseInt((time / (1 * 60 * 60)) % 24);

      h = h < 10 ? '0' + h : h;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (h > 0) {
        return h + ':' + m + ':' + s;
      } else {
        return m + ':' + s;
      }
    },
  },
};
