<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    :id="id"
    class="response"
  >
    <div class="response__text">
      <p class="response__title text">
        {{ name }}
      </p>
      <p class="response__texttemplate lines_2">
        {{ textTemplate }}
      </p>
    </div>
    <div
      class="icon-button right"
      @click.stop="$emit('edit', response)"
    >
      <edit-2-icon size="1.2x" />
    </div>
    <div
      class="delete-button icon-button"
      @click.stop="$emit('delete', id)"
    >
      <x-icon
        size="1.4x"
        class="delete-button__icon"
      />
    </div>
  </div>
</template>
<script>
  import { XIcon, Edit2Icon } from 'vue-feather-icons';
  export default {
    name: 'SavedResponse',
    components: {
      XIcon,
      Edit2Icon,
    },
    props: {
      response: {
        type: Object,
        default: () => {},
      },
      name: {
        type: String,
        default: '',
      },
      textTemplate: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        default: '',
      },
    },
    methods: {
      choose () {
        this.$emit('choose', this.textTemplate);
      },
      delete () {
        this.$emit('delete', this.id);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .icon-button{
    padding: 5px;
    border-radius: 6px;
  }
  .response {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 10px;
    padding: 10px;
    cursor: pointer;
    align-items: center;
    border-radius: 6px;
    &_active {
      background: var(--active-bg-cl);
    }
    &:hover{
      background: var(--active-bg-cl);
    }
    &__text {
      display: flex;
      flex-flow: column;
    }
    &__title {
      font-size:  1.33rem;
      font-weight: 400;
      margin: 0;
      margin-bottom: 2px;
    }
    &__texttemplate {
      font-size:  1rem;
      word-break: break-all;
      line-height: 13px;
      max-height: 26px;
      overflow: hidden;
    }

  }
</style>
