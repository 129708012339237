// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Directive } from 'vue';
import { TooltipAppearanceEnum, TooltipAlignEnum, TooltipPositionEnum } from '@/plugins/tooltip/enums';

/**
 * @typedef TooltipOptions
 * @type {Object}
 * @property {String} text
 * @property {TooltipAppearanceEnum} [appearance]
 * @property {TooltipAlignEnum} [align]
 * @property {Number} [minWidth]
 * @property {Number} [width]
 * @property {Number} [maxWidth]
 */

/**
 * @type {Directive<HTMLElement, TooltipOptions>}
 */
export const TooltipDirective = {
  inserted: (element, binding) => {
    element.setAttribute('data-v-tooltip', binding.value?.text || binding.value);

    element.classList.add('v-tooltip-parent');

    const appearance = getAppearanceModifier(binding.value?.appearance);
    element.classList.add(`v-tooltip_${appearance}`);

    const position = getPositionModifier(binding.modifiers);
    element.classList.add(`v-tooltip_${position}`);

    const align = getAlignModifier(binding.value?.align || TooltipAlignEnum.CENTER);
    element.classList.add(`v-tooltip_${position}_align-${align}`);

    binding.value?.minWidth && element.style.setProperty('--v-tooltip-min-width', binding.value?.minWidth + 'px');
    binding.value?.width && element.style.setProperty('--v-tooltip-width', binding.value?.width + 'px');
    binding.value?.maxWidth && element.style.setProperty('--v-tooltip-max-width', binding.value?.maxWidth + 'px');
  },
};

/**
 * @param {TooltipAppearanceEnum} appearance
 */
function getAppearanceModifier (appearance) {
  switch (appearance) {
    case TooltipAppearanceEnum.LIGHT:
      return TooltipAppearanceEnum.LIGHT;
    default:
      return TooltipAppearanceEnum.DARK;
  }
}

/**
 * @param {TooltipPositionEnum} appearance
 */
function getPositionModifier (modifiers) {
  switch (true) {
    case modifiers.top:
      return TooltipPositionEnum.TOP;
    case modifiers.bottom:
      return TooltipPositionEnum.BOTTOM;
    case modifiers.left:
      return TooltipPositionEnum.LEFT;
    case modifiers.right:
      return TooltipPositionEnum.RIGHT;
    default:
      return TooltipPositionEnum.TOP;
  }
}

/**
 * @param {TooltipAlignEnum} align
 */
function getAlignModifier (align) {
  if (!Object.values(TooltipAlignEnum).includes(align)) {
    throw new Error(`Wrong Enum: '${align}' is not allowed Enum.`)
  }
  return align;
}
