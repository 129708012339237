<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end-2">
    <modal
      :key="key"
      name="CrmAddPipelineModal"
      width="400px"
      height="auto"
      :adaptive="true"
      @closed="handleClose"
    >
      <div class="modal crm-deal-modal">
        <div
          class="crm-deal-modal__close-button"
        >
          <i
            class="bx bx-x"
            @click="handleClose"
          />
        </div>
        <div class="crm-deal-modal__main">
          <div class="crm-deal-modal__header">
            <h2 class="crm-deal-modal__title">
              {{
                isEdit
                  ? $t('pipelinesPage.modals.pipeline.titleEdit')
                  : $t('pipelinesPage.modals.pipeline.titleCreate')
              }}
            </h2>
          </div>
          <div class="crm-deal-modal__section">
            <default-input
              :value.sync="pipelineName"
              :placeholder="$t('pipelinesPage.modals.pipeline.inputPlaceholder')"
              size="m"
            />
          </div>
        </div>
        <div class="crm-deal-modal__buttons-block mt-2">
          <default-button
            size="m"
            :title="$t('actions.cancel')"
            color="neutral"
            form="rectangle"
            @action="handleClose"
          />
          <default-button
            size="m"
            :title="isEdit ? $t('actions.edit') : $t('actions.create')"
            color="primary"
            form="rectangle"
            @action="savePipeline"
          />
        </div>
      </div>
    </modal>
  </portal>
</template>

<script>
  import { loaderMixin } from '@/mixins/loaderMixin';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';

  export default {
    name: 'CrmAddPipelineModal',
    components: {
      DefaultInput,
      DefaultButton,
    },
    mixins: [loaderMixin],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      selectedPipeline: {
        type: Object,
        default: undefined,
      },
    },
    data () {
      return {
        key: 'CrmAddPipelineModal',
        pipelineName: '',
      };
    },

    computed: {
    },
    watch: {
      show (show) {
        if (show) {
          this.$modal.show('CrmAddPipelineModal');
          if (this.selectedPipeline?.name && this.isEdit) {
            this.pipelineName = this.selectedPipeline.name;
          }
        } else {
          this.$modal.hide('CrmAddPipelineModal');
          this.key = `${this.key} ${new Date().getTime()}`;
        }
      },
    },
    methods: {
      handleClose () {
        this.$emit('update:show', false);
      },
      savePipeline () {
        this.$emit('save-pipeline', this.pipelineName, this.isEdit);
      },
    },
  };
</script>

<style lang="scss">
.crm-deal-modal {
  transition: 0.8s;
  height: 100%;
  position: relative;
  background-color: var(--background-color);
  padding: 24px;
  border-radius: 8px;
  &__close-button {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0;
    margin: 0;
    top: 12px;
    right: 12px;
    border: none;
    outline: none;
    background: none;
    font-size: 24px;
    color: #919399;
  }
  &__main {
    display: flex;
    flex-flow: column;
    gap: 24px;
  }
  &__header {
    display: flex;
    gap: 8px;
    align-items: flex-end;
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
    &_link {
      color: var(--primary-cl);
      cursor: pointer;
      &:hover {
      opacity: 0.7;
    }
    }
    &_margin-left {
      margin-left: auto;
    }
    &_delete {
      cursor: pointer;
      transition: 100ms;
      opacity: 0;
    }
  }
}
</style>
