import { render, staticRenderFns } from "./ConfirmChangesModal.vue?vue&type=template&id=620fff7b&scoped=true&"
import script from "./ConfirmChangesModal.vue?vue&type=script&lang=js&"
export * from "./ConfirmChangesModal.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmChangesModal.vue?vue&type=style&index=0&id=620fff7b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../temp_divo-ui/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620fff7b",
  null
  
)

export default component.exports