/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import store from '@/store';
const SettingsPage = () => import('@/views/settingsPage/SettingsPage');
const SettingsAccountPage = () => import('@/views/settingsPage/SettingsAccountPage');
const SettingsLimitsPage = () => import('@/views/settingsPage/SettingsLimitsPage');
const SettingsLocationPage = () => import('@/views/settingsPage/SettingsLocationPage');
const SettingsBlacklistPage = () => import('@/views/settingsPage/SettingsBlacklistPage');
const SettingsConnectionPage = () => import('@/views/settingsPage/SettingsConnectionPage');

const isMobile = store.getters['mobile/isMobile'];

const meta = {
  layout: !isMobile ? 'DefaultLayout' : 'MobileLayout',
  hasSidebar: true,
};

const settings = [
  {
    path: '/settings',
    name: 'settings',
    component: SettingsPage,
    redirect: { name: 'account' },
    meta,
    children: [
      {
        path: '/settings/account',
        name: 'account',
        component: SettingsAccountPage,
        meta,
      },
      {
        path: '/settings/limits',
        name: 'limits',
        component: SettingsLimitsPage,
        meta,
      },
      {
        path: '/settings/location',
        name: 'location',
        component: SettingsLocationPage,
        meta,
      },
      {
        path: '/settings/blacklist',
        name: 'blacklist',
        component: SettingsBlacklistPage,
        meta,
      },
      {
        path: '/settings/connection',
        name: 'connection',
        component: SettingsConnectionPage,
        meta,
      },
    ],
  },
];

export default settings;
