<template>
  <div>
    <default-button
      icon-name="bx-filter-alt"
      color="tertiary"
      form="square"
      size="m"
      :counter="counter"
      @action="defaultModal.show = true"
    />
    <default-modal
      :show.sync="defaultModal.show"
      :name="modalName"
      width="800"
      height="500"
    >
      <filters-block-modal
        :filters="filters"
        @update-filters="$emit('update-filters', $event)"
        @close="defaultModal.show = false"
      />
    </default-modal>
  </div>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import DefaultModal from '@/components/base/DefaultModal';
  import FiltersBlockModal from '@/components/base/filtersBlock/FiltersBlockModal';

  export default {
    name: 'FiltersBlock',
    components: {
      DefaultButton,
      DefaultModal,
      FiltersBlockModal,
    },
    props: {
      filters: {
        type: Object,
        required: true,
      },
      // настраивает NAME модалки для ситуаций, когда два блока на одной странице
      modalName: {
        type: String,
        default: 'filters-block',
      },
    },
    data () {
      return {
        defaultModal: {
          show: false,
          component: {
            value: '',
            title: '',
          },
        },
      };
    },
    computed: {
      counter () {
        return Object.values(this.filters.chosenData).reduce((acc, v) => {
          acc = acc + v.length;
          return acc;
        }, 0);
      },
    },
  };
</script>
