/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const BillingOverviewPage = () => import('@/components/billingPage/BillingOverviewPage');
const BillingTransactionsPage = () => import('@/components/billingPage/BillingTransactionsPage');
const BillingSubscriptionsPage = () => import('@/components/billingPage/BillingSubscriptionsPage');
const BillingCreditsHistoryPage = () => import('@/components/billingPage/BillingCreditsHistoryPage');
const BillingPage = () => import('@/views/BillingPage');

const meta = {
  layout: 'DefaultLayout',
  hasSidebar: true,
};

const billing = [
  {
    path: '/billing',
    name: 'billing',
    component: BillingPage,
    redirect: { name: 'overview' },
    meta,
    children: [
      {
        path: 'overview',
        name: 'overview',
        component: BillingOverviewPage,
        meta,
      },
      {
        path: 'transactions',
        name: 'transactions',
        component: BillingTransactionsPage,
        meta,
      },
      {
        path: 'subscriptions',
        name: 'subscriptions',
        component: BillingSubscriptionsPage,
        meta,
      },
      {
        path: 'credits-history',
        name: 'creditsHistory',
        component: BillingCreditsHistoryPage,
        meta,
      },
    ],
  },
];

export default billing;
