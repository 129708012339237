/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const SetSpacePage = () => import('@/views/spacePage/SetSpacePage');
const UseSpacePage = () => import('@/views/spacePage/UseSpacePage');
const CreateSpace = () => import('@/views/spacePage/CreateSpacePage');
const JoinSpace = () => import('@/views/spacePage/JoinToSpace');
const AcceptInvitePage = () => import('@/views/spacePage/AcceptInvitePage');
const SpacePage = () => import('@/views/spacePage/spacePage');

const space = [
  {
    path: '/space',
    name: 'space-page',
    redirect: { name: 'set-space' },
    component: SpacePage,
    meta: {
      layout: 'ClearLayout',
    },

    children: [
      {
        path: 'set-space',
        name: 'set-space',
        component: SetSpacePage,
        meta: {
          layout: 'ClearLayout',
        },
      },
      {
        path: 'use-space',
        name: 'use-space',
        component: UseSpacePage,
        meta: {
          layout: 'ClearLayout',
        },
      },
      {
        path: 'join',
        name: 'join-space',
        component: JoinSpace,
        meta: {
          layout: 'ClearLayout',
        },

      },
      {
        path: 'accept-invite',
        name: 'accept-invite',
        component: AcceptInvitePage,
        meta: {
          layout: 'ClearLayout',
        },
      },
      {
        path: 'create',
        name: 'create-space',
        component: CreateSpace,
        meta: {
          layout: 'ClearLayout',
        },
      },
      {
        path: '*',
        redirect: { name: 'set-space' },
      },
      // ...остальные вложенные маршруты
    ],
  },
];

export default space;
