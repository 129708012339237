/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const CreateWorkspacePage = () => import('@/views/workspaces/CreateWorkspacePage');

const settings = [
  {
    path: '/workspace/add',
    name: 'add-workspace',
    component: CreateWorkspacePage,
    meta: {
      layout: 'DefaultLayout',
      hasSidebar: true,
    },
  },
];
export default settings;
