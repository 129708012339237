/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function getTemplatesMarketplace (filters, q = '') {
  const data = await useHttp(
    '/templates/marketplace/fetch',
    'GET',
    null,
    {
      types: JSON.stringify(filters?.types || []),
      q,
    }
  );
  return data.payload;
};

async function addTemplateFromMarketplace (marketplaceId, marketplace) {
  const data = await useHttp(
    `/templates/marketplace/${marketplaceId}/add_to_user`,
    'POST',
    marketplace,
    null,
    true
  );
  return data.payload;
}

async function addTemplateToMarketplace (templateId) {
  const data = await useHttp(
    `/templates/${templateId}/add_to_marketplace`,
    'POST',
    null,
    true
  );
  return data.payload;
}

export { getTemplatesMarketplace, addTemplateFromMarketplace, addTemplateToMarketplace };
