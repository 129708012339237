<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    v-if="!isLabelsShort"
    class="labels"
  >
    <p
      v-for="(label, i) in labels"
      :key="i"
      class="label"
      :style="{
        background: label.color + '1A',
        color: label.color,
      }"
      @click="$emit('click-label', label)"
    >
      {{ label.name }}
    </p>
  </div>
  <div
    v-else
    class="labels"
  >
    <p
      v-for="(label, i) in labels.slice(0, maxShownLabels)"
      :key="i"
      class="label"
      :style="{
        background: label.color + '1A',
        color: label.color,
      }"
      @click="$emit('click-label', label)"
    >
      {{ label.name }}
    </p>
    <span
      v-if="labels.length - maxShownLabels > 0"
      class="label__text"
    >
      +{{ labels.length - maxShownLabels }}</span>
  </div>
</template>
<script>
  export default {
    name: 'ProfileLabels',
    props: {
      labels: {
        type: Array,
        default: () => [],
      },
      isLabelsShort: {
        type: Boolean,
        default: false,
      },
      maxShownLabels: {
        type: Number,
        default: 3,
      },
    },
    computed: {
      // Remove archived and starred labels
      // profileLabels () {
      //   return this.labels.filter(label => label.type !== 'global');
      // },
    },
  };
</script>
<style lang="scss" scoped>
.labels {
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  gap: 4px;
  white-space: nowrap;
}
.label {
  color: #fff;
  padding: 0px 6px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 20px;

  &__text {
    color: var(--secondary-text-color);
    font-size: 13px;
    line-height: 20px;
  }
}
</style>
