<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="lyne-menu">
    <div class="lyne-menu__container">
      <template v-for="column in templates">
        <div
          v-if="column._id === 'lyne'"
          :key="column._id"
          class="lyne-menu__column"
        >
          <div
            class="lyne-menu__items-container"
          >
            <template v-for="template in column.data">
              <span
                v-if="!template.disabled"
                :key="template.value"
                class="lyne-menu__item lyne-menu__text color_tertiary text_s"
                @click="addTemplateToArea(template)"
              >
                <i class="bx bx-code-curly" />
                {{ template.value }}
              </span>
            </template>
          </div>
        </div>
      </template>
      <div class="lyne-menu__items-desc text_s color_secondary">
        {{ chosenTemplate.text }}
      </div>
    </div>
  </div>
</template>
<script>
  import { templatesMixin } from '@/mixins/templatesMixin';
  import { TEMPLATES } from '@/utils/workflows/templates';

  import { mapGetters } from 'vuex';

  export default {
    name: 'LyneMenu',
    components: {
    },
    mixins: [templatesMixin],
    props: {
      placeholdersToSubject: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        templates: [...TEMPLATES.value],
        chosenTemplate: '',
      };
    },
    computed: {
      ...mapGetters({
        user: 'user/user',
      }),
    },
    methods: {
      addTemplateToArea (template) {
        this.chosenTemplate = template;
        if (!template.disabled) {
          const templateStr = template.fallback ? `{${template.value}:FALLBACK}` : `{${template.value}}`;
          this.$emit('add-template-to-area', templateStr, template.space);
        }
      },
    },
  };
</script>
<style lang="scss">
.lyne-menu {
  display: flex;
  flex-flow: column;
  gap: 8px;
  &__container {
    display: flex;
    gap: 36px;
  }
  &__column {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }
  &__items-container {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }
  &__item {
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;
    width: fit-content;
    background: var(--neutral-50);
    &:hover {
      opacity: 0.7;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
</style>
