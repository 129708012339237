<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="flex-row flex-gap-4 text_xs">
    <p>
      <span
        v-if="credentialFullName"
        class="color_primary"
      >
        {{ credentialFullName }}
      </span>
      <span
        v-else
        class="color_primary"
      >
        {{ $t('campaignsPage.campaign.header.info.percentCemplete', { percent: getPercent }) }}
      </span>
    </p>
    <p class="color_tertiary text_nowrap">
      &bull; {{ getParseDate(workflow.created_at) }}
    </p>
    <p class="color_tertiary text_nowrap">
      &bull; {{ $t('campaignsPage.campaign.header.info.stepsCount', { stepsCount: workflow.sequence_length }) }}
    </p>
    <p
      v-if="workflow.settings?.is_enabled"
      class="color_tertiary text_nowrap"
    >
      &bull;  <span class="text_bold">{{ workflow.settings.max_requests }}</span> max con. req./day
    </p>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { getParseDate } from '@/utils/workflows/utils';
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const moment = require('moment');

  export default {
    name: 'WorkflowInfo',
    props: {
      /** @type {import('vue').PropType<base.workflow.WorkflowsPage>} */
      workflow: {
        type: Object,
        required: true,
      },
      credentialFullName: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      getPercent () {
        return Math.floor(this.workflow.statistics.rates.success_rate.value * 100);
      },
    },
    methods: {
      getParseDate (date) {
        return getParseDate(date);
      },
      getDate (date) {
        const myTimeZone = new Date().getTimezoneOffset();
        const momentDate = this.selectedCredential.ui_pc_timezone
          ? moment(new Date(new Date(date) - myTimeZone * 1000 * 60))
          : moment(date).utcOffset(
            this.selectedCredential.timezone_offset - myTimeZone
          );
        return momentDate.format('YYYY-MM-DD h:mm a');
      },
    },
  };
</script>
