/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const addTemplateMixin = {
  methods: {
    addTemplateToArea: function (message, template, ref = 'messageArea', refNode) {
      let cursorStartPos;
      let cursorEndPos;
      if (ref) {
        cursorStartPos = this.$refs[ref]?.$el ? this.$refs[ref].$el.selectionStart : this.$refs[ref].selectionStart;
        cursorEndPos = this.$refs[ref]?.$el ? this.$refs[ref].$el.selectionEnd : this.$refs[ref].selectionEnd;
        setTimeout(() => {
          cursorStartPos += template.length + 1;
          this.$refs[ref].$el
            ? this.$refs[ref].$el.selectionStart = this.$refs[ref].$el.selectionEnd = cursorStartPos
            : this.$refs[ref].selectionStart = this.$refs[ref].selectionEnd = cursorStartPos;
        }, 10);
      } else {
        cursorStartPos = refNode.selectionStart;
        cursorEndPos = refNode.selectionEnd;
      }
      let cursorMessage = message.substring(0, cursorStartPos);
      if (!cursorMessage.endsWith(' ')) {
        cursorMessage += ' ';
      }
      return cursorMessage + template + message.substring(cursorEndPos, message.length);
    },
  },
};
