<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="template-unlink-menu">
    <div class="template-unlink-menu__item">
      <div class="template-unlink-menu__body">
        <span class="text_s ">
          {{ $t('campaignsPage.editor.steps.modals.stepEdit.menu.templates.buttons.unlink.title') }}
        </span>
        <span class="text_xs color_secondary">
          {{ $t('campaignsPage.editor.steps.modals.stepEdit.menu.templates.buttons.unlink.info') }}
        </span>
      </div>
      <default-button
        class="margin-left template-unlink-menu__button"
        :title="$t('campaignsPage.editor.steps.modals.stepEdit.menu.templates.buttons.unlink.title')"
        color="none"
        title-color="link"
        size="s"
        @action="$emit('unlink-template', template)"
      />
    </div>
    <div class="template-unlink-menu__item">
      <div class="template-unlink-menu__body">
        <span class="text_s">
          {{ $t('campaignsPage.editor.steps.modals.stepEdit.menu.templates.buttons.edit.title') }}
        </span>
        <span class="text_xs color_secondary">
          {{ $t('campaignsPage.editor.steps.modals.stepEdit.menu.templates.buttons.edit.info') }}
        </span>
      </div>
      <default-button
        class="margin-left template-unlink-menu__button"
        :title="$t('campaignsPage.editor.steps.modals.stepEdit.menu.templates.buttons.edit.title')"
        color="none"
        title-color="link"
        size="s"
        @action="$emit('edit-template', template)"
      />
    </div>
  </div>
</template>
<script>

  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  export default {
    name: 'TemplateUnlinkMenu',
    components: {
      DefaultButton,
    },
    props: {
      template: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
      };
    },
    methods: {
    },
  };
</script>
<style lang="scss">
.template-unlink-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background: rgba(0, 6, 15, 0.24);
&__item {
  background: var(--table-bg);
  padding: 12px;
  display: flex;
  flex-flow: column;
  max-width: 340px;
  height: 140px;
  border-radius: 8px;
}
  &__body {
    gap: 8px;
    display: flex;
    flex-flow: column;
  }
  &__button {
    margin-top: auto;
  }
}
</style>
