/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getScenario } from '@/api/scenario';
let listLoaded = false;

const state = () => ({
  scenarioList: [],
});

const getters = {
  scenarioList: state => {
    return state.scenarioList;
  },
};

const mutations = {
  SET_SCENARIO_LIST (state, payload) {
    if (!listLoaded) {
      state.scenarioList = payload;
      listLoaded = true;
    }
  },
  UPDATE_SCENARIO (state, payload) {
    const scenarioList = state.scenarioList.slice(0);
    const existingIndex = scenarioList.findIndex(template => template._id === payload._id);
    if (state.scenarioList._id === payload._id) {
      state.scenarioList = payload;
    }
    if (existingIndex > -1) {
      scenarioList.splice(existingIndex, 1, payload);
    } else {
      scenarioList.push(payload);
    }
    state.scenarioList = scenarioList;
  },
  DELETE_SCENARIO (state, payload) {
    state.scenarioList = state.scenarioList.filter((scenario) => {
      return scenario._id !== payload._id;
    });
  },
  ADD_SCENARIO (state, payload) {
    state.scenarioList.unshift(payload);
  },
};

const actions = {
  async SET_SCENARIO_LIST ({ commit }, payload) {
    const scenario = payload || await getScenario();
    commit('SET_SCENARIO_LIST', scenario);
    return scenario;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
