<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div>
    <h1 class="text_size_l text_weight_accent mb-2">
      {{ title }}
    </h1>
    <textarea-autosize
      ref="messageArea"
      :value="message"
      :class="[
        'ui-kit-input__container mb-2',
        'text_' + size,
        { 'ui-kit-input__container_active': isActivated },
      ]"
      :style="padding"
      :min-height="minHeight"
      :max-height="maxHeight"
      :placeholder="placeholder"
      rows="1"
      @focus="active = true"
      @blur="active = false"
      @input="input"
    />
    <templates-item
      :has-button="false"
      @addTemplate="addTemplate"
    />
  </div>
</template>
<script>
  import TemplatesItem from '@/components/updateWorkflow/TemplatesItem';
  import { addTemplateMixin } from '@/mixins/addTemplateMixin.js';
  export default {
    name: 'ConstructorTextrea',
    components: {
      TemplatesItem,
    },
    mixins: [addTemplateMixin],
    props: {
      title: {
        type: String,
        default: '',
      },
      message: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: 's', // 'xs', 's'
      },
      isActivated: {
        type: Boolean,
        default: false,
      },
      minHeight: {
        type: Number,
        default: 0,
      },
      maxHeight: {
        type: Number,
        default: 0,
      },
      placeholder: {
        type: String,
        default: '',
      },
    },
    computed: {
      padding () {
        switch (this.size) {
        case 'xs':
          return 'padding: 8px 12px;';
        case 's':
          return 'padding: 10px 12px;';
        default:
          return 'padding: 8px 12px;';
        }
      },
    },
    methods: {
      addTemplate (template) {
        this.$emit('update', this.addTemplateToArea(this.message, template));
      },
      input (v) {
        this.$emit('update', v);
      },
    },

  };
</script>
