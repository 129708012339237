<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div>
    <button
      v-for="button in list"
      :key="button.value"
      :class="getButtonClasses(button.value)"
      :style="buttonStyle"
      @click="selectHandler(button.value)"
    >
      <i
        v-if="button.icon"
        :class="['bx icon_size_16', button.icon]"
      />
      <span
        v-if="button.name"
        class="text text_xs text_weight_accent"
      >{{ button.name }}</span>
    </button>
  </div>
</template>
<script>
  export default {
    name: 'CustomSwitchersList',
    props: {
      buttonType: {
        type: String,
        default: 'rectangle', // circle, rectangle
      },
      list: {
        type: Array,
        required: true,
      },
      selected: {
        type: String,
        default: '',
      },
      buttonStyle: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      getButtonClasses (buttonValue) {
        return [
          'default-switcher__button',
          'default-switcher__button_' + this.buttonType,
          'default-switcher__button_' + this.buttonType + '_' + (this.selected === buttonValue ? 'active' : ''),
        ];
      },
      selectHandler (value) {
        this.$emit('update:selected', value);
        this.$emit('select', value);
      },
    },
  };
</script>
<style lang="scss">
.default-switcher {
  &__button {
    @include default-button;
    gap: 4px;
    height: 100%;

    &_rectangle {
      border-radius: $border-radius-small;

      &_active {
        background: $cl-white;
      }
    }

    &_circle {
      border-radius: 100px;
      box-shadow: 0 2px 8px rgba(35, 44, 96, 0.12);
      background: var(--background-color);

      &_active {
        background: var(--primary-cl);
        color: $cl-white;
        box-shadow: none;
      }
    }
  }
}
</style>
