/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const ContenteditableMixin = {
  data () {
    return {
      range: null,
      cursorStartPos: 0,
      cursorEndPos: 0,
      templateInput: false,
    };
  },
  methods: {
    handleInput (ref = 'messageArea') {
      const root = this.$refs[ref].$el || this.$refs[ref];
      if (!root.innerText) {
        root.innerHTML = '';
      }
      if (
        root.contains(window.getSelection().baseNode) &&
        !this.templateInput
      ) {
        this.range = window.getSelection().getRangeAt(0);
        this.cursorStartPos = this.range.startOffset;
        this.cursorEndPos = this.range.endOffset;
      }
    },
    saveRange () {
      this.range = window.getSelection().getRangeAt(0);
    },
    addTemplate (template, ref = 'messageArea') {
      this.templateInput = true;

      // ДАЛЬШЕ БОГА НЕТ
      const html = `<span>${template}</span>`;
      if (!this.range) {
        const range = new Range();
        range.setStart(this.$refs[ref].$el || this.$refs[ref], 0);
        range.setEnd(this.$refs[ref].$el || this.$refs[ref], 0);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        this.range = range;
      }
      // IE9 and non-IE
      this.range.deleteContents();
      const sel = window.getSelection();

      // Range.createContextualFragment() would be useful here but is
      // non-standard and not supported in all browsers (IE9, for one)
      const el = document.createElement('div');
      el.innerHTML = html;
      const frag = document.createDocumentFragment();
      let node;
      let lastNode;
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      this.range.insertNode(frag);

      // Preserve the selection
      if (lastNode) {
        const range = this.range.cloneRange();
        range.setStartAfter(lastNode);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        this.range = range;
      }

      setTimeout(() => {
        this.templateInput = false;
      });
    },

    handleQuillChange ({ quill }) {
      if (
        quill.root.contains(window.getSelection().baseNode) &&
        !this.templateInput
      ) {
        this.range = window.getSelection().getRangeAt(0);
        this.cursorStartPos = this.range.startOffset;
        this.cursorEndPos = this.range.endOffset;
      }
    },
    handleQuillClick () {
      this.range = window.getSelection().getRangeAt(0);
      this.cursorStartPos = this.range.startOffset;
      this.cursorEndPos = this.range.endOffset;
    },

    addTemplatetoQuill (template) {
      this.templateInput = true;

      // ДАЛЬШЕ БОГА НЕТ

      if (!this.range) {
        const root = this.$refs.quillBlock.quill.root;
        const range = new Range();
        range.setStartAfter(root.lastChild);
        range.setEndAfter(root.lastChild);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        this.range = range;
      }
      const lastChild = this.range.commonAncestorContainer.lastChild;
      let container;
      if (lastChild) {
        if (lastChild.tagName !== 'BR') {
          container = this.range.commonAncestorContainer.lastChild;
        } else {
          container = this.range.commonAncestorContainer;
        }
      } else {
        container = this.range.commonAncestorContainer;
      }

      const text = container.textContent;
      container.textContent =
        text.substring(0, this.cursorStartPos) +
        template +
        text.substring(this.cursorEndPos, text.length);
      const range = new Range();
      container = container.lastChild ? container.lastChild : container;
      range.setStart(container, this.cursorEndPos + template.length);
      range.setEnd(container, this.cursorEndPos + template.length);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      this.cursorStartPos = range.startOffset;
      this.cursorEndPos = range.endOffset;
      this.range = range;
      setTimeout(() => {
        this.templateInput = false;
      });
    },
  },
};
