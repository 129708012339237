<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="uikit-tooltip">
    <div class="uikit-tooltip__inner">
      <div
        ref="showListBtn"
        :data-closable="dataClosable"
        @click="showListTooltip"
      >
        <slot name="default" />
      </div>
      <div
        v-show="showBlocks[dataClosable]"
        :class="[
          'uikit-tooltip__list',
          'uikit-tooltip__list_' + listPosition,
          { 'uikit-tooltip__list_fulled': isFulled },
        ]"
        :style="{
          maxHeight: maxHeight ? maxHeight + 'px' : maxHeight,
          width: listWidth,
        }"
      >
        <slot
          class="uikit-tooltip__body"
          name="body"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  export default {
    name: 'DefaultTooltipBlock',
    extends: DefaultTooltip,
    mounted () {
    },
  };
</script>
