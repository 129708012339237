/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getConfig } from '@/api/AdminMethods';

const state = () => ({
  config: null,
});

const getters = {
  config: state => {
    return state.config || JSON.parse(window.localStorage.getItem('config'));
  },
};

const mutations = {
  SET_CONFIG (state, payload) {
    state.config = payload;
  },

};

const actions = {
  async SET_CONFIG ({ commit }, payload) {
    const config = await getConfig();
    window.localStorage.setItem('config', JSON.stringify(config));
    commit('SET_CONFIG', payload || config);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
