/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';
async function deleteMessage (id, credentialsId) {
  const data = await useHttp(
    `/messages/${id}/`,
    'DELETE',
    null,
    {
      credentials_id: credentialsId,
    }
  );

  return data.payload;
}

async function markImportant (id, isMe = false, isImportant = false) {
  const data = await useHttp(
    `/integrations/messages/${id}/important`,
    'POST',
    {
      is_me: isMe,
      is_important: isImportant,
    },
    null,
    true

  );

  return data.payload;
}

export { deleteMessage, markImportant };
