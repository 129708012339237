var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{class:[
    'default-card',
    {
      'default-card_hovered default-card_hovered_neutral': _vm.isHovered,
      'default-card_activated': _vm.isActive,
    }
  ]},[_c('div',{staticClass:"overflow-hidden"},[_c('p',{staticClass:"text_weight_accent text_ellipsis mb-1 text_s"},[_vm._v(" "+_vm._s(_vm.workflow.name)+" ")]),_c(_setup.MyWorkflowInfo,{attrs:{"workflow":_vm.workflow,"credential-full-name":_setup.selectedCredential.full_name}})],1),_c(_setup.DefaultLabel,{staticClass:"flex-align-self-center",attrs:{"size":"xs","custom-type":_setup.statusLabel.type,"label-name":_setup.statusLabel.name}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }