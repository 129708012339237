/* eslint-disable camelcase */
import { useHttp } from '@/middleware/httpMiddleware';

async function getScenario () {
  const data = await useHttp(
    '/scenarios/',
    'GET',
    null
  );
  return data.payload;
}

async function createScenario (name, actions, duration, video_settings) { // дополнительная обработка
  const data = await useHttp(
    '/scenarios/',
    'POST',
    {
      name,
      actions,
      duration,
      video_settings,
    },
    null,
    true
  );
  return data.payload;
}
async function recordPreview (scenarioId, credentialsId) {
  const data = await useHttp(
    `/scenarios/${scenarioId}/preview`,
    'POST',
    {
      credentials_id: credentialsId,
    },
    null,
    true
  );
  return data.payload;
}

async function getVideo (scenarioId) {
  const data = await useHttp(
    `/scenarios/${scenarioId}/video`,
    'GET',
    null
  );
  return data.payload;
}

async function deleteVideo (scenarioId) {
  const data = await useHttp(
    `/scenarios/${scenarioId}`,
    'DELETE',
    null
  );
  return data.payload;
}

async function updateScenario (scenarioId, actions, duration, name, video_settings) {
  const data = await useHttp(
    `/scenarios/${scenarioId}`,
    'PUT',
    {
      actions,
      name,
      video_settings,
      duration,
    },
    null,
    true
  );
  return data.payload;
}

async function getInfo (videoId) {
  const data = await useHttp(
    `/videos/${videoId}/info`,
    'GET',
    null,
    null,
    true
  );
  return data.payload;
}

async function getPreviewTemplate (
  credentialsId,
  {
    title_template,
    description_template,
    call_to_action_title,
    calendar_link,
  }) {
  const data = await useHttp(
    '/scenarios/preview',
    'GET',
    null,
    {
      credentials_id: credentialsId,
      title_template,
      description_template,
      call_to_action_title,
      calendar_link,
    }
  );
  return data.payload;
}

export {
  getScenario,
  createScenario,
  getVideo,
  deleteVideo,
  updateScenario,
  getInfo,
  recordPreview,
  getPreviewTemplate
};
