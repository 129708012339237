export const getStream = async () => {
  try {
    return await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
      facingMode: 'user',
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach((device) => {
          // eslint-disable-next-line no-console
          console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(`${err.name}: ${err.message}`);
      });
  }
};
