<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<!-- eslint-disable vue/no-v-html !-->
<template>
  <div class="preview-message">
    <div class="preview-message__header">
      <div class="preview-message__info">
        <div
          class="preview-message__user-photo"
          :style="
            selectedCredential && selectedCredential.photo_link
              ? {
                'background-image': `url(${selectedCredential.photo_link})`,
              }
              : null
          "
        >
          <icon
            v-if="!(selectedCredential && selectedCredential.photo_link)"
            size="1.5x"
          />
        </div>
        <div>
          <div class="preview-message__user-info">
            <a class="preview-message__user-info_name">
              {{ selectedCredential.full_name }}
            </a>
            <span class="preview-message__user-info_position">
              {{ selectedCredential.position }}
            </span>
            <a class="preview-message__user-info_member-insights">
              <img loading="lazy"
                src="~@/assets/previewImages/rings.svg"
              >
              <span>{{ selectedCredential.full_name }} and 9 others</span>
            </a>
          </div>
        </div>
      </div>
      <div class="preview-message__button-group">
        <button class="btn-control btn_ignore">
          Ignore
        </button>
        <button class="ml-2 btn-control btn_accept">
          Accept
        </button>
      </div>
    </div>
    <div
      v-if="message"
      class="preview-message__message-container"
    >
      <div class="preview-message__message-icon">
        <img loading="lazy"
          class="mercado-match"
          src="~@/assets/previewImages/dots.svg"
        >
      </div>
      <span
        class="preview-message__message"
        v-html="message"
      />
      <div class="preview-message__anywhere-btn">
        <button class="btn-control btn-reply mt-1">
          Reply to {{ selectedCredential.full_name }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import '@fontsource/inter';
  import { mapGetters } from 'vuex';

  export default {
    name: 'PreviewConnect',
    props: {
      message: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
    },
    mounted () {
      window.addEventListener(
        'resize',
        event => {
        // some code
        },
        false
      );
    },
  };
</script>
<style scoped lang="scss">
.preview-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  font-family: 'Inter';

  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__info {
    display: flex;
  }
  &__user-photo {
    width: 72px;
    height: 72px;
    min-width: 72px;
    box-sizing: border-box;
    background: #f2f2f2;
    color: #c6c6c6;
    background-size: cover;
    border-radius: 50%;
    margin-bottom: 12px;
    display: grid;
    place-items: center;
    transition: 0.3s;
  }

  &__message-content {
    margin-left: 13px;
    max-width: calc(100% - 74px);
  }
  &__user-info {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    &_name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      font-weight: bold;
      font-size: 16px;
    }
    &_position {
      margin-top: 2px;
      color: #666666;
      font-size: 11px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    &_member-insights {
      font-size: 11px;
      color: #666666;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      & img {
        margin-top: 1px;
        margin-right: 5px;
        width: 12px;
      color: #666666;
      }
    }
  }

  &__button-group {
    display: flex;
    margin-top: 15px;
  }
  .btn-control {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #00000099;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    font-family: inherit;
    background: none;
    font-weight: 600;
    font-size: 16px;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 167ms;
  }
  .btn_ignore {
    width: 64px;
    height: 32px;
    &:hover {
      background-color: #00000014;
      color: #00000099;
    }
  }
  .btn_accept {
    width: 82px;
    height: 32px;
    border-radius: 1.6rem;
    color: #0a66c2;
    box-shadow: inset 0 0 0 1px #0a66c2;
    &:hover {
      background-color: #70b5f933;
      box-shadow: inset 0 0 0 2px #0a66c2;
    }
  }
  .btn-reply {
    padding: 5px 10px;
    font-size: 14px;
    &:hover {
      background-color: #00000014;
      color: #00000099;
    }
  }
  &__message-container {
    margin-left: 82px;
    padding: 8px 12px;
    padding-right: 30px;
    border: 2px solid #00000014;
    border-radius: 8px;
    color: #000000e6;
    font-size: 14px;
    position: relative;
    white-space: pre-line;
  }
  &__message-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
  }
}
</style>
