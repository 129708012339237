<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <section>
    <article class="video-constructor__card">
      <div class="video-constructor__card-inner">
        <h1 class="text_size_l text_weight_accent mb-2">
          {{ $t('videoConstructor.editor.sections.name.title') }}
        </h1>
        <default-input
          :value="scenario.name"
          :placeholder="$t('videoConstructor.editor.sections.name.title')"
          @input="update($event, 'name', false)"
        />
      </div>
    </article>

    <article class="video-constructor__card">
      <constructor-textarea
        class="video-constructor__card-inner"
        :message="scenario.video_settings.title_template"
        :min-height="40"
        :max-height="120"
        :title="$t('videoConstructor.editor.sections.title.title')"
        :placeholder="$t('videoConstructor.editor.sections.title.title')"
        @update="update($event, 'title_template')"
      />
    </article>

    <article class="video-constructor__card">
      <constructor-textarea
        class="video-constructor__card-inner"
        :message="scenario.video_settings.description_template"
        :min-height="120"
        :max-height="120"
        :title="$t('videoConstructor.editor.sections.subtitle.title')"
        :placeholder="$t('videoConstructor.editor.sections.subtitle.title')"
        @update="update($event, 'description_template')"
      />
    </article>
    <article class="video-constructor__card">
      <h1 class="text_size_l text_weight_accent mb-2">
        {{ $t('videoConstructor.editor.sections.video.title') }}
      </h1>
      <div class="flex-row flex-gap-16">
        <video-editor
          class="video-constructor__editor"
          :selected-scenario="scenario"
          @close-modal="$emit('close-modal')"
        />
        <constructor-radiobuttons
          class="flex-align-self-start"
          :bubble-size="scenario.video_settings.bubble_size"
          :scroll-speed="scenario.video_settings.scroll_speed"
          :mirror-video="scenario.video_settings.mirror_video"
          @update="update"
        />
      </div>
    </article>
    <article class="video-constructor__card">
      <constructor-textarea
        class="video-constructor__card-inner mb-2"
        :message="scenario.video_settings.call_to_action_title"
        :min-height="120"
        :max-height="120"
        :title="$t('videoConstructor.editor.sections.callToAction.title')"
        :placeholder="$t('videoConstructor.editor.sections.callToAction.messagePlaceholder')"
        @update="update($event, 'call_to_action_title')"
      />
      <div class="flex-row flex-gap-16">
        <default-input
          class="half-parent-size-width"
          :value="scenario.video_settings.calendar_link"
          :description="$t('videoConstructor.editor.sections.callToAction.url.label')"
          :placeholder="$t('videoConstructor.editor.sections.callToAction.url.placeholder')"
          @input="update($event, 'calendar_link')"
        />
        <div class="video-constructor__info">
          <i class="bx bx-info-circle icon_size-16" />
          <p class="text_xs">
            {{ $t('videoConstructor.editor.sections.callToAction.url.info') }}
          </p>
        </div>
      </div>
    </article>
  </section>
</template>
<script>
  import DefaultInput from '@/components/base/uiKit/DefaultInput.vue';
  import ConstructorTextarea from '@/components/videoEditor/constructor/ConstructorTextarea.vue';
  import ConstructorRadiobuttons from '@/components/videoEditor/constructor/ConstructorRadiobuttons.vue';
  import VideoEditor from '@/components/videoEditor/editor/VideoEditor.vue';

  export default {
    name: 'ConstructorEdit',
    components: {
      DefaultInput,
      ConstructorTextarea,
      ConstructorRadiobuttons,
      VideoEditor,
    },
    props: {
      scenario: {
        type: Object,
        default: null,
      },
    },
    methods: {
      update ($event, key, isSettings = true) {
        this.$emit('update', $event, key, isSettings);
      },
    },
  };
</script>
<style lang="scss">
.video-constructor {
  &__card {
    margin-bottom: 16px;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 1px 1px 4px var(--tooltip-shadow);
    background: $cl-white;

    &:last-child {
      margin-bottom: 0;
    }

    &-inner {
      max-width: 50%;
    }
  }

  &__editor {
    max-width: 780px;
  }

  &__info {
    display: flex;
    gap: 8px;
    width: calc(50% - 16px);
    padding: 8px;
    border-radius: $border-radius-big;
    color: $t-light-tertiary;
    background: $cl-neutral-50;
  }
}
</style>
